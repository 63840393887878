import React from "react"
import { Card, Elevation, FormGroup,Checkbox } from "@blueprintjs/core"
import { DateInput } from "@blueprintjs/datetime";
import moment from 'moment';

export default class EmpleadosFechas extends React.Component {

    state={
        fechaAlta:new Date(),
        fechaBaja:null,
        bolFechaBaja:false,
        fechaIngreso:null,
        bolFechaIngreso:false,
        fechaAntiguedad:null,
        bolFechaAntiguedad:false

    }
    render(){
        
        
        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };

        
        const escogeFechaAlta = (nuevaFecha) => {
            this.setState({ fechaAlta: nuevaFecha })
        }
        
        const escogeFechaBaja = (nuevaFecha) => {
            this.setState({ fechaBaja: nuevaFecha })
        }
        
        const escogeFechaIngreso = (nuevaFecha) => {
            this.setState({ fechaIngreso: nuevaFecha })
        }
        
        const escogeFechaAntiguedad = (nuevaFecha) => {
            this.setState({ fechaAntiguedad: nuevaFecha })
        }

        const handleBolFechaAntiguedad = () => {
            let valor= !this.state.bolFechaAntiguedad 
            this.setState({ bolFechaAntiguedad: valor })

            if(valor){
                this.setState({fechaAntiguedad:new Date()})
            }else{
                this.setState({fechaAntiguedad:null})
            }

        }

        const handleBolFechaBaja = () => {

            let valor= !this.state.bolFechaBaja 
            this.setState({ bolFechaBaja:valor })

            if(valor){
                this.setState({fechaBaja:new Date()})
            }else{
                this.setState({fechaBaja:null})
            }

        }

        const handleBolFechaIngreso = () => {

            let valor= !this.state.bolFechaIngreso 
            this.setState({ bolFechaIngreso:valor })

            if(valor){
                this.setState({fechaIngreso:new Date()})
            }else{
                this.setState({fechaIngreso:null})
            }

        }

        return (
            <>
                <div className="pixelAlrededor">
                <Card interactive={true} elevation={Elevation.TWO}>
                        <FormGroup
                            label={this.props.i18next.t("trabajadores.fecha_alta",{ns:"page"})+":"}
                            labelFor="fecha-alta"
                            inline={true}
                        >
                            <DateInput minDate={new Date(1900, 1 ,1)}
                                {...jsDateFormatter} 
                                onChange={escogeFechaAlta}
                                value={this.state.fechaAlta}
                            />
                        </FormGroup>
                        <FormGroup
                            label={this.props.i18next.t("trabajadores.fecha_baja",{ns:"page"})+":"}
                            labelFor="fecha-baja"
                            inline={true}
                        >
                            <Checkbox checked={this.state.bolFechaBaja?this.state.bolFechaBaja:false} onChange={handleBolFechaBaja} />
                            <DateInput minDate={new Date(1900, 1 ,1)}
                                {...jsDateFormatter} 
                                onChange={escogeFechaBaja}
                                value={this.state.fechaBaja}
                                disabled={!this.state.bolFechaBaja}
                            />
                        </FormGroup>
                        <FormGroup
                            label={this.props.i18next.t("trabajadores.fecha_ingreso",{ns:"page"})+":"}
                            labelFor="fecha-ingreso"
                            inline={true}
                        >
                            <Checkbox checked={this.state.bolFechaIngreso?this.state.bolFechaIngreso:false} onChange={handleBolFechaIngreso} />
                            <DateInput minDate={new Date(1900, 1 ,1)}
                                {...jsDateFormatter} 
                                onChange={escogeFechaIngreso}
                                value={this.state.fechaIngreso}
                                disabled={!this.state.bolFechaIngreso}
                            />
                        </FormGroup>
                        <FormGroup
                            label={this.props.i18next.t("trabajadores.fecha_antiguedad",{ns:"page"})+":"}
                            labelFor="fecha-antiguedad"
                            inline={true}
                        >
                            <Checkbox checked={this.state.bolFechaAntiguedad?this.state.bolFechaAntiguedad:false} onChange={handleBolFechaAntiguedad} />
                            <DateInput minDate={new Date(1900, 1 ,1)}
                                {...jsDateFormatter} 
                                onChange={escogeFechaAntiguedad}
                                value={this.state.fechaAntiguedad}
                                disabled={!this.state.bolFechaAntiguedad}
                            />
                        </FormGroup>
                </Card>
                </div>
            </>
        )
    }
}