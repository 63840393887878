import React, { Component } from 'react';
import { Card, FormGroup,InputGroup, Navbar, Elevation, ButtonGroup, Alignment, Button, Dialog, Classes, Tooltip, AnchorButton, Intent, Checkbox, TextArea, Switch, Spinner,Callout } from "@blueprintjs/core"
import { DateInput } from "@blueprintjs/datetime";
import gql from "graphql-tag";
import moment from 'moment';
import { withApollo } from 'react-apollo';
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import { Cargando } from "../Pantallas/Cargando"
import ReactDOM from 'react-dom';
import iconoFooter from '../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import const_table from './BootstrapTable';
import ApolloClient from "apollo-boost";
import Select from "react-select"
import i18next from "i18next";
import { withTranslation } from "react-i18next";

import styles from './stylesPrint';

const today = new Date();

class Alertas extends Component {

    state={
        perfilAdmin:1,
        perfilUsuario:0,
        nuevoActivado: true,
        editarActivado: false,
        borrarActivado: false,
        alertas:[
           
        ],
        asunto: null,
        tipo:{"label": "ADVERTENCIA","value":"ADVERTENCIA"},
        mostrarDesde: null,
        mostrarHasta: null,
        publico: false,
        cerrar: false,
        texto:null,
        bolMostrarDesde:false,
        bolMostrarHasta:false,
        idUsuarioActual:null,
        fechaCreacion:null,
        cargandoForm:false,
        datosEmpresa:null,
        cargandoImprimirI:false,
        modalImprimirI: false,
        cargandoImprimirG:false,
        modalImprimirG: false,
        logotipo:null,
        cargandoAlertas:false,
        tipos:[
            {"label": "ERROR","value":"ERROR"},
            {"label": "ADVERTENCIA","value":"ADVERTENCIA"},
            {"label": "INFO","value":"INFO"}
        ]
    }

    cargarAlertas=async ()=>{

       let usuarioActual=await this.props.client
        .query({
            query: gql`
            {obtenerUsuarioActual}
            `,
            fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.obtenerUsuarioActual
        });
        this.setState({ idUsuarioActual:usuarioActual});
        if (this.state.perfilUsuario===this.state.perfilAdmin) {
            await this.props.client.query({
                query:gql`{alertas{
                    ID_ALERTA,
                    ID_USUARIO,
                    PUBLICA,
                    DESDE,
                    HASTA,
                    PERSISTENTE,
                    TEXTO,
                    FECHA_CREACION,
                    ASUNTO,
                    TIPO}}`,
                    fetchPolicy:'network-only'
            }).then(async(result)=>{
                //console.log(result)
                let alertas=[];
                let tipoSel=null;
                for(let alerta of result.data.alertas){
                    tipoSel=this.state.tipos.find(tipo=>{return tipo.value===alerta.TIPO});
                    alertas.push({
                        "id":alerta.ID_ALERTA,
                        "fechaCreacion": moment(alerta.FECHA_CREACION).format('DD-MM-YYYY'),
                        "FECHA_CREACION": alerta.FECHA_CREACION,
                        "asunto": alerta.ASUNTO,
                        "tipo": tipoSel,
                        "mostrarDesde": (alerta.DESDE?moment(alerta.DESDE).format('DD-MM-YYYY'):null),
                        "DESDE": alerta.DESDE,
                        "mostrarHasta": (alerta.HASTA?moment(alerta.HASTA).format('DD-MM-YYYY'):null),
                        "HASTA": alerta.HASTA,
                        "publico": alerta.PUBLICA,
                        "cerrar": alerta.PERSISTENTE,
                        "texto":alerta.TEXTO
                    })
                }
                //console.log(alertas)
                this.setState({alertas:alertas})
            })
        } else {
            await this.props.client.query({
                query:gql`{alertas(FILTROS:{ID_USUARIO:${usuarioActual}}){
                    ID_ALERTA,
                    ID_USUARIO,
                    PUBLICA,
                    DESDE,
                    HASTA,
                    PERSISTENTE,
                    TEXTO,
                    FECHA_CREACION,
                    ASUNTO,
                    TIPO}}`,
                    fetchPolicy:'network-only'
            }).then(async(result)=>{
                //console.log(result)
                let alertas=[];
                let tipoSel=null;
                for(let alerta of result.data.alertas){
                    tipoSel=this.state.tipos.find(tipo=>{return tipo.value===alerta.TIPO});
                    alertas.push({
                        "id":alerta.ID_ALERTA,
                        "fechaCreacion": moment(alerta.FECHA_CREACION).format('DD-MM-YYYY'),
                        "FECHA_CREACION": alerta.FECHA_CREACION,
                        "asunto": alerta.ASUNTO,
                        "tipo": tipoSel,
                        "mostrarDesde": (alerta.DESDE?moment(alerta.DESDE).format('DD-MM-YYYY'):null),
                        "DESDE": alerta.DESDE,
                        "mostrarHasta": (alerta.HASTA?moment(alerta.HASTA).format('DD-MM-YYYY'):null),
                        "HASTA": alerta.HASTA,
                        "publico": alerta.PUBLICA,
                        "cerrar": alerta.PERSISTENTE,
                        "texto":alerta.TEXTO
                    })
                }
                //console.log(alertas)
                this.setState({alertas:alertas})
            })
        }

    }

    obtenerLogoEmpresa=async()=>{
        //obtencion logo de la empresa
         await this.props.client
         .query({
             query: gql`
                 {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
             `,fetchPolicy:'network-only'             
         }).then(result=>{ 
             if(result.data.adjuntos.length>0){
    
                 var request = require('request').defaults({ encoding: null,withCredentials:true });
    
                 request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                     if (!error && response.statusCode === 200) {
                     let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                     this.setState({logotipo:data})
                     }
                 });
    
             }
         })
     
     }
    cargarDatos=async()=> {
        let datosEmpresa= await this.cargarEmpresa()
        
        this.setState({
            datosEmpresa:datosEmpresa,
        })

    }

    cargarEmpresa= async() => {
         
        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                }

            }
            
        })

       return datosEmpresa
   
   }

   obtenerUsuarioLoginActual=async() => {
    let cliente = new ApolloClient({
        uri: process.env.REACT_APP_DOMINIO+"/bdds",
        credentials: 'include',
    });


      await cliente
      .query({
          query: gql`
          {obtenerUsuariosLoginActual{ID_USUARIO,USUARIO,NOMBRE,APELLIDOS,ID_PERFIL}}
          `,
          fetchPolicy:'network-only'
      }).then(async result1=>{    
          this.setState({
              perfilUsuario:result1.data.obtenerUsuariosLoginActual.ID_PERFIL,
        })
        
      });
   }

   generarImprimirI= async() => {
        this.setState({
            cargandoImprimirI:true,
            modalImprimirI:true
        }, async () => {
            this.setState({cargandoImprimirI:false}, () => {
                ReactDOM.render(this.ImprimirI(), document.getElementById('generarI-pdf'))
            })
        })
    }

    generarImprimirG= async() => {
        this.setState({
            cargandoImprimirG:true,
            modalImprimirG:true
        }, async () => {
            this.setState({cargandoImprimirG:false}, () => {
                ReactDOM.render(this.ImprimirG(), document.getElementById('generarG-pdf'))
            })
        })
    }

    ImprimirI=()=>{
        
        return (
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("alertas.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("alertas.alerta",{ns:"page"}).toUpperCase()}</Text>
                </View>
                    {this.state.alertaSeleccionada?
                            <View >
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("alertas.asunto",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.alertaSeleccionada.asunto}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("alertas.tipo",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.alertaSeleccionada.tipo.value}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("alertas.mostrardesde",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.alertaSeleccionada.mostrarDesde}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("alertas.mostrarhasta",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.alertaSeleccionada.mostrarHasta}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("alertas.publica",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.alertaSeleccionada.publico?i18next.t("yes",{ns:"global"}):i18next.t("no",{ns:"global"})}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("alertas.nomostrar",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.alertaSeleccionada.cerrar?i18next.t("yes",{ns:"global"}):i18next.t("no",{ns:"global"})}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("alertas.textalerta",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.alertaSeleccionada.texto}</Text>
                                </View>
                            </View>
                    :<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )
    }

    ImprimirG=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHeadL}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("alertas.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("alertas.listalertas",{ns:"page"}).toUpperCase()}</Text>
                </View>
                    {this.state.alertas?this.state.alertas.map((alerta, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("alertas.fecha",{ns:"page"}).toUpperCase()}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("alertas.asunto",{ns:"page"}).toUpperCase()}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("alertas.mostrardesde",{ns:"page"}).toUpperCase()}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("alertas.mostrarhasta",{ns:"page"}).toUpperCase()}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("alertas.publico",{ns:"page"}).toUpperCase()}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'15%'}}>{i18next.t("alertas.permitircerrar",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{alerta.FECHA_CREACION?moment(alerta.FECHA_CREACION).format('DD/MM/YYYY'):''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{alerta.asunto?alerta.asunto:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{alerta.mostrarDesde?alerta.mostrarDesde:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{alerta.mostrarHasta?alerta.mostrarHasta:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{alerta.publico?'SI':'NO'}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'15%'}}>{alerta.cerrar?'SI':'NO'}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )


    componentDidMount(props){
        this.setState({
            cargandoForm: true
        },async () => {
            await this.obtenerUsuarioLoginActual();
            await this.obtenerLogoEmpresa()
            await this.cargarDatos()
            await this.cargarAlertas()
            this.setState({cargandoForm: false})
        });
    }

    render() {

        document.title = i18next.t("lexcontrol",{ns:"global"})+' | '+i18next.t("alertas.title",{ns:"page"})

        const abrirModalNuevo = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            this.setState({
                modalAbierto: true,
                nuevoActivado:true,
                editarActivado:false,
                borrarActivado:false,
                asunto: null,
                tipo:{"label": "ADVERTENCIA","value":"ADVERTENCIA"},
                mostrarDesde: null,
                mostrarHasta: null,
                publico: false,
                cerrar: false,
                texto:null,
                bolMostrarDesde:false,
                bolMostrarHasta:false,
                cargandoAlertas:true
            })
        }

        const abrirModalEditar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.alertaSeleccionada){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            this.setState({
                modalAbierto: true,
                nuevoActivado:false,
                editarActivado:true,
                borrarActivado:false,
                asunto: this.state.alertaSeleccionada.asunto,
                tipo: this.state.alertaSeleccionada.tipo,
                mostrarDesde: new Date(moment(this.state.alertaSeleccionada.mostrarDesde,"DD-MM-YYYY").format("YYYY-MM-DD")),
                mostrarHasta: new Date(moment(this.state.alertaSeleccionada.mostrarHasta,"DD-MM-YYYY").format("YYYY-MM-DD")),
                publico: this.state.alertaSeleccionada.publico,
                cerrar: this.state.alertaSeleccionada.cerrar,
                texto:this.state.alertaSeleccionada.texto,
                bolMostrarDesde: this.state.alertaSeleccionada.mostrarDesde,
                bolMostrarHasta: this.state.alertaSeleccionada.mostrarHasta
            })
        }

        const abrirModalBorrar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.alertaSeleccionada){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            this.setState({
                modalBorrarAbierto: true,
                borrarActivado:true,
                editarActivado:false,
                nuevoActivado:false
            })
        }

        const cerrarModal = () => {
            this.setState({
                modalAbierto: false,
                modalBorrarAbierto: false,
                cargandoAlertas:false
            })
        }


        const añadirNuevo = (value) => {

            //console.log(this.state)

            if(!this.state.asunto){
                alert(i18next.t("alertas.valid1",{ns:"page"}));
                return false;
            }

            if(!this.state.tipo){
                alert(i18next.t("alertas.valid2",{ns:"page"}));
                return false;
            }


            if (this.state.asunto.length>60) {
                alert(i18next.t("alertas.valid3",{ns:"page"}));
                return false;
            }

            if(!this.state.texto){
                alert(i18next.t("alertas.valid4",{ns:"page"}));
                return false;
            }

       
            let alertas=this.state.alertas
            let fCreacion=new Date()
            alertas.push({"fechaCreacion":moment(fCreacion).format('DD-MM-YYYY'),"asunto":this.state.asunto,"tipo":this.state.tipo,"mostrarDesde":(this.state.bolMostrarDesde?moment(this.state.mostrarDesde).format('DD-MM-YYYY hh:mm:ss'):null),"mostrarHasta":(this.state.bolMostrarHasta?moment(this.state.mostrarHasta).format('DD-MM-YYYY hh:mm:ss'):null),"publico":this.state.publico,"cerrar":this.state.cerrar}) 
            this.setState({fechaCreacion:fCreacion})
            this.setState({alertas:alertas})
            this.setState({pendienteGuardar:true})
            this.setState({controlesDesactivados:true})

            cerrarModal()
        }

        
        const actualizarAlerta = () => {

            if(!this.state.asunto && !this.state.alertaSeleccionada){
                alert(i18next.t("alertas.valid1",{ns:"page"}))
                return false;
            }

            if(!this.state.tipo && !this.state.alertaSeleccionada){
                alert(i18next.t("alertas.valid2",{ns:"page"}))
                return false;
            }

            if (this.state.asunto.length>60) {
                alert(i18next.t("alertas.valid3",{ns:"page"}));
                return false;
            }

            if(!this.state.texto && !this.state.alertaSeleccionada){
                alert(i18next.t("alertas.valid4",{ns:"page"}))
                return false;
            }
        

            let alertas=this.state.alertas
            this.state.alertas.find((alerta,index) =>{
                if(alerta.id===this.state.alertaSeleccionada.id){

                    alertas[index]["asunto"]=this.state.asunto?this.state.asunto:this.state.alertaSeleccionada.asunto
                    alertas[index]["tipo"]=this.state.tipo?this.state.tipo:this.state.alertaSeleccionada.tipo
                    alertas[index]["mostrarDesde"]=this.state.bolMostrarDesde?(this.state.mostrarDesde?moment(this.state.mostrarDesde).format('DD-MM-YYYY hh:mm:ss'):this.state.alertaSeleccionada.mostrarDesde):null
                    alertas[index]["mostrarHasta"]=this.state.bolMostrarHasta?(this.state.mostrarHasta?moment(this.state.mostrarHasta).format('DD-MM-YYYY hh:mm:ss'):this.state.alertaSeleccionada.mostrarHasta):null
                    alertas[index]["publico"]=this.state.publico
                    alertas[index]["cerrar"]=this.state.cerrar

                }
                return alerta.id===this.state.alertaSeleccionada.id
            })
            this.setState({pendienteActualizar:true})
            this.setState({controlesDesactivados:true})
            this.setState({alertas:alertas})
        
            cerrarModal()
        }

        const eliminarAlerta = () => {
            //console.log(this.state.alertaSeleccionada)
            let alertas=this.state.alertas.filter(alerta => alerta.id !== this.state.alertaSeleccionada.id)
            this.setState({alertas:alertas})
            this.setState({pendienteBorrar:true})     
            this.setState({controlesDesactivados:true})
        
            cerrarModal()
        };

        const guardarCambios=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }
            //console.log(this.state)
            if(this.state.pendienteGuardar){
            
                this.props.client.mutate({
                    mutation:gql`mutation{crearAlerta(VALORES:{ID_USUARIO:${this.state.idUsuarioActual},FECHA_CREACION:"${moment(this.state.fechaCreacion).format('YYYY-MM-DD HH:mm:ss')}",DESDE:${this.state.bolMostrarDesde?'"'+moment(this.state.mostrarDesde).format('YYYY-MM-DD HH:mm:ss')+'"':null},HASTA:${this.state.bolMostrarHasta?'"'+moment(this.state.mostrarHasta).format('YYYY-MM-DD HH:mm:ss')+'"':null},PERSISTENTE:${this.state.cerrar},PUBLICA:${this.state.publico},TEXTO:"""${this.state.texto}""",ASUNTO:"${this.state.asunto}",TIPO:"${this.state.tipo.value}"}){ID_ALERTA}}`
                }).then(async result=>{
        
                    alert(i18next.t("insertcorrecto",{ns:"global"}))
                    await this.cargarAlertas()
                   
                    this.setState({
                        pendienteGuardar:false,
                        controlesDesactivados:false
                    })
                    
                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })

            }else if(this.state.pendienteActualizar){

                this.props.client.mutate({
                    mutation:gql`mutation{actualizarAlerta(ID_ALERTA:${this.state.alertaSeleccionada.id},VALORES:{ID_USUARIO:${this.state.idUsuarioActual},DESDE:${this.state.bolMostrarDesde?(this.state.mostrarDesde?'"'+moment(this.state.mostrarDesde).format('YYYY-MM-DD hh:mm:ss')+'"':'"'+moment(this.state.alertaSeleccionada.mostrarDesde,"DD-MM-YYYY hh:mm:ss").format('YYYY-MM-DD hh:mm:ss')+'"'):null},HASTA:${this.state.bolMostrarHasta?(this.mostrarHasta?'"'+moment(this.state.mostrarHasta).format('YYYY-MM-DD hh:mm:ss')+'"':'"'+moment(this.state.alertaSeleccionada.mostrarHasta,"DD-MM-YYYY hh:mm:ss").format('YYYY-MM-DD hh:mm:ss')+'"'):null},PERSISTENTE:${this.state.cerrar},PUBLICA:${this.state.publico},TEXTO:"""${this.state.texto?this.state.texto:this.state.alertaSeleccionada.texto}""",ASUNTO:"${this.state.asunto?this.state.asunto:this.state.alertaSeleccionada.asunto}",TIPO:"${this.state.tipo?this.state.tipo.value:this.state.alertaSeleccionada.tipo.value}"}){ID_ALERTA}}`
                }).then(async result=>{
                  
                        alert(i18next.t("updatecorrecto",{ns:"global"}))
                        await this.cargarAlertas()

                        this.setState({
                            pendienteActualizar:false,
                            controlesDesactivados:false
                        })


                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })

            }else if(this.state.pendienteBorrar){

                this.props.client.mutate({
                    mutation:gql`mutation{eliminarAlerta(ID_ALERTA:${this.state.alertaSeleccionada.id}){ID_ALERTA}}`
                }).then(result=>{
                    
                    alert(i18next.t("deletecorrecto",{ns:"global"}))
                    this.setState({pendienteBorrar:false})
                    this.setState({controlesDesactivados:false})
                    this.setState({alertaSeleccionada:null})
                
                    this.cargarAlertas()
                    
                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })
            }


        }

        const cancelarCambios=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            this.setState({
                pendienteActualizar:false,
                pendienteBorrar:false,
                pendienteGuardar:false,
                controlesDesactivados:false,
                alertaSeleccionada:null,
            })

            this.cargarAlertas()
        }

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY",
        }

        const escogeFechaDesde = (nuevaFecha) => {
            this.setState({ mostrarDesde: nuevaFecha })
        }

        const escogeFechaHasta= (nuevaFecha) => {
            this.setState({ mostrarHasta: nuevaFecha })
        }

        const handleEnabledChangePublico = () => this.setState({ publico: !this.state.publico })
        
        const handleEnabledChangeCerrar = () => this.setState({ cerrar: !this.state.cerrar })

        const handleEnabledChangeBolMostrarDesde = () => {
            let nuevoValor=!this.state.bolMostrarDesde
            this.setState({ bolMostrarDesde: nuevoValor })
            //si hacemos click en el boton y no hay fecha seleccionada, cogeremos le facha por defecto.
            if(nuevoValor){
                this.setState({mostrarDesde:new Date()})
            }
        }

        const handleEnabledChangeBolMostrarHasta = () => {
            let nuevoValor=!this.state.bolMostrarHasta 
            this.setState({ bolMostrarHasta: nuevoValor})
            //si hacemos click en el boton y no hay fecha seleccionada, cogeremos le facha por defecto.
            if(nuevoValor){
                this.setState({mostrarHasta:new Date()})
            }
        }


        const handleCambiarAsunto = (ev) => { 
           this.setState({asunto:ev.currentTarget.value})
        }

        const handleCambiarTipo = (value) => { 
            this.setState({tipo:value})
         }

        const handleCambiarTexto = (ev) => { 
            this.setState({texto:ev.currentTarget.value})
        }

        const cerrarModalImprimir = () => {
            this.setState({
                modalImprimirI:false,
                modalImprimirG:false,
            })
        }

        const handleOnSelect=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                  alertaSeleccionada: row
                });
            }
            //console.log(row);
            return true; // return true or dont return to approve current select action
        }

        function dateFormatterSort(cell, row) {
            return (
                moment(cell).format("YYYY-MM-DD")
            );
        }
        const yes=i18next.t("yes", { ns: "global" }).toUpperCase();
        const no=i18next.t("no", { ns: "global" }).toUpperCase();
        function boolFormatter(cell, row) {
            let rpta=no;
            rpta=(cell)?yes:no;
            return (
                rpta
            );
        }
        const columns = [
            {
                dataField: 'FECHA_CREACION',
                text: i18next.t("alertas.fecha",{ns:"page"}),
                formatter: dateFormatterSort,
                sort:true
            },
            {
              dataField: 'asunto',
              text: i18next.t("alertas.asunto",{ns:"page"}),
              sort: true,
            },
            {
              dataField: 'tipo.value',
              text: i18next.t("alertas.tipo",{ns:"page"}),
              sort: true,
            },
            {
              dataField: 'DESDE',
              text: i18next.t("alertas.mostrardesde",{ns:"page"}),
              formatter: dateFormatterSort,
              sort: true,
            },
            {
              dataField: 'HASTA',
              text: i18next.t("alertas.mostrarhasta",{ns:"page"}),
              formatter: dateFormatterSort,
              sort: true,
            },
            {
              dataField: 'publico',
              text: i18next.t("alertas.publico",{ns:"page"}),
              sort: true,
              formatter: boolFormatter
            },
            {
              dataField: 'cerrar',
              text: i18next.t("alertas.permitircerrar",{ns:"page"}),
              sort: true,
              formatter: boolFormatter
            }
        ];

        return (
            <>
            {this.state.cargandoForm===true?
                <Cargando/>
            :
            <>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={i18next.t("print",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirG}
                    style={styles.dialogL}
                >
                    {!this.state.cargandoImprimirG?
                            <div style={{ width: '100%', height: '500px'}} id='generarG-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={i18next.t("print",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirI}
                    style={styles.dialogL}
                >
                    {!this.state.cargandoImprimirI?
                            <div style={{ width: '100%', height: '500px'}} id='generarI-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                
                <Dialog
                    transitionDuration={400}
                    className="dialog-ancho pixelAlrededor"
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={this.state.nuevoActivado?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalAbierto}
                >
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <FormGroup
                            label={i18next.t("alertas.asunto",{ns:"page"})+":"}
                            labelFor="asunto"
                            intent="danger"
                            helperText={!this.state.asunto?i18next.t("alertas.valid1",{ns:"page"}):this.state.asunto.length>60?i18next.t("alertas.valid3",{ns:"page"}):""}
                        >
                            <InputGroup id="asunto" value={this.state.asunto} intent={!this.state.asunto?"danger":this.state.asunto.length>60?"danger":"primary"} onChange={handleCambiarAsunto}/>
                        </FormGroup>
                        <FormGroup
                            label={i18next.t("alertas.tipo",{ns:"page"})+":"}
                            labelFor="tipo"
                            intent="danger"
                            helperText={!this.state.tipo?i18next.t("alertas.valid2",{ns:"page"}):""}
                        >
                            <div style={!this.state.tipo?{border:"1px solid red"}:{}}>
                                <Select
                                    options={this.state.tipos}
                                    className="pixelAlrededor"
                                    onChange={handleCambiarTipo}
                                    value={this.state.tipo}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup
                            labelFor="desde"
                            inline={true}
                        >
                            <Switch label={i18next.t("alertas.mostrardesde",{ns:"page"})+":"} checked={this.state.bolMostrarDesde} alignIndicator={Alignment.RIGHT} onChange={handleEnabledChangeBolMostrarDesde} />
                            <DateInput minDate={new Date(1900, 1 ,1)}
                                maxDate={new Date(today.getFullYear()+100, 11 ,31)}
                                {...jsDateFormatter} 
                                onChange={escogeFechaDesde}
                                value={this.state.bolMostrarDesde?this.state.mostrarDesde:null}
                                disabled={!this.state.bolMostrarDesde}
                            />
                        </FormGroup>

                        <FormGroup
                            labelFor="hasta"
                            inline={true}
                        >
                            <Switch label={i18next.t("alertas.mostrarhasta",{ns:"page"})+":"} checked={this.state.bolMostrarHasta} alignIndicator={Alignment.RIGHT} onChange={handleEnabledChangeBolMostrarHasta} />
                            <DateInput minDate={new Date(1900, 1 ,1)}
                                maxDate={new Date(today.getFullYear()+100, 11 ,31)}
                                {...jsDateFormatter} 
                                onChange={escogeFechaHasta}
                                value={this.state.bolMostrarHasta?this.state.mostrarHasta:null}
                                disabled={!this.state.bolMostrarHasta}
                            />
                        </FormGroup>

                        <br />
                        <FormGroup>
                            <Checkbox checked={this.state.publico} onChange={handleEnabledChangePublico} label={i18next.t("alertas.publica",{ns:"page"})} />
                            <Checkbox checked={this.state.cerrar} onChange={handleEnabledChangeCerrar} label={i18next.t("alertas.nomostrar",{ns:"page"})} />
                        </FormGroup>
                        <FormGroup
                            label={i18next.t("alertas.textalerta",{ns:"page"})}
                            labelFor="texto-alerta"
                            intent="danger"
                            helperText={!this.state.texto?i18next.t("alertas.valid4",{ns:"page"}):""}
                        >
                            <div style={!this.state.texto?{border:"1px solid red"}:{}}>
                                <TextArea fill={true} value={this.state.texto} onChange={handleCambiarTexto} />
                            </div>
                        </FormGroup>
                    </Card>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("cerraruvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                        </Tooltip>
                        <Tooltip content={this.state.nuevoActivado?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={this.state.nuevoActivado?añadirNuevo:actualizarAlerta}
                            >
                              {this.state.nuevoActivado?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
         
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title={i18next.t("delete",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBorrarAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>{i18next.t("alertas.questiondel",{ns:"page"})}</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("delete",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={eliminarAlerta}
                            >
                                {i18next.t("yes",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraruvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("no",{ns:"global"})}</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
                <Card>
                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("alertas.title",{ns:"page"})}</Callout>
                    <Navbar>
                        <Navbar.Group>
                            <ButtonGroup align={Alignment.LEFT}>
                                {this.state.controlesDesactivados?[
                                    <React.Fragment key="fragment">
                                        <Button key="btnCrear" className="bp3-minimal" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoActivado} disabled />
                                        <Button key="btnEditar" className="bp3-minimal" icon="edit" text={i18next.t("edit",{ns:"global"})} active={this.state.editarActivado} disabled/>
                                        <Button key="btnBorrar" className="bp3-minimal" icon="delete" text={i18next.t("delete",{ns:"global"})} active={this.state.borrarActivado} disabled/>
                                        <Button className="bp3-minimal" icon="print"  text={i18next.t("print",{ns:"global"})} disabled/>
                                        <Button className="bp3-minimal" icon="print" text={i18next.t("printgral",{ns:"global"})} disabled/>
                                    </React.Fragment>
                                ]:[
                                    <React.Fragment key="fragment">
                                        <Button key="btnCrear" className="bp3-minimal" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                        <Button key="btnEditar" className="bp3-minimal" icon="edit" text={i18next.t("edit",{ns:"global"})} active={this.state.editarActivado} onClick={abrirModalEditar} />
                                        <Button key="btnBorrar" className="bp3-minimal" icon="delete" text={i18next.t("delete",{ns:"global"})} active={this.state.borrarActivado} onClick={abrirModalBorrar}/>
                                        <Tooltip 
                                            content={i18next.t("print",{ns:"global"})} 
                                            position="right" 
                                        >
                                            <Button 
                                                className="bp3-minimal" 
                                                icon="print" 
                                                disabled={this.state.alertaSeleccionada?false:true}  
                                                text={i18next.t("print",{ns:"global"})} onClick={()=>this.generarImprimirI()}
                                            />
                                        </Tooltip>  
                                        <Tooltip 
                                            content={i18next.t("printgral",{ns:"global"})} 
                                            position="right" 
                                        >
                                            <Button 
                                                className="bp3-minimal" 
                                                icon="print" 
                                                text={i18next.t("printgral",{ns:"global"})} 
                                                onClick={()=>this.generarImprimirG()}
                                            />
                                        </Tooltip>
                                    </React.Fragment>
                                ]}
                            </ButtonGroup>
                        </Navbar.Group>
                    </Navbar>
                    {this.state.cargandoAlertas?
                        <Cargando />
                    :
                    <BootstrapTable
                        keyField='id'
                        data={ this.state.alertas }
                        columns={ columns }
                        selectRow={ const_table.selectRow(handleOnSelect) }
                        pagination={ paginationFactory(const_table.options) }
                        defaultSorted={ const_table.defaultSorted('nombre','asc') }
                        headerClasses="header-class"
                        rowClasses="row-class"
                        hover
                        condensed
                        noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                    />
                    }
                    <br />
                    <Button 
                        alignText="right"
                        icon="cross"
                        text={i18next.t("cancelar",{ns:"global"})}
                        intent="danger"
                        disabled={!this.state.controlesDesactivados}
                        onClick={cancelarCambios}
                        className="bp3-intent-primary iconoDerecha bp3-minimal"
                    />
                    <Button 
                        alignText="right"
                        icon="floppy-disk"
                        text={i18next.t("guardar",{ns:"global"})}
                        intent="danger"
                        disabled={!this.state.controlesDesactivados}
                        onClick={guardarCambios}
                        className="bp3-intent-primary iconoDerecha bp3-minimal"
                    />
                    <br />
                </Card>
            
            </>
             }
             </>
        )
    }
}

export default withTranslation()(withApollo(Alertas))
