import React from "react"
import { Card, Elevation, FormGroup, InputGroup} from "@blueprintjs/core"

export default class EmpleadosRetribucion extends React.Component {
    state={
        retribucion:null,
        retribucionVoluntaria:null,
        porcentajeVoluntaria:0,
        retribucionVariables:null,
        porcentajeVariables:0
    }

    render(){
        const calcularPorcentajeVoluntaria=()=>{
            let legal=this.state.retribucion?this.state.retribucion:0
            let voluntaria=this.state.retribucionVoluntaria?this.state.retribucionVoluntaria:0
            let porcentaje=0
            if (legal!==0) {
                porcentaje=(voluntaria*100)/legal
            }
            this.setState({porcentajeVoluntaria:porcentaje.toFixed(2)})
        }
        const calcularPorcentajeVariables=()=>{
            let legal=this.state.retribucion?this.state.retribucion:0
            let variables=this.state.retribucionVariables?this.state.retribucionVariables:0
            let porcentaje=0
            if (legal!==0) {
                porcentaje=(variables*100)/legal
            }
            this.setState({porcentajeVariables:porcentaje.toFixed(2)})
        }
        const handleCambiarRetribucion = (event) => {
            const value = event.target.value;
            this.setState({
                retribucion:value
            },async ()=>{
                calcularPorcentajeVoluntaria()
                calcularPorcentajeVariables()
            })
        }
        const handleCambiarRetribucionVoluntaria = (event) => {
            const value = event.target.value;
            this.setState({
                retribucionVoluntaria:value
            },async ()=>{
                calcularPorcentajeVoluntaria()
            })
        }
        const handleCambiarRetribucionVariables = (event) => {
            const value = event.target.value;
            this.setState({
                retribucionVariables:value
            },async ()=>{
                calcularPorcentajeVariables()
            })
        }
        const onKeyDownRetribucion=(e) => {
            
            if ((e.keyCode < '48' || e.keyCode > '57') && (e.keyCode < '96' || e.keyCode > '105') && e.keyCode!==190 && e.keyCode!==110 && e.keyCode!==8) {
                e.preventDefault()
            }
            let present=0;
            let count=0;
            let valor=document.getElementById("retribucion").value
            do {
                present=valor.indexOf(".",present)
                if (present!==-1) {
                    count++
                    present++
                }
            } while (present!==-1)
            if ((e.keyCode===190 || e.keyCode===110) && count>0) {
                e.preventDefault()
            }
         }
         const onKeyDownRetribucionVoluntaria=(e) => {
            
            if ((e.keyCode < '48' || e.keyCode > '57') && (e.keyCode < '96' || e.keyCode > '105') && e.keyCode!==190 && e.keyCode!==110 && e.keyCode!==8) {
                e.preventDefault()
            }
            let present=0;
            let count=0;
            let valor=document.getElementById("retribucion-voluntaria").value
            do {
                present=valor.indexOf(".",present)
                if (present!==-1) {
                    count++
                    present++
                }
            } while (present!==-1)
            if ((e.keyCode===190 || e.keyCode===110) && count>0) {
                e.preventDefault()
            }
         }
         const onKeyDownRetribucionVariables=(e) => {
            
            if ((e.keyCode < '48' || e.keyCode > '57') && (e.keyCode < '96' || e.keyCode > '105') && e.keyCode!==190 && e.keyCode!==110 && e.keyCode!==8) {
                e.preventDefault()
            }
            let present=0;
            let count=0;
            let valor=document.getElementById("retribucion-variables").value
            do {
                present=valor.indexOf(".",present)
                if (present!==-1) {
                    count++
                    present++
                }
            } while (present!==-1)
            if ((e.keyCode===190 || e.keyCode===110) && count>0) {
                e.preventDefault()
            }
         }
         const onNotPaste=(e)=>{
            e.preventDefault()
         }
        return (
            <>
                <div className="pixelAlrededor">
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <FormGroup
                            label={this.props.i18next.t("trabajadores.retribucion_legal",{ns:"page"})+":"}
                            labelFor="retribucion"
                        >
                            <InputGroup  id="retribucion" value={this.state.retribucion?this.state.retribucion:''} onKeyDown={onKeyDownRetribucion} onChange={handleCambiarRetribucion} onPaste={onNotPaste} />
                        </FormGroup>
                        <FormGroup
                            label={this.props.i18next.t("trabajadores.retribucion_voluntaria",{ns:"page"})+":"}
                            labelFor="retribucion-voluntaria"
                        >
                            <InputGroup id="retribucion-voluntaria" value={this.state.retribucionVoluntaria?this.state.retribucionVoluntaria:''} onKeyDown={onKeyDownRetribucionVoluntaria} onChange={handleCambiarRetribucionVoluntaria} onPaste={onNotPaste}/>
                        </FormGroup>
                        <FormGroup
                            label={this.props.i18next.t("trabajadores.porc_voluntaria",{ns:"page"})+":"}
                            labelFor="porcentaje-voluntaria"
                        >
                            <InputGroup id="porcentaje-voluntaria" value={this.state.porcentajeVoluntaria?this.state.porcentajeVoluntaria:''} readOnly={true}/>
                        </FormGroup>
                        <FormGroup
                            label={this.props.i18next.t("trabajadores.retribucion_variables",{ns:"page"})+":"}
                            labelFor="retribucion-variables"
                        >
                            <InputGroup id="retribucion-variables" value={this.state.retribucionVariables?this.state.retribucionVariables:''} onKeyDown={onKeyDownRetribucionVariables} onChange={handleCambiarRetribucionVariables} onPaste={onNotPaste}/>
                        </FormGroup>
                        <FormGroup
                            label={this.props.i18next.t("trabajadores.porc_variables",{ns:"page"})+":"}
                            labelFor="porcentaje-variables"
                        >
                            <InputGroup id="porcentaje-variables" value={this.state.porcentajeVariables?this.state.porcentajeVariables:''} readOnly={true} />
                        </FormGroup>
                    </Card>
                </div>
            </>
        )
    }
}