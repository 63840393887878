import {Spinner, Dialog, Alignment, Button, ButtonGroup, Callout, Card, Elevation, Navbar, NavbarGroup, Position, Tooltip } from '@blueprintjs/core'
import React, { Component } from 'react'
import { CSVLink } from 'react-csv';
import gql from "graphql-tag";
import { withApollo } from 'react-apollo';
import { Cargando } from './Cargando';
import { 
    PDFViewer,
    Page,
    Text,
    View,
    Document,
    Image
} from '@react-pdf/renderer';
import moment from 'moment';
import iconoFooter from '../../Assets/lc.png';
import pantallaInicio from '../../Assets/LOGO REGISTRADO PEQUEÑO.png'
import ReactDOM from 'react-dom'
import i18next from "i18next";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import const_table from "./BootstrapTable";


import styles from './stylesPrint';

 class ValoracionPuestos extends Component {
    state={
        datosCsv:[],
        cargando:false,
        puestos:[],
        puestosGral:[],
        tareas:[],
        puestoSeleccionado:null,
        cargandoPuest:true,
        cargandoCsv:false,
        modalImprimir:false,
        cargandoImprimir:false,
        logotipo:null,
        datosEmpresa:[],
        registros:[],
        modalImprimirFact:false,
        modalImprimirProp:false,
        cargandoImprimirFact:false,
        cargandoImprimirProp:false
    }
    
    obtenerLogoEmpresa=async()=>{
        //obtencion logo de la empresa
         await this.props.client
         .query({
             query: gql`
                 {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
             `,fetchPolicy:'network-only'             
         }).then(result=>{ 
             if(result.data.adjuntos.length>0){
    
                 var request = require('request').defaults({ encoding: null,withCredentials:true });
    
                 request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                     if (!error && response.statusCode === 200) {
                     let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                     this.setState({logotipo:data})
                     }
                 });
    
             }
         })
     
     }

    
    cargarPuestos= async () =>{
        await this.props.client
        .query({
            query: gql` 
                    {puestosTrabajo{
                      ID_PUESTO,
                      DEPARTAMENTO{AREA{DESCRIPCION}},
                      DEPARTAMENTO{DESCRIPCION},
                      DESCRIPCION,
                      ID_RIESGO_OBJETIVO,
                      RIESGO_OBJETIVO{DESCRIPCION},
                      GRADO_RESPONSABILIDAD,
                      CODIGO_PUESTO,
                      DESC_CORTA,
                      DESC_LARGA,
                      CENTRO_TRABAJO,
                      TAREAS,
                      CONOCIMIENTOS_APTITUDES,
                      RESPONSABILIDAD,
                      ESFUERZO,
                      CONDICIONES_TRABAJO,
                      DESTREZA_EQUIPOS,
                      CONOCIMIENTOS_INFORMATICOS,
                      POLIVALENCIA,
                      EXPERIENCIA,
                      POSTURAS_FORZADAS,
                      MOVIMIENTOS_REPETITIVOS,
                      CONCENTRACION,
                      DIFICULTAD_TECNICA,
                      ORGANIZACION_TRABAJO,
                      RESPONSABILIDAD_MAQUINARIA,
                      JEFATURA,
                      INICIATIVA_POLIVALENCIA,
                      CONTACTO_CLIENTES,
                      TRABAJO_DISTANCIA,
                      POLIVALENCIA_EXIGIDA,
                      RIESGO_PSICOSOCIAL,
                      GENERO_CONOCIMIENTO,
                      GENERO_ESFUERZO,
                      GENERO_RESPONSABILIDAD,
                      GENERO_CONDICIONES}}
                        `,
                        fetchPolicy:'network-only'
            }).then(async result=>{
                console.log(result);
                this.setState({
                  puestos:result.data.puestosTrabajo
                })
            })
    }

    cargarPuestosGral=async()=>{
        
        let puestos=await this.props.client.query({
            query:gql`{puestosTrabajo{
                ID_PUESTO,
                ID_DEPARTAMENTO,
                DEPARTAMENTO{DESCRIPCION},
                DEPARTAMENTO{AREA{DESCRIPCION}}
                DEPARTAMENTO{ID_AREA}DESCRIPCION,
                GRADO_RESPONSABILIDAD,
                ID_RIESGO_OBJETIVO,
                RIESGO_OBJETIVO{DESCRIPCION},
                CODIGO_PUESTO,
                DESC_CORTA,
                DESC_LARGA,
                NUMERO_TRABAJADORES,
                TAREAS,
                CONOCIMIENTOS_APTITUDES,
                RESPONSABILIDAD,
                ESFUERZO,
                CONDICIONES_TRABAJO,
                DESTREZA_EQUIPOS,
                CONOCIMIENTOS_INFORMATICOS,
                POLIVALENCIA,
                EXPERIENCIA,
                POSTURAS_FORZADAS,
                MOVIMIENTOS_REPETITIVOS,
                CONCENTRACION,
                DIFICULTAD_TECNICA,
                ORGANIZACION_TRABAJO,
                RESPONSABILIDAD_MAQUINARIA,
                JEFATURA,
                INICIATIVA_POLIVALENCIA,
                CONTACTO_CLIENTES,
                TRABAJO_DISTANCIA,
                POLIVALENCIA_EXIGIDA,
                RIESGO_PSICOSOCIAL,
                GENERO_CONOCIMIENTO,
                GENERO_ESFUERZO,
                GENERO_RESPONSABILIDAD,
                GENERO_CONDICIONES,
                CENTRO_TRABAJO
            }}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            return result.data.puestosTrabajo
        })
        this.setState({puestosGral:puestos})
    }

    cargarDatosEmpresa=async()=>{         
        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                }

            }
            
        })
    

       return datosEmpresa

}
cargarDatos= async () => {
    let datosEmpresa= await this.cargarDatosEmpresa()
    this.setState({datosEmpresa:datosEmpresa})
}
    
    componentDidMount(){
        this.setState({cargando:true}, async () =>{
            await this.cargarPuestos();
            await this.obtenerLogoEmpresa();
            await this.cargarDatos()
            this.setState({
                cargando:false
            })
        })
    }
    cargarRiesgoColor = (data) => {
      
        let color="";
        switch(data){
            case 1:
                color="green"
            break;
            case 2:
                color="yellow"
            break;
            case 3:
                color="orange"
            break;
            case 4:
                color="red"
            break;
            
            default:
            break;
        }
        return color;
      }

    generarImprimirProp= async()=> {
        this.setState({
            cargandoImprimirProp: true,
            modalImprimirProp: true
        }, async( )=>{
            await this.cargarPuestosGral()
            this.setState({
                cargandoImprimirProp: false
            }, () =>{
                ReactDOM.render(this.ImprimirPropiedades(), document.getElementById('generarProp-pdf'))
            })
        })
    }
    ImprimirPropiedades= () =>{
        let color=''
        return (
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" orientation="landscape" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("registrosretributivos.recursoshumanos",{ns:"page"})}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("valoracionpuesto.puesto",{ns:"page"}).toUpperCase()}</Text>
                </View>
                {this.state.puestosGral?this.state.puestosGral.map((puesto, index) => {
                    color= this.cargarRiesgoColor(puesto.ID_RIESGO_OBJETIVO)
                    return(                        
                        <View key={index}>                                  
                            {index===0 && (
                               <View style={styles.colHead}>
                               <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'12%'}}>{i18next.t("valoracionpuesto.area",{ns:"page"}).toUpperCase()}</Text>
                               <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'17%'}}>{i18next.t("valoracionpuesto.departamento",{ns:"page"}).toUpperCase()}</Text>
                               <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'13%'}}>{i18next.t("valoracionpuesto.puesto",{ns:"page"}).toUpperCase()}</Text>
                               <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'7%'}}>{i18next.t("valoracionpuesto.codigo_puesto",{ns:"page"}).toUpperCase()}</Text>
                               <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("valoracionpuesto.resp",{ns:"page"}).toUpperCase()}</Text>
                               <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'5%'}}>{i18next.t("valoracionpuesto.riesgo",{ns:"page"}).toUpperCase()}</Text>
                               <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("valoracionpuesto.descripcion_breve",{ns:"page"}).toUpperCase()}</Text>
                               <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'19%'}}>{i18next.t("valoracionpuesto.descripcion_detallada",{ns:"page"}).toUpperCase()}</Text>
                               <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'7%'}}>{i18next.t("valoracionpuesto.centrostrabajo",{ns:"page"}).toUpperCase()}</Text>
                           </View>                           
                            )}
                           
                           <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'12%'}}>{puesto.DEPARTAMENTO.AREA?puesto.DEPARTAMENTO.AREA.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'17%'}}>{puesto.DEPARTAMENTO?puesto.DEPARTAMENTO.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'13%'}}>{puesto.DESCRIPCION?puesto.DESCRIPCION:""}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'7%'}}>{puesto.CODIGO_PUESTO?puesto.CODIGO_PUESTO:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.GRADO_RESPONSABILIDAD?puesto.GRADO_RESPONSABILIDAD:''}</Text>
                                    <Text style={{backgroundColor:`${color}`,textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'5%'}}>{puesto.RIESGO_OBJETIVO?puesto.RIESGO_OBJETIVO.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.DESC_CORTA?puesto.DESC_CORTA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'19%'}}>{puesto.DESC_LARGA?puesto.DESC_LARGA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'7%'}}>{puesto.CENTRO_TRABAJO?puesto.CENTRO_TRABAJO:''}</Text>
                            </View>
                                           
                            
                        </View>
                        
                    )
                }):<></>}       
            </View>
            <Text
            style={styles.footerPage}
            render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
            fixed
            />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )
}  
    
    

    generarImprimir= async()=>{
        this.setState({
            cargandoImprimir:true,
            modalImprimir:true
        }, async()=>{
            await this.cargarPuestosGral()
            this.setState({cargandoImprimir:false}, () =>{
                ReactDOM.render(this.InformeValoracionPuestos(), document.getElementById('generar-pdf'))
            })

        })
    }

    InformeValoracionPuestos= () => {
        let color=''
        return(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" orientation="landscape" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("registrosretributivos.recursoshumanos",{ns:"page"})}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("valoracionpuesto.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                {this.state.puestosGral?this.state.puestosGral.map((puesto, index)=>{
                    color= this.cargarRiesgoColor(puesto.ID_RIESGO_OBJETIVO)
                    return (
                            <View key={index}>
                                {index===0 && (
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'12%'}}>{i18next.t("valoracionpuesto.area",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'17%'}}>{i18next.t("valoracionpuesto.departamento",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'13%'}}>{i18next.t("valoracionpuesto.puesto",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'7%'}}>{i18next.t("valoracionpuesto.codigo_puesto",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("valoracionpuesto.resp",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'5%'}}>{i18next.t("valoracionpuesto.riesgo",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("valoracionpuesto.descripcion_breve",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'19%'}}>{i18next.t("valoracionpuesto.descripcion_detallada",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'7%'}}>{i18next.t("valoracionpuesto.centrostrabajo",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                                )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'12%'}}>{puesto.DEPARTAMENTO.AREA?puesto.DEPARTAMENTO.AREA.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'17%'}}>{puesto.DEPARTAMENTO?puesto.DEPARTAMENTO.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'13%'}}>{puesto.DESCRIPCION?puesto.DESCRIPCION:""}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'7%'}}>{puesto.CODIGO_PUESTO?puesto.CODIGO_PUESTO:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.GRADO_RESPONSABILIDAD?puesto.GRADO_RESPONSABILIDAD:''}</Text>
                                    <Text style={{backgroundColor:`${color}`,textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'5%'}}>{puesto.RIESGO_OBJETIVO?puesto.RIESGO_OBJETIVO.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.DESC_CORTA?puesto.DESC_CORTA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'19%'}}>{puesto.DESC_LARGA?puesto.DESC_LARGA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'7%'}}>{puesto.CENTRO_TRABAJO?puesto.CENTRO_TRABAJO:''}</Text>
                                </View>
                                
                            </View>
                        )
                    }
                ):<></>}
              <View style={{margin:10}}>
              <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("valoracionpuesto.titlefactores",{ns:"page"}).toUpperCase()}</Text>
                </View>

                <View style={{margin:6}}>
                    <Text style={styles.labelTitle}>{i18next.t("valoracionpuesto.titleprint1",{ns:"page"})}</Text>
                </View>
              {this.state.puestosGral? this.state.puestosGral.map((puesto, index) => {
                  return(
                        <View key={index}>
                            {index===0 && (
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("valoracionpuesto.area",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("valoracionpuesto.departamento",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("valoracionpuesto.namepuesto",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("valoracionpuesto.conocimientosaptitudes",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'8%'}}>{i18next.t("valoracionpuesto.destreza",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("valoracionpuesto.conocimientos",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("valoracionpuesto.polivalencia",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'8%'}}>{i18next.t("valoracionpuesto.experiencia",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'8%'}}>{i18next.t("valoracionpuesto.genero",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                            )}
                            <View style={styles.colHead}>                                
                                    
                                   <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO.AREA?puesto.DEPARTAMENTO.AREA.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO?puesto.DEPARTAMENTO.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DESCRIPCION?puesto.DESCRIPCION:""}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.CONOCIMIENTOS_APTITUDES?puesto.CONOCIMIENTOS_APTITUDES:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'8%'}}>{puesto.DESTREZA_EQUIPOS?puesto.DESTREZA_EQUIPOS:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.CONOCIMIENTOS_INFORMATICOS?puesto.CONOCIMIENTOS_INFORMATICOS:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.POLIVALENCIA?puesto.POLIVALENCIA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'8%'}}>{puesto.EXPERIENCIA?puesto.EXPERIENCIA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'9%'}}>{puesto.GENERO_CONOCIMIENTO?puesto.GENERO_CONOCIMIENTO:''}</Text></View>
                        </View>
                  )
              }):<></>}
              <View style={{margin:6}}>
                    <Text style={styles.labelTitle}>{i18next.t("valoracionpuesto.titleprint2",{ns:"page"})}</Text>
                </View>
               {this.state.puestosGral? this.state.puestosGral.map((puesto, index) => {
                  return(
                        <View key={index}>
                            {index===0 && (
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("valoracionpuesto.area",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'17%'}}>{i18next.t("valoracionpuesto.departamento",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("valoracionpuesto.namepuesto",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'8%'}}>{i18next.t("valoracionpuesto.esfuerzo",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("valoracionpuesto.posturas",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("valoracionpuesto.movimientos",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("valoracionpuesto.concentracion",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'8%'}}>{i18next.t("valoracionpuesto.dificultad",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'7%'}}>{i18next.t("valoracionpuesto.genero",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                            )}
                            <View style={styles.colHead}>                                
                                    
                                   <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO.AREA?puesto.DEPARTAMENTO.AREA.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'17%'}}>{puesto.DEPARTAMENTO?puesto.DEPARTAMENTO.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DESCRIPCION?puesto.DESCRIPCION:""}</Text>                                   
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'8%'}}>{puesto.ESFUERZO?puesto.ESFUERZO:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.POSTURAS_FORZADAS?puesto.POSTURAS_FORZADAS:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.MOVIMIENTOS_REPETITIVOS?puesto.MOVIMIENTOS_REPETITIVOS:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.CONCENTRACION?puesto.CONCENTRACION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'8%'}}>{puesto.DIFICULTAD_TECNICA?puesto.DIFICULTAD_TECNICA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'7%'}}>{puesto.GENERO_ESFUERZO?puesto.GENERO_ESFUERZO:''}</Text>
                            </View>
                        </View>
                  )
              }):<></>}
              <View style={{margin:6}}>
                    <Text style={styles.labelTitle}>{i18next.t("valoracionpuesto.titleprint3",{ns:"page"})}</Text>
                </View>
                    {this.state.puestosGral? this.state.puestosGral.map((puesto, index) => {
                  return(
                        <View key={index}>
                            {index===0 && (
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("valoracionpuesto.area",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("valoracionpuesto.departamento",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("valoracionpuesto.namepuesto",{ns:"page"}).toUpperCase()}</Text>                                    
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'8%'}}>{i18next.t("valoracionpuesto.resp",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("valoracionpuesto.organizacion",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("valoracionpuesto.responsabilidad",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'8%'}}>{i18next.t("valoracionpuesto.jefatura",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("valoracionpuesto.iniciativa",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'9%'}}>{i18next.t("valoracionpuesto.genero",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                            )}
                            <View style={styles.colHead}>                                
                                    
                                   <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO.AREA?puesto.DEPARTAMENTO.AREA.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO?puesto.DEPARTAMENTO.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DESCRIPCION?puesto.DESCRIPCION:""}</Text>                                 
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'8%'}}>{puesto.RESPONSABILIDAD?puesto.RESPONSABILIDAD:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.ORGANIZACION_TRABAJO?puesto.ORGANIZACION_TRABAJO:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.RESPONSABILIDAD_MAQUINARIA?puesto.RESPONSABILIDAD_MAQUINARIA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'8%'}}>{puesto.JEFATURA?puesto.JEFATURA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.INICIATIVA_POLIVALENCIA?puesto.INICIATIVA_POLIVALENCIA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'9%'}}>{puesto.GENERO_RESPONSABILIDAD?puesto.GENERO_RESPONSABILIDAD:''}</Text>
                            </View>
                        </View>
                  )
              }):<></>}
              <View style={{margin:6}}>
                    <Text style={styles.labelTitle}>{i18next.t("valoracionpuesto.titleprint4",{ns:"page"})}</Text>
                </View>

                {this.state.puestosGral? this.state.puestosGral.map((puesto, index) => {
                  return(
                        <View key={index}>
                            {index===0 && (
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("valoracionpuesto.area",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("valoracionpuesto.departamento",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("valoracionpuesto.namepuesto",{ns:"page"}).toUpperCase()}</Text>                                    
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("valoracionpuesto.condiciones",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("valoracionpuesto.contacto",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'7%'}}>{i18next.t("valoracionpuesto.distancia",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("valoracionpuesto.exigida",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("valoracionpuesto.psicosocial",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'8%'}}>{i18next.t("valoracionpuesto.genero",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                            )}
                            <View style={styles.colHead}>                                
                                    
                                   <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO.AREA?puesto.DEPARTAMENTO.AREA.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO?puesto.DEPARTAMENTO.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DESCRIPCION?puesto.DESCRIPCION:""}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.CONDICIONES_TRABAJO?puesto.CONDICIONES_TRABAJO:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.CONTACTO_CLIENTES?puesto.CONTACTO_CLIENTES:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'7%'}}>{puesto.TRABAJO_DISTANCIA?puesto.TRABAJO_DISTANCIA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.POLIVALENCIA_EXIGIDA?puesto.POLIVALENCIA_EXIGIDA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.RIESGO_PSICOSOCIAL?puesto.RIESGO_PSICOSOCIAL:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'8%'}}>{puesto.GENERO_CONDICIONES?puesto.GENERO_CONDICIONES:''}</Text>
                            </View>
                        </View>
                  )
              }):<></>}
              <View style={{margin:6}}>
                    <Text style={styles.labelTitle}>{i18next.t("valoracionpuesto.tareas",{ns:"page"}).toUpperCase()}</Text>
                </View>
                {// eslint-disable-next-line
                this.state.puestosGral? this.state.puestosGral.map((puesto, index) => {
                  let tareas=puesto.TAREAS?JSON.parse(puesto.TAREAS):[];
                  for(let tarea of tareas){
                  return (
                        <View key={index}>
                            {index===0 && (
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("valoracionpuesto.area",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("valoracionpuesto.departamento",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("valoracionpuesto.namepuesto",{ns:"page"}).toUpperCase()}</Text>                                    
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'40%'}}>{i18next.t("valoracionpuesto.descripcion",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'15%'}}>VALOR</Text>
                                </View>
                            )}
                              <View style={styles.colHead}>                                
                                      
                                      <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO.AREA?puesto.DEPARTAMENTO.AREA.DESCRIPCION:''}</Text>
                                      <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO?puesto.DEPARTAMENTO.DESCRIPCION:''}</Text>
                                      <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DESCRIPCION?puesto.DESCRIPCION:""}</Text>
                                      <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'40%'}}>{tarea.descripcion}</Text>
                                      <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'15%'}}>{tarea.valor}</Text>
                              </View>
                        </View>
                  )
              }
            }):<></>}
              </View>
            </View>
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )
 }
 generarImprimirFactores= async () =>{
     this.setState({
         cargandoImprimirFact:true,
         modalImprimirFact: true
        }, async () => {
            await this.cargarPuestosGral()
            this.setState({
                cargandoImprimirFact: false
            }, () => {
                ReactDOM.render(this.ImprimirFactores(), document.getElementById('generarFact-pdf'))
            })
        })
 }

 ImprimirFactores= () => (
    <PDFViewer style={{width: '100%', height: '100%'}}>
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.body}>
            <View fixed style={styles.row}>
              <Image
                style={styles.imageHead}
                src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                />
            </View>
            <View fixed style={styles.rowHead}>
                <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
            </View>
            <View fixed style={styles.rowHeadTwo}>
                <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                <Text style={styles.text}>{i18next.t("registrosretributivos.recursoshumanos",{ns:"page"})}</Text>
            </View>
            <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("valoracionpuesto.titlefactores",{ns:"page"}).toUpperCase()}</Text>
            </View>

                <View style={{margin:6}}>
                    <Text style={styles.labelTitle}>{i18next.t("valoracionpuesto.titleprint1",{ns:"page"})}</Text>
                </View>
              {this.state.puestosGral? this.state.puestosGral.map((puesto, index) => {
                  return(
                        <View key={index}>
                            {index===0 && (
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("valoracionpuesto.area",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("valoracionpuesto.departamento",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("valoracionpuesto.namepuesto",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("valoracionpuesto.conocimientosaptitudes",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'8%'}}>{i18next.t("valoracionpuesto.destreza",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("valoracionpuesto.conocimientos",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("valoracionpuesto.polivalencia",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'8%'}}>{i18next.t("valoracionpuesto.experiencia",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'8%'}}>{i18next.t("valoracionpuesto.genero",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                            )}
                            <View style={styles.colHead}>                                
                                    
                                   <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO.AREA?puesto.DEPARTAMENTO.AREA.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO?puesto.DEPARTAMENTO.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DESCRIPCION?puesto.DESCRIPCION:""}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.CONOCIMIENTOS_APTITUDES?puesto.CONOCIMIENTOS_APTITUDES:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'8%'}}>{puesto.DESTREZA_EQUIPOS?puesto.DESTREZA_EQUIPOS:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.CONOCIMIENTOS_INFORMATICOS?puesto.CONOCIMIENTOS_INFORMATICOS:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.POLIVALENCIA?puesto.POLIVALENCIA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'8%'}}>{puesto.EXPERIENCIA?puesto.EXPERIENCIA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'9%'}}>{puesto.GENERO_CONOCIMIENTO?puesto.GENERO_CONOCIMIENTO:''}</Text></View>
                        </View>
                  )
              }):<></>}
              <View style={{margin:6}}>
                    <Text style={styles.labelTitle}>{i18next.t("valoracionpuesto.titleprint2",{ns:"page"})}</Text>
                </View>
               {this.state.puestosGral? this.state.puestosGral.map((puesto, index) => {
                  return(
                        <View key={index}>
                            {index===0 && (
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("valoracionpuesto.area",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'17%'}}>{i18next.t("valoracionpuesto.departamento",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("valoracionpuesto.namepuesto",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'8%'}}>{i18next.t("valoracionpuesto.esfuerzo",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("valoracionpuesto.posturas",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("valoracionpuesto.movimientos",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("valoracionpuesto.concentracion",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'8%'}}>{i18next.t("valoracionpuesto.dificultad",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'7%'}}>{i18next.t("valoracionpuesto.genero",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                            )}
                            <View style={styles.colHead}>                                
                                    
                                   <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO.AREA?puesto.DEPARTAMENTO.AREA.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'17%'}}>{puesto.DEPARTAMENTO?puesto.DEPARTAMENTO.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DESCRIPCION?puesto.DESCRIPCION:""}</Text>                                   
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'8%'}}>{puesto.ESFUERZO?puesto.ESFUERZO:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.POSTURAS_FORZADAS?puesto.POSTURAS_FORZADAS:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.MOVIMIENTOS_REPETITIVOS?puesto.MOVIMIENTOS_REPETITIVOS:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.CONCENTRACION?puesto.CONCENTRACION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'8%'}}>{puesto.DIFICULTAD_TECNICA?puesto.DIFICULTAD_TECNICA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'7%'}}>{puesto.GENERO_ESFUERZO?puesto.GENERO_ESFUERZO:''}</Text>
                            </View>
                        </View>
                  )
              }):<></>}
              <View style={{margin:6}}>
                    <Text style={styles.labelTitle}>{i18next.t("valoracionpuesto.titleprint3",{ns:"page"})}</Text>
                </View>
                    {this.state.puestosGral? this.state.puestosGral.map((puesto, index) => {
                  return(
                        <View key={index}>
                            {index===0 && (
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("valoracionpuesto.area",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("valoracionpuesto.departamento",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("valoracionpuesto.namepuesto",{ns:"page"}).toUpperCase()}</Text>                                    
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'8%'}}>{i18next.t("valoracionpuesto.resp",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("valoracionpuesto.organizacion",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("valoracionpuesto.responsabilidad",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'8%'}}>{i18next.t("valoracionpuesto.jefatura",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("valoracionpuesto.iniciativa",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'9%'}}>{i18next.t("valoracionpuesto.genero",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                            )}
                            <View style={styles.colHead}>                                
                                    
                                   <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO.AREA?puesto.DEPARTAMENTO.AREA.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO?puesto.DEPARTAMENTO.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DESCRIPCION?puesto.DESCRIPCION:""}</Text>                                 
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'8%'}}>{puesto.RESPONSABILIDAD?puesto.RESPONSABILIDAD:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.ORGANIZACION_TRABAJO?puesto.ORGANIZACION_TRABAJO:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.RESPONSABILIDAD_MAQUINARIA?puesto.RESPONSABILIDAD_MAQUINARIA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'8%'}}>{puesto.JEFATURA?puesto.JEFATURA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.INICIATIVA_POLIVALENCIA?puesto.INICIATIVA_POLIVALENCIA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'9%'}}>{puesto.GENERO_RESPONSABILIDAD?puesto.GENERO_RESPONSABILIDAD:''}</Text>
                            </View>
                        </View>
                  )
              }):<></>}
              <View style={{margin:6}}>
                    <Text style={styles.labelTitle}>{i18next.t("valoracionpuesto.titleprint4",{ns:"page"})}</Text>
                </View>

                {this.state.puestosGral? this.state.puestosGral.map((puesto, index) => {
                  return(
                        <View key={index}>
                            {index===0 && (
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("valoracionpuesto.area",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("valoracionpuesto.departamento",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("valoracionpuesto.namepuesto",{ns:"page"}).toUpperCase()}</Text>                                    
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("valoracionpuesto.condiciones",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("valoracionpuesto.contacto",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'7%'}}>{i18next.t("valoracionpuesto.distancia",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("valoracionpuesto.exigida",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("valoracionpuesto.psicosocial",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'8%'}}>{i18next.t("valoracionpuesto.genero",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                            )}
                            <View style={styles.colHead}>                                
                                    
                                   <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO.AREA?puesto.DEPARTAMENTO.AREA.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO?puesto.DEPARTAMENTO.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DESCRIPCION?puesto.DESCRIPCION:""}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.CONDICIONES_TRABAJO?puesto.CONDICIONES_TRABAJO:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.CONTACTO_CLIENTES?puesto.CONTACTO_CLIENTES:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'7%'}}>{puesto.TRABAJO_DISTANCIA?puesto.TRABAJO_DISTANCIA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.POLIVALENCIA_EXIGIDA?puesto.POLIVALENCIA_EXIGIDA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.RIESGO_PSICOSOCIAL?puesto.RIESGO_PSICOSOCIAL:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'8%'}}>{puesto.GENERO_CONDICIONES?puesto.GENERO_CONDICIONES:''}</Text>
                            </View>
                        </View>
                  )
              }):<></>}
              <View style={{margin:6}}>
                    <Text style={styles.labelTitle}>{i18next.t("valoracionpuesto.tareas",{ns:"page"}).toUpperCase()}</Text>
                </View>
                {
                    // eslint-disable-next-line
                this.state.puestosGral? this.state.puestosGral.map((puesto, index) => {
                  let tareas=puesto.TAREAS?JSON.parse(puesto.TAREAS):[];
                  for(let tarea of tareas){
                  return (
                        <View key={index}>
                            {index===0 && (
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("valoracionpuesto.area",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("valoracionpuesto.departamento",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("valoracionpuesto.namepuesto",{ns:"page"}).toUpperCase()}</Text>                                    
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'40%'}}>{i18next.t("valoracionpuesto.descripcion",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'15%'}}>VALOR</Text>
                                </View>
                            )}
                              <View style={styles.colHead}>                                
                                      
                                      <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO.AREA?puesto.DEPARTAMENTO.AREA.DESCRIPCION:''}</Text>
                                      <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO?puesto.DEPARTAMENTO.DESCRIPCION:''}</Text>
                                      <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DESCRIPCION?puesto.DESCRIPCION:""}</Text>
                                      <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'40%'}}>{tarea.descripcion}</Text>
                                      <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'15%'}}>{tarea.valor}</Text>
                              </View>
                        </View>
                  )
              }
            }):<></>}
        </View>
        <Text
        style={styles.footerPage}
        render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
        fixed
        />
        <Image
                style={styles.icono}
                src={iconoFooter}
                fixed
                />
        <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
      </Page>
    </Document>
  </PDFViewer>
 )
    generarCsv=async() =>{
        await this.cargarDatosCsv();
        this.Csv.link.click();
    }

    cargarDatosCsv= async()=>{
        this.setState({cargandoCsv:true})
        await this.cargarPuestosGral()
        let registrosCsv=[]

        for(let registro of this.state.puestosGral){

            registrosCsv.push({"Área":registro.DEPARTAMENTO.AREA.DESCRIPCION,
                                "Departamento": registro.DEPARTAMENTO.DESCRIPCION,
                                "Nombre Puesto": registro.DESCRIPCION,
                                "Código Puesto":registro.CODIGO_PUESTO,
                                "Responsabilidad":registro.GRADO_RESPONSABILIDAD, 
                                "Riesgo Objetivo":registro.RIESGO_OBJETIVO.DESCRIPCION,
                                "Descripción Corta": registro.DESC_CORTA,
                                "Descripción Larga": registro.DESC_LARGA,
                                "Centro de Trabajo": registro.CENTRO_TRABAJO,
                                "Conocimientos y Aptitudes": registro.CONOCIMIENTOS_APTITUDES,
                                "Destreza y manejo de equipos": registro.DESTREZA_EQUIPOS,
                                "Conocimientos Informáticos": registro.CONOCIMIENTOS_INFORMATICOS,
                                "Polivalencia y Creatividad":registro.POLIVALENCIA,
                                "Experiencia": registro.EXPERIENCIA,
                                "Género Conocimiento": registro.GENERO_CONOCIMIENTO,
                                "Esfuerzo":registro.ESFUERZO,
                                "Posturas Forzadas": registro.POSTURAS_FORZADAS,
                                "Movimientos Repetivos": registro.MOVIMIENTOS_REPETITIVOS,
                                "Concentración y esfuerzo emocional": registro.CONCENTRACION,
                                "Dificultad Técnica": registro.DIFICULTAD_TECNICA,
                                "Género Esfuerzo": registro.GENERO_ESFUERZO,
                                "Responsabilidad2": registro.RESPONSABILIDAD,
                                "Organización del trabajo": registro.ORGANIZACION_TRABAJO,
                                "Responsabilidad Maquinaria": registro.RESPONSABILIDAD_MAQUINARIA,
                                "Jefatura": registro.JEFATURA,
                                "Iniciativa y Polivalencia": registro.INICIATIVA_POLIVALENCIA,
                                "Género Responsabilidad": registro.GENERO_RESPONSABILIDAD,
                                "Condiciones de Trabajo": registro.CONDICIONES_TRABAJO,
                                "Contacto con Cliente": registro.CONTACTO_CLIENTES,
                                "Trabajo a distancia": registro.TRABAJO_DISTANCIA,
                                "Polivalencia exigida": registro.POLIVALENCIA_EXIGIDA,
                                "Riesgo psicosocial": registro.RIESGO_PSICOSOCIAL,
                                "Género Condiciones": registro.GENERO_CONDICIONES,
                                "Tareas":registro.TAREAS
                            })
        }

        registrosCsv.sort(function(a,b){
            return('' + a.Nombre).localeCompare(b.Nombre)
        })

        this.setState({datosCsv: registrosCsv})

    this.setState({cargandoCsv:false})
    }

    
    render() {

        document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("valoracionpuesto.title",{ns:"page"})
        const handleOnSelect=(row, isSelect) => {
        if (isSelect) {
        this.setState({
            puestoSeleccionado: row,
            seleccionado:[row],
            tareas:row.TAREAS?JSON.parse(row.TAREAS):[],
            cargandoPuest:false
        });
        }
        return true;
        }

        function colorFormatter(cell, row) {
            let color=''
            switch (row.ID_RIESGO_OBJETIVO) {
                case 1:
                    color='green'                    
                    break;
                case 2:
                    color='yellow'
                    break;
                case 3: 
                    color='orange'
                    break;
                case 4:
                    color='red'
                    break;
                default:
                    break;
            }

            return {
                backgroundColor: color
            };
        }

        function areaFormatter(cell, row) {
        return (
        (row.DEPARTAMENTO.AREA.DESCRIPCION)?row.DEPARTAMENTO.AREA.DESCRIPCION:''
        );
        }

        function dptoFormatter(cell, row) {
            return (
                (row.DEPARTAMENTO)?row.DEPARTAMENTO.DESCRIPCION:''
            );
            }
            
        function riesgoFormatter(cell, row) {
            return (
                (row.RIESGO_OBJETIVO)?row.RIESGO_OBJETIVO.DESCRIPCION:''
            );
            }
        const columns = [
        {
        dataField: 'DEPARTAMENTO',
        text: i18next.t("valoracionpuesto.area",{ns:"page"}),
        formatter: areaFormatter
        },{
            dataField: 'ID_PUESTO',
            text: i18next.t("valoracionpuesto.departamento",{ns:"page"}),
            formatter: dptoFormatter
            },{
                dataField: 'DESCRIPCION',
                text: i18next.t("valoracionpuesto.puesto",{ns:"page"})
                },{
                    dataField: 'CODIGO_PUESTO',
                    text: i18next.t("valoracionpuesto.codigo_puesto",{ns:"page"})
                    },{
                        dataField: 'GRADO_RESPONSABILIDAD',
                        text: i18next.t("valoracionpuesto.resp",{ns:"page"})
                        },{
                            dataField: 'RIESGO_OBJETIVO',
                            text: i18next.t("valoracionpuesto.riesgo",{ns:"page"}),
                            formatter: riesgoFormatter,
                            style: colorFormatter
                            },{
                                dataField: 'DESC_CORTA',
                                text: i18next.t("valoracionpuesto.descripcion_breve",{ns:"page"}),
                                formatter: strFormatter
                                },{
                                    dataField: 'DESC_LARGA',
                                    text: i18next.t("valoracionpuesto.descripcion_detallada",{ns:"page"}),
                                    formatter: strFormatter
                                    },{
                                        dataField: 'CENTRO_TRABAJO',
                                        text: i18next.t("valoracionpuesto.centrostrabajo",{ns:"page"}),
                                        formatter: strFormatter
                                        }
        ];
        
        
        function valFormatter(cell, row) {
        return (
            (cell)?cell:0
        );
        }

        function strFormatter(cell, row) {
            return (
                (cell)?cell:''
            );
        }

        const columns1 = [
        {
        dataField: 'DESTREZA_EQUIPOS',
        text: i18next.t("valoracionpuesto.destreza",{ns:"page"}),
        formatter: valFormatter
        },{
            dataField: 'CONOCIMIENTOS_INFORMATICOS',
            text: i18next.t("valoracionpuesto.conocimientos",{ns:"page"}),
            formatter: valFormatter
            },{
                dataField: 'POLIVALENCIA',
                text: i18next.t("valoracionpuesto.polivalencia",{ns:"page"}),
                formatter: valFormatter
                },{
                    dataField: 'EXPERIENCIA',
                    text: i18next.t("valoracionpuesto.experiencia",{ns:"page"}),
                    formatter: valFormatter
                    },{
                        dataField: 'GENERO_CONOCIMIENTO',
                        text: i18next.t("valoracionpuesto.genero",{ns:"page"}),
                        formatter: strFormatter
                        }
        ];

        const columns2 = [
            {
            dataField: 'POSTURAS_FORZADAS',
            text: i18next.t("valoracionpuesto.posturas",{ns:"page"}),
            formatter: valFormatter
            },{
                dataField: 'MOVIMIENTOS_REPETITIVOS',
                text: i18next.t("valoracionpuesto.movimientos",{ns:"page"}),
                formatter: valFormatter
                },{
                    dataField: 'CONCENTRACION',
                    text: i18next.t("valoracionpuesto.concentracion",{ns:"page"}),
                    formatter: valFormatter
                    },{
                        dataField: 'DIFICULTAD_TECNICA',
                        text: i18next.t("valoracionpuesto.dificultad",{ns:"page"}),
                        formatter: valFormatter
                        },{
                            dataField: 'GENERO_ESFUERZO',
                            text: i18next.t("valoracionpuesto.genero",{ns:"page"}),
                            formatter: strFormatter
                            }
            ];

        const columns3 = [
            {
            dataField: 'ORGANIZACION_TRABAJO',
            text: i18next.t("valoracionpuesto.organizacion",{ns:"page"}),
            formatter: valFormatter
            },{
                dataField: 'RESPONSABILIDAD_MAQUINARIA',
                text: i18next.t("valoracionpuesto.responsabilidad",{ns:"page"}),
                formatter: valFormatter
                },{
                    dataField: 'JEFATURA',
                    text: i18next.t("valoracionpuesto.jefatura",{ns:"page"}),
                    formatter: valFormatter
                    },{
                        dataField: 'INICIATIVA_POLIVALENCIA',
                        text: i18next.t("valoracionpuesto.iniciativa",{ns:"page"}),
                        formatter: valFormatter
                        },{
                            dataField: 'GENERO_RESPONSABILIDAD',
                            text: i18next.t("valoracionpuesto.genero",{ns:"page"}),
                            formatter: strFormatter
                            }
            ];
    
        const columns4 = [
            {
            dataField: 'CONTACTO_CLIENTES',
            text: i18next.t("valoracionpuesto.contacto",{ns:"page"}),
            formatter: valFormatter
            },{
                dataField: 'TRABAJO_DISTANCIA',
                text: i18next.t("valoracionpuesto.distancia",{ns:"page"}),
                formatter: valFormatter
                },{
                    dataField: 'POLIVALENCIA_EXIGIDA',
                    text: i18next.t("valoracionpuesto.exigida",{ns:"page"}),
                    formatter: valFormatter
                    },{
                        dataField: 'RIESGO_PSICOSOCIAL',
                        text: i18next.t("valoracionpuesto.psicosocial",{ns:"page"}),
                        formatter: valFormatter
                        },{
                            dataField: 'GENERO_CONDICIONES',
                            text: i18next.t("valoracionpuesto.genero",{ns:"page"}),
                            formatter: strFormatter
                            }
            ];

        const columns5 = [
            {
            dataField: 'descripcion',
            text: i18next.t("valoracionpuesto.descripcion",{ns:"page"}),
            formatter: strFormatter
            },{
                dataField: 'valor',
                text: i18next.t("valoracionpuesto.valor",{ns:"page"}),
                formatter: strFormatter
                }
            ];

        const cerrarModalImprimir=()=>{
            this.setState({
                modalImprimir:false,
                modalImprimirProp:false,
                modalImprimirFact: false
            })
        }
        

        

       
        return (
            <>
            {this.state.cargando?
                <Cargando/>
            :
            <>
            <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={i18next.t("print",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimir}
                    style={styles.dialogL}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id="generar-pdf">
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={i18next.t("valoracionpuesto.print1",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirProp}
                    style={styles.dialogL}
                >
                    {!this.state.cargandoImprimirProp?
                            <div style={{ width: '100%', height: '600px'}} id="generarProp-pdf">
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={i18next.t("valoracionpuesto.print2",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirFact}
                    style={styles.dialogL}
                >
                    {!this.state.cargandoImprimirFact?
                            <div style={{ width: '100%', height: '600px'}} id="generarFact-pdf">
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
            <div id="contenedor-valoracion-puestos">
                <div className="pixelAlrededor">
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("valoracionpuesto.title",{ns:"page"})}</Callout>
                        <Navbar>
                            <NavbarGroup align={Alignment.LEFT}>
                               <ButtonGroup align={Alignment.LEFT}>
                                    <Tooltip content={i18next.t("printgral",{ns:"global"})} position={Position.RIGHT}>
                                        <Button key='botonImprimir' className="bp3-minimal" icon='print' text={i18next.t("printgral",{ns:"global"})} id='boton-imprimir' onClick={() => this.generarImprimir()}/>
                                    </Tooltip>
                                    <Tooltip content={i18next.t("valoracionpuesto.print1",{ns:"page"})} position={Position.RIGHT}>
                                        <Button key='botonImprimir' className="bp3-minimal" icon='print' text={i18next.t("valoracionpuesto.print1",{ns:"page"})} id='boton-imprimir' onClick={() => this.generarImprimirProp()}/>
                                    </Tooltip>
                                    <Tooltip content={i18next.t("valoracionpuesto.print2",{ns:"page"})} position={Position.RIGHT}>
                                        <Button key='botonImprimir' className="bp3-minimal" icon='print' text={i18next.t("valoracionpuesto.print2",{ns:"page"})} id='boton-imprimir' onClick={() => this.generarImprimirFactores()}/>
                                    </Tooltip>
                                    
                                    <CSVLink ref={(r) => {this.Csv = r;}} data={this.state.datosCsv} separator={';'} enclosingCharacter={``} filename={"ValoracionPuestos.csv"}/>
                                    {!this.state.cargandoCsv?
                                    <Tooltip content={i18next.t("exportar",{ns:"global"})} position={Position.RIGHT}>
                                        <Button className='bp3-minimal' icon='document' text={i18next.t("exportar",{ns:"global"})} onClick={()=> this.generarCsv()}/>
                                    </Tooltip>
                                    :
                                    <Button className="bp3-minimal" icon="document" text={i18next.t("cargando",{ns:"global"})}/>
                                    }
                               </ButtonGroup>
                            </NavbarGroup>
                        </Navbar>
                        <>
                        <BootstrapTable
                        keyField='ID_PUESTO'
                        data={ this.state.puestos }
                        columns={ columns }
                        selectRow={ const_table.selectRow(handleOnSelect) }
                        pagination={ paginationFactory(const_table.options) }
                        headerClasses="header-class"
                        rowClasses="row-class"
                        hover
                        condensed
                        noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                        />
                        </>
                    </Card>
                </div>
                <div className="contenedor_factoresyTareas">
                    <Card elevation={Elevation.TWO} interactive={true}>
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">
                          {i18next.t("valoracionpuesto.titlefactores",{ns:"page"})} {this.state.puestoSeleccionado?`${this.state.puestoSeleccionado.DESCRIPCION?i18next.t("de",{ns:"global"})+' '+this.state.puestoSeleccionado.DESCRIPCION:''}`: ""}
                        </Callout>
                        <br></br>
                        <Callout className="bp3-intent-danger">
                            {this.state.puestoSeleccionado?i18next.t("valoracionpuesto.conocimientosaptitudes",{ns:"page"})+` : ${this.state.puestoSeleccionado.CONOCIMIENTOS_APTITUDES?this.state.puestoSeleccionado.CONOCIMIENTOS_APTITUDES:''}%`: i18next.t("valoracionpuesto.select1",{ns:"page"})}
                        </Callout>
                            <BootstrapTable
                            keyField='ID_PUESTO'
                            data={ this.state.seleccionado?this.state.seleccionado:[] }
                            columns={ columns1 }
                            pagination={ paginationFactory(const_table.options) }
                            headerClasses="header-class"
                            rowClasses="row-class"
                            hover
                            condensed
                            noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                            />
                            
                        <br></br>
                        <Callout className="bp3-intent-danger">
                        {this.state.puestoSeleccionado?i18next.t("valoracionpuesto.esfuerzo",{ns:"page"})+` : ${this.state.puestoSeleccionado.ESFUERZO?this.state.puestoSeleccionado.ESFUERZO:''}%`: i18next.t("valoracionpuesto.select2",{ns:"page"})}
                        </Callout>
                            <BootstrapTable
                            keyField='ID_PUESTO'
                            data={ this.state.seleccionado?this.state.seleccionado:[] }
                            columns={ columns2 }
                            pagination={ paginationFactory(const_table.options) }
                            headerClasses="header-class"
                            rowClasses="row-class"
                            hover
                            condensed
                            noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                            />
                        <br></br>
                        <Callout className="bp3-intent-danger">
                        {this.state.puestoSeleccionado?i18next.t("valoracionpuesto.resp",{ns:"page"})+` : ${this.state.puestoSeleccionado.RESPONSABILIDAD?this.state.puestoSeleccionado.RESPONSABILIDAD:''}%`: i18next.t("valoracionpuesto.select3",{ns:"page"})}
                        </Callout>
                            <BootstrapTable
                            keyField='ID_PUESTO'
                            data={ this.state.seleccionado?this.state.seleccionado:[] }
                            columns={ columns3 }
                            pagination={ paginationFactory(const_table.options) }
                            headerClasses="header-class"
                            rowClasses="row-class"
                            hover
                            condensed
                            noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                            />
                        <br></br>
                        <Callout className="bp3-intent-danger">
                            {this.state.puestoSeleccionado?i18next.t("valoracionpuesto.condiciones",{ns:"page"})+` : ${this.state.puestoSeleccionado.CONDICIONES_TRABAJO?this.state.puestoSeleccionado.CONDICIONES_TRABAJO:''}%`: i18next.t("valoracionpuesto.select4",{ns:"page"})}
                        </Callout>
                            <BootstrapTable
                            keyField='ID_PUESTO'
                            data={ this.state.seleccionado?this.state.seleccionado:[] }
                            columns={ columns4 }
                            pagination={ paginationFactory(const_table.options) }
                            headerClasses="header-class"
                            rowClasses="row-class"
                            hover
                            condensed
                            noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                            />
                        <br></br>
                        <Callout className="bp3-intent-danger">
                            {this.state.puestoSeleccionado?i18next.t("valoracionpuesto.tareas",{ns:"page"})+``: i18next.t("valoracionpuesto.select5",{ns:"page"})}
                        </Callout>
                        <BootstrapTable
                            keyField='valor'
                            data={ this.state.tareas?this.state.tareas:[] }
                            columns={ columns5 }
                            pagination={ paginationFactory(const_table.options) }
                            headerClasses="header-class"
                            rowClasses="row-class"
                            hover
                            condensed
                            noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                            />
                            
                    </Card>
                                            
                                                          
                   
                </div>
            </div>
         </> 
         
         }  
     </>
        )
      
    }
}

export default withApollo(ValoracionPuestos)