import React from "react"
import { Card, Elevation, FormGroup, TextArea } from "@blueprintjs/core"
 
export default class EmpleadosInformacionContractual extends React.Component {

    state={
        laboral:null,
        coincidente:null
    }

    render(){

        const handleCambiarLaboral=(ev)=>{
            this.setState({laboral: ev.currentTarget.value})
        }

        const handleCambiarCoincidente=(ev)=>{
            this.setState({coincidente: ev.currentTarget.value})
        }

        return (
            <>
                <div>
                    <div className="pixelAlrededor tablaMitadIzquierda">
                        <Card interactive={true} elevation={Elevation.TWO}>
                            <FormGroup
                                label={this.props.i18next.t("trabajadores.experiencia",{ns:"page"})+":"}
                                labelFor="experiencia-laboral"
                            >
                                <TextArea onChange={handleCambiarLaboral} value={this.state.laboral?this.state.laboral:''} fill={true}/>
                            </FormGroup>
                        </Card>
                    </div>
                    <div className="pixelAlrededor tablaMitadDerecha">
                        <Card interactive={true} elevation={Elevation.TWO}>
                            <FormGroup
                                label={this.props.i18next.t("trabajadores.experiencia_coincidente",{ns:"page"})+":"}
                                labelFor="experiencia-laboral-coincidente"
                            >
                                <TextArea onChange={handleCambiarCoincidente} value={this.state.coincidente?this.state.coincidente:''} fill={true}/>
                            </FormGroup>
                        </Card>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
            </>
        )
    }
}