import React from "react"
import { Card, Elevation,Button,Navbar,Classes,AnchorButton,Intent,Tooltip,Dialog,Alignment, FormGroup, InputGroup, TextArea } from "@blueprintjs/core"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import const_table from '../BootstrapTable';
import { Cargando } from "../Cargando";

export default class EmpleadosInformacionContractual extends React.Component {

    state={

        formacionAcademica:null,
        otrasTitulaciones:null,
        areaConocimientos:null,
        habilidades:null,
        aptitudes:null,
        actitudes:null,
        especialidades:null,
        cursos:[],
        conocimientosEspecificos:null,
        nuevoCursoActivado:true,
        eliminarCursoActivado:false,
        nuevoCurso:null,
        duracion:null,
        modalCurso:false,
        cursoSeleccionado:null,
        modalBorrarAbierto:false,
        cargando:false
    }

    render(){


        const handleCambiarForamcionAcademica = (e) => {
            this.setState({formacionAcademica:e.currentTarget.value})
        }

        const handleCambiarOtrasTitulaciones = (e) => {
            this.setState({otrasTitulaciones:e.currentTarget.value})
        }

        const handleCambiarAreaConocimiento = (e) => {
            this.setState({areaConocimientos:e.currentTarget.value})
        }

        const handleCambiarConocimientosEspecificos = (e) => {
            this.setState({conocimientosEspecificos:e.currentTarget.value})
        }

        const handleCambiarHabilidades = (e) => {
            this.setState({habilidades:e.currentTarget.value})
        }

        const handleCambiarAptitudes = (e) => {
            this.setState({aptitudes:e.currentTarget.value})
        }

        const handleCambiarActitudes = (e) => {
            this.setState({actitudes:e.currentTarget.value})
        }

        const handleCambiarEspecialidades = (e) => {
            this.setState({especialidades:e.currentTarget.value})
        }

        const handleOnSelect = (row,isSelect) => {
            if (isSelect) {
                this.setState({
                    cursoSeleccionado: row
                });
            }
        }

        const abrirModalNuevoCurso=()=>{

            this.setState({
                nuevoCurso:null,
                duracion:null,
                nuevoCursoActivado:true,
                editarCursoActivado:false,
                eliminarCursoActivado:false,
                modalCurso:true,
                cursoSeleccionado:null,
                cargando:true
            })

        }

        const abrirModalActualizarCurso=()=>{


            if(!this.state.cursoSeleccionado){
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}));
                return
            }

            this.setState({
                nuevoCurso:this.state.cursoSeleccionado.curso,
                duracion:this.state.cursoSeleccionado.duracion,
                nuevoCursoActivado:false,
                editarCursoActivado:true,
                eliminarCursoActivado:false,
                modalCurso:true,
                cargando:true
            })

        }

        const handleNuevoCurso = (e) => {
            this.setState({nuevoCurso:e.currentTarget.value})
        }

        const handleDuracion = (e) => {
            this.setState({duracion:e.currentTarget.value})
        }

        const anadirCurso = async() => {

            if(!this.state.nuevoCurso || !this.state.duracion)return

            let cursos=this.state.cursos
            let id=this.state.cursos.length>0?this.state.cursos[this.state.cursos.length-1].id+1:1
            cursos.push({
                id:id,
                curso:this.state.nuevoCurso,
                duracion:this.state.duracion
            })
            this.setState({
                cursos:cursos,
                modalCurso:false,
                cargando:false
            })
            
        }

        const actualizarCurso=()=>{
            
            if(!this.state.cursoSeleccionado){
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}));
                return
            }

            let cursos=this.state.cursos
            
            this.setState({cursos:cursos,
                modalCurso:false,
                cargando:false})
        }

        const eliminarCurso=()=>{

            if(!this.state.cursoSeleccionado){
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            let cursos=this.state.cursos
            cursos=this.state.cursos.filter(curso => curso.id !== this.state.cursoSeleccionado.id)
            this.setState({
                cursos:cursos,
                cursoSeleccionado:null,
                nuevoCursoActivado:false,
                editarCursoActivado:false,
                eliminarCursoActivado:true,
                modalBorrarAbierto:false
            })

        }

        const abrirModalBorrarCurso=()=>{
            if(!this.state.cursoSeleccionado){
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
                return
            }
                this.setState({
                    nuevoCursoActivado:false,
                    editarCursoActivado:false,
                    eliminarCursoActivado:true,
                    modalBorrarAbierto:true
                })
            
        }

        const columns = [
            {
                dataField: 'curso',
                text: this.props.i18next.t("trabajadores.curso",{ns:"page"})
            },
            {
                dataField: 'duracion',
                text: this.props.i18next.t("trabajadores.duracion",{ns:"page"})
            }
        ]

        return (
            <>

                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={()=>{this.setState({modalCurso:false,cargando:<i class="fas fa-bullseye    "></i>})}}
                    title={this.state.nuevoCurso?this.props.i18next.t("agregar",{ns:"global"}):this.props.i18next.t("edit",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalCurso}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                        label={this.props.i18next.t("trabajadores.curso",{ns:"page"})+":"}
                        labelFor="curso"
                        intent="danger"
                        helperText={this.state.nuevoCurso?"":this.props.i18next.t("emptyfield",{ns:"global"})+this.props.i18next.t("trabajadores.curso",{ns:"page"})}
                        >
                            <InputGroup id="texto-nombre-curso" onChange={handleNuevoCurso} value={this.state.nuevoCurso}  placeholder={this.props.i18next.t("placeholder",{ns:"global"})} intent={this.state.nuevoCurso?"primary":"danger"} />
                        </FormGroup>
                        <FormGroup
                        label={this.props.i18next.t("trabajadores.duracion",{ns:"page"})+":"}
                        labelFor="duracion"
                        intent="danger"
                        helperText={this.state.duracion?"":this.props.i18next.t("emptyfield",{ns:"global"})+this.props.i18next.t("trabajadores.duracion",{ns:"page"})}
                        >
                            <InputGroup id="texto-nombre-convenio" onChange={handleDuracion} value={this.state.duracion}  placeholder={this.props.i18next.t("placeholder",{ns:"global"})} intent={this.state.duracion?"primary":"danger"} />
                        </FormGroup>

                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={this.state.nuevoCursoActivado?this.props.i18next.t("agregar",{ns:"global"}):this.props.i18next.t("edit",{ns:"global"})} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={this.state.nuevoCursoActivado?anadirCurso:actualizarCurso}
                                >
                                    {this.props.i18next.t("aceptar",{ns:"global"})}
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                <Button onClick={()=>{this.setState({modalCurso:false,cargando:false})}}>{this.props.i18next.t("cancelar",{ns:"global"})}</Button>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>
                <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="warning-sign"
                        onClose={()=>{this.setState({modalBorrarAbierto:false})}}
                        title={this.props.i18next.t("delete",{ns:"global"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalBorrarAbierto}
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <h2>{this.props.i18next.t("trabajadores.questiondel_formacion",{ns:"page"})}</h2>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={this.props.i18next.t("delete",{ns:"global"})} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={eliminarCurso}
                                >
                                    {this.props.i18next.t("yes",{ns:"global"})}
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                <Button onClick={()=>{this.setState({modalBorrarAbierto:false})}}>{this.props.i18next.t("no",{ns:"global"})}</Button>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>
                <div className="pixelAlrededor">
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <FormGroup
                            label={this.props.i18next.t("trabajadores.formacion_academica",{ns:"page"})+":"}
                            labelFor="formacion-academica"
                        >
                            <InputGroup value={this.state.formacionAcademica?this.state.formacionAcademica:''} onChange={handleCambiarForamcionAcademica} id="formacion-academica" />
                        </FormGroup>
                        <FormGroup
                            label={this.props.i18next.t("trabajadores.otras_titulaciones",{ns:"page"})+":"}
                            labelFor="otras-titulaciones"
                        >
                            <InputGroup  value={this.state.otrasTitulaciones?this.state.otrasTitulaciones:''} onChange={handleCambiarOtrasTitulaciones} id="otras-titulaciones" />
                        </FormGroup>
                        <FormGroup
                            label={this.props.i18next.t("trabajadores.area_conocimiento",{ns:"page"})+":"}
                            labelFor="area-conocimientos"
                        >
                            <InputGroup value={this.state.areaConocimientos?this.state.areaConocimientos:''} onChange={handleCambiarAreaConocimiento} id="area-conocimientos" />
                        </FormGroup>
                        <FormGroup
                            label={this.props.i18next.t("trabajadores.conocimientos_especificos",{ns:"page"})+":"}
                            labelFor="conocimientos-especificos"
                        >
                            <InputGroup value={this.state.conocimientosEspecificos?this.state.conocimientosEspecificos:''} onChange={handleCambiarConocimientosEspecificos} id="conocimiento-especifico" />
                        </FormGroup>
                        <FormGroup
                            label={this.props.i18next.t("trabajadores.habilidades",{ns:"page"})+":"}
                            labelFor="habilidades"
                        >
                            <InputGroup value={this.state.habilidades?this.state.habilidades:''} onChange={handleCambiarHabilidades} id="habilidades" />
                        </FormGroup>
                        <FormGroup
                            label={this.props.i18next.t("trabajadores.aptitudes",{ns:"page"})+":"}
                            labelFor="aptitudes"
                        >
                            <InputGroup  value={this.state.aptitudes?this.state.aptitudes:''} onChange={handleCambiarAptitudes} id="aptitudes" />
                        </FormGroup>
                        <FormGroup
                            label={this.props.i18next.t("trabajadores.actitudes",{ns:"page"})+":"}
                            labelFor="actitudes"
                        >
                            <InputGroup value={this.state.actitudes?this.state.actitudes:''} onChange={handleCambiarActitudes} id="actitudes" />
                        </FormGroup>
                        <FormGroup
                            label={this.props.i18next.t("trabajadores.especialidades",{ns:"page"})+":"}
                            labelFor="especialidades"
                        >
                            <TextArea value={this.state.especialidades?this.state.especialidades:''} fill={true} onChange={handleCambiarEspecialidades} id="especialidades" />
                        </FormGroup>
                        <FormGroup
                            label={this.props.i18next.t("trabajadores.cursos",{ns:"page"})+":"}
                            labelFor="cursos"
                        >
                            <Navbar>
                                <Navbar.Group align={Alignment.LEFT}>
                                    <Button 
                                        icon="add"
                                        className="bp3-minimal"
                                        onClick={abrirModalNuevoCurso}
                                        active={this.state.nuevoCursoActivado}
                                    />
                                    <Button 
                                        icon="edit"
                                        className="bp3-minimal"
                                        onClick={abrirModalActualizarCurso}
                                        active={this.state.editarCursoActivado}
                                    />
                                    <Button 
                                        icon="cross"
                                        className="bp3-minimal"
                                        onClick={abrirModalBorrarCurso}
                                        active={this.state.eliminarCursoActivado}
                                    />
                                </Navbar.Group>
                            </Navbar>
                            {this.state.cargando?
                                <Cargando />
                            :
                                <BootstrapTable
                                    keyField='id'
                                    data={ this.state.cursos }
                                    columns={ columns }
                                    selectRow={ const_table.selectRow(handleOnSelect) }
                                    pagination={ paginationFactory(const_table.options) }
                                    headerClasses="header-class"
                                    rowClasses="row-class"
                                    hover
                                    condensed
                                    noDataIndication={ this.props.i18next.t("datavacio",{ns:"global"}) }
                                />
                            }
                        </FormGroup>
                    </Card>
                </div>
            </>
        )
    }
}