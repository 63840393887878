import React from "react"
import { Card, Elevation, Radio, RadioGroup, InputGroup, FormGroup } from "@blueprintjs/core"

export default class EmpleadosIRPF extends React.Component {

    state={
        irpfValue: null,
        nifConyuge:null
    }

    render(){

        const handleRadioChange = (event) => {
            this.setState({irpfValue: event.currentTarget.value})
            if(event.currentTarget.value!==2)this.setState({nifConyuge:null})
        }

        const handleCambiarNifConyuge = (event) => {
            this.setState({nifConyuge: event.currentTarget.value})
        }

        return (
            <>
                <Card interactive={true} elevation={Elevation.TWO}>
                        <RadioGroup
                            label={this.props.i18next.t("trabajadores.situacion_familiar",{ns:"page"})+":"}
                            name="irpf-radio"
                            onChange={handleRadioChange}
                            selectedValue={this.state.irpfValue}
                        >
                            <Radio label={this.props.i18next.t("trabajadores.situacion1",{ns:"page"})} value="1" />
                            <Radio label={this.props.i18next.t("trabajadores.situacion2",{ns:"page"})} value="2" />
                                {this.state.irpfValue === "2"? 
                                <FormGroup
                                    label={this.props.i18next.t("trabajadores.nif_conyuge",{ns:"page"})+":"}
                                    labelFor="nif-conyuge"
                                >
                                    <InputGroup maxLength={10} value={this.state.nifConyuge} onChange={handleCambiarNifConyuge} />
                                </FormGroup>
                                : null}
                            <Radio label={this.props.i18next.t("trabajadores.situacion3",{ns:"page"})} value="3" />
                        </RadioGroup>
                </Card>
            </>
        )
    }
}