import React from "react"
import { Classes,InputGroup,Dialog,Tooltip,AnchorButton,Intent,Card, Elevation, Navbar, FormGroup, Alignment, Button, ButtonGroup, Collapse } from "@blueprintjs/core"
import Select from "react-select"
import gql from "graphql-tag";
import ApolloClient from "apollo-boost";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import const_table from '../BootstrapTable';
import { Cargando } from "../Cargando";
let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: 'include',
});

export default class EmpleadosInformacionContractual extends React.Component {

    state={
        idiomas:[
        ],
        nuevoActivado: false,
        editarActivado: false,
        borrarActivado: false,
        modalAbierto:false,
        idiomaSeleccionado: null,
        competenciaSeleccionado: null,
        nivelSeleccionado: null,
        idiomaSeleccionadoTabla:null,
        controlesDesactivados:false,
        modalBorrarAbierto:false,
        nuevoIdioma:null,
        idiomasSelect: [
        ],
        competenciaSelect: [
            { value: 1, label: "hablado" },
            { value: 2, label: "escrito" },
        ],
        nivelSelect: [
            { value: 1, label: "bajo" },
            { value: 2, label: "medio" },
            { value: 3, label: "alto" },
            { value: 4, label: "nativo" },
        ],
        cargando:false
    }

    recargarIdiomas=async ()=>{

        client = new ApolloClient({
            uri: process.env.REACT_APP_DOMINIO+"/api",
            credentials: 'include',
        });
        
        //carga del desplegable de idiomas.
        await client
        .query({
            query: gql`
            {idiomas{ID_IDIOMA,DESCRIPCION}}
            `
        }).then(result=>{ 
            let idiomas=[]
            result.data.idiomas.forEach(idioma=>{
                idiomas.push({"value":idioma.ID_IDIOMA,"label":idioma.DESCRIPCION})
            })
            this.setState({idiomasSelect:idiomas})
        })

    }

    componentDidMount(props){
        this.recargarIdiomas()
        console.log(this.state)
    }


    render(){

        const abrirModalNuevo = () => {


            this.setState({
                modalAbierto:true,
                nuevoActivado:true,
                editarActivado:false,
                borrarActivado:false,
                controlesDesactivados:true,
                idiomaSeleccionado:null,
                competenciaSeleccionado:null,
                nivelSeleccionado:null,
                cargando:true
            })
         
        }

        const abrirModalEditar = () => {
            if(!this.state.idiomaSeleccionadoTabla){
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            let idiomaSel=this.state.idiomasSelect.find(idioma=>{
                return idioma.value===this.state.idiomaSeleccionadoTabla.idIdioma
            })

            let competenciaSel=this.state.competenciaSelect.find(comepetencia=>{
                return comepetencia.value===this.state.idiomaSeleccionadoTabla.idCompetencia
            })
            
            let nivelSel=this.state.nivelSelect.find(nivel=>{
                return nivel.value===this.state.idiomaSeleccionadoTabla.idNivel
            })

            this.setState({
                modalAbierto:true,
                nuevoActivado:false,
                editarActivado:true,
                controlesDesactivados:true,
                borrarActivado:false,
                idiomaSeleccionado:idiomaSel,
                competenciaSeleccionado:competenciaSel,
                nivelSeleccionado:nivelSel,
                cargando:true
            })
        }

        const handleNuevoIdioma = (e) => {
            this.setState({nuevoIdioma:e.currentTarget.value})
        }

        const abrirModalBorrar = () => {

            if(!this.state.idiomaSeleccionadoTabla){
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            this.setState({modalBorrarAbierto:true})
            this.setState({nuevoActivado:false})
            this.setState({editarActivado:false})
            this.setState({borrarActivado:true})
        }

        const eliminarIdioma=()=>{

            let idiomas=this.state.idiomas.filter(idioma=>{
                return idioma.idIdioma!==this.state.idiomaSeleccionadoTabla.idIdioma
            })
            this.setState({idiomaSeleccionadoTabla:null})
            this.setState({idiomas:idiomas})
            cerrarModal()

        }

        const cerrarModal=()=>{
            this.setState({modalBorrarAbierto:false})
            this.setState({modalIdiomaAbierto:false})
        }

        const abrirModalIdioma=()=>{
            this.setState({nuevoIdioma:null})
            this.setState({modalIdiomaAbierto:true})
        }
 

        const seleccionIdioma  = (value) => { 
            this.setState({idiomaSeleccionado: value})
        }

        const seleccionCompetencia  = (value) => { 
            this.setState({competenciaSeleccionado: value})
        }

        const seleccionNivel  = (value) => { 
            this.setState({nivelSeleccionado: value})
        }

        const guardarIdiomas = () => {

            if(!this.state.idiomaSeleccionado)return
            if(!this.state.competenciaSeleccionado)return
            if(!this.state.nivelSeleccionado)return
            let idiomas=this.state.idiomas
            let existe=idiomas.find(idioma=>{
                return idioma.idIdioma===this.state.idiomaSeleccionado.value
            })

            if(this.state.nuevoActivado){

                //comprobar que el idioma no esta ya insertado.
                if(existe){
                    alert(this.props.i18next.t("yaexiste",{ns:"global"}))
                    return
                }
           
                idiomas.push({"idIdioma":this.state.idiomaSeleccionado.value,"nombre":this.state.idiomaSeleccionado.label,"idNivel":this.state.nivelSeleccionado.value,"nivel":this.state.nivelSeleccionado.label,"idCompetencia":this.state.competenciaSeleccionado.value,"competencia":this.state.competenciaSeleccionado.label})
            }else{

                //comprobar que el idioma no esta ya insertado.

                if(existe && existe.idIdioma!==this.state.idiomaSeleccionadoTabla.idIdioma){
                    alert(this.props.i18next.t("yaexiste",{ns:"global"}))
                    return
                }

                idiomas.find((idioma,index)=>{
                    if(idioma.idIdioma===this.state.idiomaSeleccionadoTabla.idIdioma){
                        idiomas[index].idIdioma=this.state.idiomaSeleccionado.value
                        idiomas[index].nombre=this.state.idiomaSeleccionado.label
                        idiomas[index].idCompetencia=this.state.competenciaSeleccionado.value
                        idiomas[index].competencia=this.state.competenciaSeleccionado.label
                        idiomas[index].idNivel=this.state.nivelSeleccionado.value
                        idiomas[index].nivel=this.state.nivelSeleccionado.label
                    }
                    return idioma.idIdioma===this.state.idiomaSeleccionadoTabla.idIdioma
                })

            }
            this.setState({
                idiomas:idiomas,
                controlesDesactivados:false,
                modalAbierto:false,
                cargando:false
            });
        }

        const cancelarIdiomas=()=>{

            this.setState({
               idiomaSeleccionado:null,
               competenciaSeleccionado:null,
               nivelSeleccionado:null,
               controlesDesactivados:false,
               modalAbierto:false,
               cargando:false
            })

        }

        const handleOnSelect = (row,isSelect) => {
            if (isSelect) {
                this.setState({
                    idiomaSeleccionadoTabla: row
                });
            }
        }

        const anadirNuevoIdioma=()=>{
            if(!this.state.nuevoIdioma){
                return
            }
  
            //comprobacion de que no exista el idoma
            let existe=this.state.idiomasSelect.find(idioma=>{
                return idioma.label===this.state.nuevoIdioma
            })
            if(existe){
                alert(this.props.i18next.t("yaexiste",{ns:"global"}))
            }

            //insertamos el idioma en la bdd
            client.mutate({
                mutation: gql`
                    mutation{crearIdioma(VALORES:{DESCRIPCION:"${this.state.nuevoIdioma}"}){ID_IDIOMA}}
                `
            }).then(async result=>{
    
                await this.recargarIdiomas()
    
                //seleccinamos el idioma creado
                let idiomaSel=this.state.idiomasSelect.find(idioma=>{return idioma.label===this.state.nuevoIdioma})
                this.setState({idiomaSeleccionado:idiomaSel})
                cerrarModal()
            }).catch(err=>{
                alert(this.props.i18next.t("error",{ns:"global"}))
            })

        }

        const columns = [
            {
                dataField: 'nombre',
                text: this.props.i18next.t("name",{ns:"global"})
            },
            {
                dataField: 'competencia',
                text: this.props.i18next.t("trabajadores.competencia",{ns:"page"})
            },
            {
                dataField: 'nivel',
                text: this.props.i18next.t("trabajadores.nivel",{ns:"page"})
            }
        ]

        return (
            <>
             <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={this.props.i18next.t("trabajadores.idiomas",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalIdiomaAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                        label={this.props.i18next.t("trabajadores.enter_idioma",{ns:"page"})+":"}
                        labelFor="informacion"
                        intent="danger"
                        helperText={this.state.nuevoIdioma?"":this.props.i18next.t("emptyfield",{ns:"global"})+this.props.i18next.t("idioma",{ns:"global"})}
                        >
                            <InputGroup id="texto-nombre-convenio" onChange={handleNuevoIdioma}  placeholder={this.props.i18next.t("placeholder",{ns:"global"})} intent={this.state.nuevoIdioma?"primary":"danger"} />
                        </FormGroup>

                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={this.props.i18next.t("agregar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={anadirNuevoIdioma}
                            >
                                {this.props.i18next.t("aceptar",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{this.props.i18next.t("cancelar",{ns:"global"})}</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
           
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title={this.props.i18next.t("delete",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBorrarAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>{this.props.i18next.t("trabajadores.questiondel_idioma",{ns:"page"})}</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={this.props.i18next.t("delete",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={eliminarIdioma}
                            >
                                {this.props.i18next.t("yes",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{this.props.i18next.t("no",{ns:"global"})}</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
                <div className="pixelAlrededor">
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <Navbar>
                            <Navbar.Group>
                                <ButtonGroup align={Alignment.LEFT}>
                                    <Button className="bp3-minimal" icon="add" text={this.props.i18next.t("new",{ns:"global"})} active={this.state.nuevoActivado} disabled={this.state.controlesDesactivados} onClick={abrirModalNuevo} />
                                    <Button className="bp3-minimal" icon="edit" text={this.props.i18next.t("edit",{ns:"global"})} active={this.state.editarActivado} disabled={this.state.controlesDesactivados} onClick={abrirModalEditar} />
                                    <Button className="bp3-minimal" icon="delete" text={this.props.i18next.t("delete",{ns:"global"})} active={this.state.borrarActivado} disabled={this.state.controlesDesactivados} onClick={abrirModalBorrar}/>
                                </ButtonGroup>
                            </Navbar.Group>
                        </Navbar>
                        {this.state.cargando?
                                <Cargando />
                            :
                                <BootstrapTable
                                    keyField='id'
                                    data={ this.state.idiomas }
                                    columns={ columns }
                                    selectRow={ const_table.selectRow(handleOnSelect) }
                                    pagination={ paginationFactory(const_table.options) }
                                    headerClasses="header-class"
                                    rowClasses="row-class"
                                    hover
                                    condensed
                                    noDataIndication={ this.props.i18next.t("datavacio",{ns:"global"}) }
                                />
                            }
                    </Card>
                    
                    <Collapse isOpen={this.state.modalAbierto}>
                        <Card interactive={true} elevation={Elevation.TWO}>
                            <FormGroup
                                label={this.props.i18next.t("trabajadores.idioma",{ns:"page"})+":"}
                                labelFor="idioma"
                                intent="danger"
                                helperText={!this.state.idiomaSeleccionado?this.props.i18next.t("selectempty",{ns:"global"})+this.props.i18next.t("trabajadores.idioma",{ns:"page"}):""}

                            >
                                <div style={this.state.idiomaSeleccionado?{}:{border:"1px solid red"}}>
                                    <Select
                                        options={this.state.idiomasSelect}
                                        className="pixelAlrededor"
                                        onChange={seleccionIdioma}
                                        backspaceRemovesValue={true}
                                        isClearable={true}
                                        value={this.state.idiomasSelect.filter(idioma => idioma.value === (this.state.idiomaSeleccionado?this.state.idiomaSeleccionado.value:0))}
                                    />
                                </div>
                                <Button 
                                    alignText="right"
                                    icon="add"
                                    className="iconoIzquierda"
                                    onClick={abrirModalIdioma}
                                />
                            </FormGroup>
                            <FormGroup
                                label={this.props.i18next.t("trabajadores.competencia",{ns:"page"})+":"}
                                labelFor="competencia"
                                intent="danger"
                                helperText={!this.state.competenciaSeleccionado?this.props.i18next.t("selectempty",{ns:"global"})+this.props.i18next.t("trabajadores.competencia",{ns:"page"}):""}

                            >
                                <div style={this.state.competenciaSeleccionado?{}:{border:"1px solid red"}}>
                                    <Select
                                        options={this.state.competenciaSelect}
                                        className="pixelAlrededor"
                                        onChange={seleccionCompetencia}
                                        backspaceRemovesValue={true}
                                        isClearable={true}
                                        value={this.state.competenciaSelect.filter(competencia => competencia.value === (this.state.competenciaSeleccionado?this.state.competenciaSeleccionado.value:0))}
                                    />
                                </div>
                            </FormGroup>
                            <FormGroup
                                label={this.props.i18next.t("trabajadores.nivel",{ns:"page"})+":"}
                                labelFor="nivel"
                                intent="danger"
                                helperText={!this.state.nivelSeleccionado?this.props.i18next.t("selectempty",{ns:"global"})+this.props.i18next.t("trabajadores.nivel",{ns:"page"}):""}
                            >
                                <div style={this.state.nivelSeleccionado?{}:{border:"1px solid red"}}>
                                    <Select
                                        options={this.state.nivelSelect}
                                        className="pixelAlrededor"
                                        onChange={seleccionNivel}
                                        backspaceRemovesValue={true}
                                        isClearable={true}
                                        value={this.state.nivelSelect.filter(nivel => nivel.value === (this.state.nivelSeleccionado?this.state.nivelSeleccionado.value:0))}
                                    />
                                </div>
                            </FormGroup>
                            <Button 
                                alignText="right"
                                icon="floppy-disk"
                                text={this.props.i18next.t("guardar",{ns:"global"})}
                                onClick={guardarIdiomas}
                                className="bp3-intent-primary iconoDerecha"
                            />
                            <Button 
                                alignText="right"
                                icon="cross"
                                text={this.props.i18next.t("cancelar",{ns:"global"})}
                                onClick={cancelarIdiomas}
                                className="bp3-intent-primary iconoDerecha"
                            />
                            <br/>
                            <br/>
                        </Card>
                    </Collapse>
                </div>
            </>
        )
    }
}