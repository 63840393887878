import React from "react"
import { Card, Elevation, Radio, RadioGroup, Checkbox, FormGroup, TextArea } from "@blueprintjs/core"

export default class EmpleadosIRPF extends React.Component {

    state={
        minusValue: null,
        necesitaAyuda: false,
        otrosDatos:null,
        interes:null
    }

    render(){

        const handleRadioChange = (event) => {
            this.setState({minusValue: event.currentTarget.value})
            if(event.currentTarget.value==="1")this.setState({necesitaAyuda:false})
        }

        const handleNecesitaAyuda = () => {
            this.setState({necesitaAyuda: !this.state.necesitaAyuda})
        }

        const handleCambiarOtrosDatos=(ev)=>{
            this.setState({otrosDatos: ev.currentTarget.value})
        }

        const handleCambiarInteres=(ev)=>{
            this.setState({interes: ev.currentTarget.value})
        }

        return (
            <>
            <Card interactive={true} elevation={Elevation.TWO} className="pixelAlrededor">
                <RadioGroup
                    label={this.props.i18next.t("trabajadores.grado_minusvalia",{ns:"page"})+":"}
                    name="minus-radio"
                    onChange={handleRadioChange}
                    selectedValue={this.state.minusValue}
                >
                    <Radio label={this.props.i18next.t("trabajadores.minusvalia1",{ns:"page"})} value="1" />
                    <Radio label={this.props.i18next.t("trabajadores.minusvalia2",{ns:"page"})} value="2" />
                    <Radio label={this.props.i18next.t("trabajadores.minusvalia3",{ns:"page"})} value="3" />
                        {(this.state.minusValue === "2" || this.state.minusValue === "3")? 
                                <Checkbox checked={this.state.necesitaAyuda?this.state.necesitaAyuda:false} onChange={handleNecesitaAyuda}>{this.props.i18next.t("trabajadores.minusvalia_add",{ns:"page"})}</Checkbox>
                            : <Checkbox checked={this.state.necesitaAyuda?this.state.necesitaAyuda:false} disabled>{this.props.i18next.t("trabajadores.minusvalia_add",{ns:"page"})}</Checkbox>
                        }
                </RadioGroup>
                <div className="pixelAlrededor tablaMitadIzquierda">
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <FormGroup
                            label={this.props.i18next.t("trabajadores.reconocimiento_medico",{ns:"page"})+":"}
                            labelFor="observaciones-reconocimiento"
                        >
                            <TextArea value={this.state.otrosDatos?this.state.otrosDatos:''} onChange={handleCambiarOtrosDatos} fill={true}/>
                        </FormGroup>
                    </Card>
                    </div>
                <div className="pixelAlrededor tablaMitadDerecha">
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <FormGroup
                            label={this.props.i18next.t("trabajadores.datos_medicos",{ns:"page"})+":"}
                            labelFor="otros-datos"
                        >
                            <TextArea value={this.state.interes?this.state.interes:''} onChange={handleCambiarInteres} fill={true}/>
                        </FormGroup>
                    </Card>
                </div>
            </Card>
            </>
        )
    }
}