import React, {Component} from 'react';
import { FormGroup,
    InputGroup,
    Spinner
} from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import moment from 'moment';
import Select from "react-select";

const today = new Date();

class Contrato extends Component {
    state={
        denominacion: '',
        tipo_contrato:null,
        fechaFirma:new Date(),
        vigencia:'',
        extincion:null,
        fechaExtincion:new Date(),
        extracto:'',
        tipos_extincion:[{
            label: 'NORMAL',
            value: 'NORMAL'
        },{
            label: 'FORZOSA',
            value: 'FORZOSA'
        }],
        cargando:false
    }

    componentDidMount=async()=>{
        this.setState({cargando:true}, async () => {
            if(this.props.contrato && Object.keys(this.props.contrato).length >0){
                let tiposContrato=this.props.tiposContrato.find(tipo=>{return tipo.value===this.props.contrato.ID_RIESGO})
                let extincionSelect=this.state.tipos_extincion.find(ext=>{return ext.value===this.props.contrato.EXTINCION})
                this.setState({
                    denominacion:this.props.contrato.DENOMINACION,
                    fechaFirma:new Date(this.props.contrato.FECHA_FIRMA),
                    vigencia:this.props.contrato.VIGENCIA,
                    extincion:extincionSelect,
                    fechaExtincion:new Date(this.props.contrato.FECHA_EXTINCION),
                    extracto:this.props.contrato.EXTRACTO,
                    tipo_contrato:tiposContrato,
                })
            }
            this.setState({cargando:false})
        });
        
    }

    render() {
        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };
        const escogeFechaFirma = (nuevaFecha) => {
            this.setState({ fechaFirma: nuevaFecha })
        }
        const escogeFechaExtincion = (nuevaFecha) => {
            this.setState({ fechaExtincion: nuevaFecha })
        }
        const seleccionarExtincion  = (value) => { 
            this.setState({extincion: value})
        }
        const seleccionarTipoContrato  = (value) => { 
            this.setState({tipo_contrato: value})
        }
        const handleDenominacion = (e) => {
            this.setState({ denominacion: e.target.value })
        }
        const handleVigencia = (e) => {
            this.setState({ vigencia: e.target.value })
        }
        const handleExtracto = (e) => {
            this.setState({ extracto: e.target.value })
        }
        
        return (
            <>
            {this.state.cargando?
                <Spinner/>
            :
            <>
                <div id="dialogo-contrato" className="bp3-card separarBordes">
                    <FormGroup
                        label={this.props.i18next.t("obligacionescontractuales.denominacion",{ns:"page"})+":"}
                        labelFor="denominacion"
                    >
                        <InputGroup id="denominacion" value={this.state.denominacion} onChange={handleDenominacion} intent={this.state.denominacion?"primary":"danger"} />   
                    </FormGroup>
                    <FormGroup
                        label={this.props.i18next.t("obligacionescontractuales.tipo",{ns:"page"})+":"}
                        labelFor="tipo-contrato"
                    >
                        <div id="tipo-contrato" style={!this.state.tipo_contrato?{border:"1px solid red"}:{}}>
                            <Select
                                options={this.props.tiposContrato}
                                className="pixelAlrededor"
                                onChange={seleccionarTipoContrato}
                                value={this.state.tipo_contrato}
                            />
                        </div>
                    </FormGroup>
                    <FormGroup
                        label={this.props.i18next.t("obligacionescontractuales.fechafirma",{ns:"page"})+":"}
                        labelFor="fecha-firma"
                    >
                        <div id="fecha-firma">
                            <DateInput minDate={new Date(1900, 1 ,1)}
                                maxDate={new Date(today.getFullYear()+100, 11 ,31)}
                                {...jsDateFormatter}
                                onChange={escogeFechaFirma}
                                value={this.state.fechaFirma}
                            />
                        </div>
                    </FormGroup>
                    <FormGroup
                        label={this.props.i18next.t("obligacionescontractuales.vigencia",{ns:"page"})+":"}
                        labelFor="vigencia"
                    >
                        <InputGroup id="vigencia" value={this.state.vigencia} onChange={handleVigencia} intent={this.state.vigencia?"primary":"danger"} />   
                    </FormGroup>
                    <FormGroup
                        label={this.props.i18next.t("obligacionescontractuales.extincion",{ns:"page"})+":"}
                        labelFor="extincion"
                    >
                        <div id="extincion" style={!this.state.extincion?{border:"1px solid red"}:{}}>
                            <Select
                                options={this.state.tipos_extincion}
                                className="pixelAlrededor"
                                onChange={seleccionarExtincion}
                                value={this.state.extincion}
                            />
                        </div>
                    </FormGroup>
                    <FormGroup
                        label={this.props.i18next.t("obligacionescontractuales.fechaextincion",{ns:"page"})+":"}
                        labelFor="fecha-extincion"
                    >
                        <div id="fecha-extincion">
                            <DateInput minDate={new Date(1900, 1 ,1)}
                                maxDate={new Date(today.getFullYear()+100, 11 ,31)}
                                {...jsDateFormatter}
                                onChange={escogeFechaExtincion}
                                value={this.state.fechaExtincion}
                            />
                        </div>
                    </FormGroup>
                    <FormGroup
                        label={this.props.i18next.t("obligacionescontractuales.extracto",{ns:"page"})+":"}
                        labelFor="extracto"
                    >
                        <InputGroup id="extracto" value={this.state.extracto} onChange={handleExtracto} intent={this.state.extracto?"primary":"danger"} />   
                    </FormGroup>
                
                </div>
            </>
            }
            </>
        );
    }
}

export default Contrato;