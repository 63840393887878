import * as React from "react";
import {
  Classes,
  Tree,
  Card,
  Elevation,
  Alignment,
  Tooltip,
  Button,
  Callout,
  Position

} from "@blueprintjs/core";
import {  withRouter} from 'react-router-dom';
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import Seguimiento from "../Pantallas/Seguimiento"
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { Cargando } from "./Cargando";
import i18next from "i18next";
/* tslint:disable:object-literal-sort-keys so childNodes can come last */
const INITIAL_STATE = [];

/* tslint:enable:object-literal-sort-keys */

class ControlCumplimiento extends React.Component {

  state={
    idRiesgo:null,
    idDepartamento:null,
  }

  abrirModal = async (riesgo) => {
    this.setState({idRiesgo:riesgo.idRiesgo})
    this.setState({idArea:riesgo.idArea})
    this.setState({idDepartamento:riesgo.idDepartamento})
    this.setState({modalIdentificacionAbierto:true})
  }

  setModalAbierto=(abierto)=>{
    this.setState({modalIdentificacionAbierto:abierto})
  }

  setRecargar=(value)=>{
    if(value)this.obtenerAreas()
  }


  state = {
    cargandoCC:false,
    riesgos: INITIAL_STATE,
    puestosDepartamento: [],
    modalIdentificacionAbierto: false,
    riesgoSeleccionadoFalso: "Riesgo seleccionado",
    fechaFicha: new Date().toLocaleString(),
    departamento: "Departamento Falso",
    numero: "1",
    codigoRiesgo: "01A01",
    nombreRiesgo: "EXCESIVO NIVEL DE ENDEUDAMIENTO",
    riesgoHaSucedidoAntes: false,
    nivelRiesgo: null,
    nivelSancion: 1,
    nivelPerdida: 2,
    nivelSuspension: 3,
    nivelReputacional: 4,
    nivelOtros: 5,
    categoriaRiesgo: 0,
    categoriaSancion: 0,
    categoriasRiesgo: ["BAJO", "MEDIO", "ALTO", "MUY ALTO"],
    intentCategoriasRiesgo: [
      "celdaFondoVerde",
      "celdaFondoAmarillo",
      "celdaFondoNaranja",
      "celdaFondoRojo"
    ],
    intentCategoriasRiesgo2: [
      "celdaFondoVerde",
      "celdaFondoAmarillo",
      "celdaFondoAmarillo",
      "celdaFondoNaranja",
      "celdaFondoRojo"
    ],
    valoran: [],
    asignados: [],
    recursosFinancieros: 0.0,
    recursosOrganizacion: "",
    recursosExternos: "",
    periodicidadAbierto: false,
    radio1: "1",
    bloque: null,
    observaciones: null,
    departamentoSeleccionado: null,
    idUsuario: null,
    valoranSeleccionada: null,
    asignadoSeleccionada: null,
    existeDocumento: false,
    documentoSeleccionado: null,
    modalAdvertencia1Abierto: false,
    fechaDiferente: false,
    pasoGuia:0,
    steps: [
        {
          target: '#vista-general-cuadro-seguimiento',
          content: "En esta pantalla se muestra un resumen de los documentos de seguimiento que se han creado en los diferentes riesgos.",
          selector: '#vista-general-cuadro-seguimiento',
          position: 'auto',
          disableBeacon: true,
          type: 'hover',
          isFixed: true,
          stepIndex:0, 
        },
        {
            target: '#arbol-areas-cuadro-seguimiento',
            content: "Aqui se muestran las diferentes areas sobre las cuales se ha creado algún documento de indentificación.",
            selector: '#arbol-areas-cuadro-seguimiento',
            position: 'auto',
            disableBeacon: true,
            type: 'hover',
            isFixed: true,
            stepIndex:1, 
        },
        {
          target: '.tablaSesenta',
          content: "En la parte derecha, se mostrarán los diferentes riesgos, ordenados por área, departamento y bloque, que tienen algún documento de identificación, junto a sus niveles de cumplimiento (imperativas y recomendaciones) y su color correspondiente.",
          selector: '.tablaSesenta',
          position: 'auto',
          disableBeacon: true,
          type: 'hover',
          isFixed: true,
          stepIndex:2, 
        },
        {
          target: '.bloque-seguimiento',
          content: "Si hacemos clic en el riesgo, se abrirá el mismo modal que habíamos visto en 'Control y seguimiento' con los datos de la última versión del documento de seguimiento del riesgo.",
          selector: '.bloque-seguimiento',
          position: 'auto',
          disableBeacon: true,
          type: 'hover',
          isFixed: true,
          stepIndex:3, 
        },
      
    ]
  };

  componentDidMount = () => {
    this.setState({cargandoCC:true}, async()=>{
      await this.obtenerAreas()
       //obtener el id del usuario actual logueado en la aplicacion.
      await  this.props.client
    .query({
      query: gql`
        {
          obtenerUsuarioActual
        }
      `,
        fetchPolicy: "network-only"
      })
      .then(result => {
        this.setState({ idUsuario: result.data.obtenerUsuarioActual });
      });

    this.setState({
              areasAMostrar: this.state.riesgos.length
      });
      this.setState({cargandoCC:false})
      })
  }

  groupBy = function(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  obtenerAreas = async () => {
    let areas = [];
    //obtenemos las areas que tienen hechas algun documento.
    let documentos = await this.props.client
      .query({
        query: gql`
        {complianceDocumentosSeguimientos(ORDEN: "ID_DOCUMENTO,NUMERO,DESC"){
            ID_DOCUMENTO,
            ID_CONTRATO_DEPARTAMENTO,
            CONTRATO_DEPARTAMENTO{DEPARTAMENTO{ID_DEPARTAMENTO,DESCRIPCION}}
            ID_RIESGO,
            RIESGO{ID_RIESGO,TITULO,CODIGO_RIESGO}
            FECHA,
            NUMERO,
            NOMBRE_APELLIDOS,
            DOCUMENTO_IDENTIDAD,
            EMPRESA,
            PUESTO,
            EXPLICACION_IMPERATIVOS,
            EXPLICACION_RECOMENDADOS,
            EXPLICACION_SALVEDADES,
            FECHA_PROPUESTA_PROXIMA,
            OBSERVACIONES,
            FECHA_PROXIMA,
            ID_USUARIO,
            COMPLIANCE_CERTIFICA,
            CONTRATO_DEPARTAMENTO {
                DEPARTAMENTO {
                  ID_DEPARTAMENTO
                  DESCRIPCION
                  AREA {
                    ID_AREA
                    DESCRIPCION
                  }
                }
              },
              RIESGO {
                ID_RIESGO
                TITULO
                CODIGO_RIESGO
                BLOQUE {
                  ID_BLOQUE,
                  LETRA,
                  TITULO
                }
              }
        }}`,
        fetchPolicy: "network-only"
      })
      .then(async result => {
        let seguimientos= JSON.parse(
          JSON.stringify(result.data.complianceDocumentosSeguimientos)
        );
        // let imperativas = await this.props.client
        // .query({
        //   query: gql` 
        //           {complianceSeguimientoImperativas{
        //             ID_DOCUMENTO_IMPERATIVA,
        //             ID_DOCUMENTO,
        //             ID_IMPERATIVA,
        //             IMPERATIVA{DESCRIPCION},
        //             VALOR
        //           }}
        //       `,
        //       fetchPolicy:'network-only'
        // })
        // .then(result => {
        //   return result.data.complianceSeguimientoImperativas
        // });
        // let recomendaciones = await this.props.client
        // .query({
        //   query: gql` 
        //           {SeguimientoRecomendaciones{
        //             ID_DOCUMENTO_RECOMENDACION,
        //             ID_DOCUMENTO,
        //             ID_RECOMENDACION,
        //             RECOMENDACION{DESCRIPCION},
        //             VALOR
        //           }}
        //       `,
        //       fetchPolicy:'network-only'
        // })
        // .then(result => {
        //   return JSON.parse(JSON.stringify(result.data.SeguimientoRecomendaciones));
        // });

        //obtenemos las imperativas y redcomendadas.
        for(let seguimiento of seguimientos){

        
          let imperativas = await this.props.client
          .query({
            query: gql` 
                    {complianceSeguimientoImperativas(FILTROS:{ID_DOCUMENTO:${seguimiento.ID_DOCUMENTO}}){
                      ID_DOCUMENTO_IMPERATIVA,
                      ID_DOCUMENTO,
                      ID_IMPERATIVA,
                      IMPERATIVA{DESCRIPCION},
                      VALOR
                    }}
                `,
                fetchPolicy:'network-only'
          })
          .then(result => {
            console.log(result)
            return JSON.parse(JSON.stringify(result.data.complianceSeguimientoImperativas));
          });
        
         
          for(let imperativa of imperativas){
                imperativa.value=imperativa.ID_IMPERATIVA
                imperativa.nivel=imperativa.VALOR
                imperativa.label=imperativa.IMPERATIVA.DESCRIPCION
            
          }
    
          //obtencion de las recomendaciones.
          let recomendaciones = await this.props.client
          .query({
            query: gql` 
                    {SeguimientoRecomendaciones(FILTROS:{ID_DOCUMENTO:${seguimiento.ID_DOCUMENTO}}){
                      ID_DOCUMENTO_RECOMENDACION,
                      ID_DOCUMENTO,
                      ID_RECOMENDACION,
                      RECOMENDACION{DESCRIPCION},
                      VALOR
                    }}
                `,
                fetchPolicy:'network-only'
          })
          .then(result => {
            return JSON.parse(JSON.stringify(result.data.SeguimientoRecomendaciones));
          });
          
          for(let recomendacion of recomendaciones){
            recomendacion.value=recomendacion.ID_RECOMENDACION
            recomendacion.nivel=recomendacion.VALOR
            recomendacion.label=recomendacion.RECOMENDACION.DESCRIPCION
          }
        

          seguimiento.recomendaciones=recomendaciones
          seguimiento.imperativas=imperativas
        }
        console.log(seguimientos)
        return seguimientos
    });

    for (let documento of documentos) {
      areas.push({
        idArea:
          documento.CONTRATO_DEPARTAMENTO.DEPARTAMENTO.AREA
            .ID_AREA,
        area:
          documento.CONTRATO_DEPARTAMENTO.DEPARTAMENTO.AREA
            .DESCRIPCION,
        departamento:
          documento.CONTRATO_DEPARTAMENTO.DEPARTAMENTO.DESCRIPCION,
        idDepartamento:
          documento.CONTRATO_DEPARTAMENTO.DEPARTAMENTO
            .ID_DEPARTAMENTO,
        idRiesgo: documento.RIESGO.ID_RIESGO,
        tituloRiesgo: "BLOQUE "+documento.RIESGO.BLOQUE.LETRA+"-"+documento.RIESGO.BLOQUE.TITULO,
        idBloque:documento.RIESGO.BLOQUE.ID_BLOQUE,
        label: documento.RIESGO.TITULO,
        numero: documento.NUMERO,
        idDocumento: documento.ID_DOCUMENTO,
        idDepCon: documento.ID_CONTRATO_DEPARTAMENTO,
        fechaDoc: documento.FECHA,
        imperativas:documento.imperativas,
        recomendaciones:documento.recomendaciones
      });
    }

    areas=areas.sort(function(a, b) { 
      return a.idArea- b.idArea || a.idDepartamento-b.idDepartamento;
    })

    let datosArbol = [];
    let datosRiesgos = [];

    //eliminamos repetidos
    //areas=this.removeDuplicates(areas,"departamento")
    //agrupados
    areas = await this.groupBy(areas, "area");

    for (let key of Object.keys(areas)) {
      let hijos = [];
      let hijosArbol = [];
      let riesgos = [];

      for (let area of areas[key]) {
        riesgos = [];

        //obtencion de los riesgos
        let r = areas[key].filter(ar => {
          return ar.idDepartamento === area.idDepartamento;
        });

        r = this.removeDuplicates(r, "idRiesgo");

        for (let ries of r) {
          //Obtencion de los riesgos
          riesgos.push({
            id: area.idArea + "-" + ries.idDepartamento + "-" + ries.idRiesgo,
            icon: "small-tick",
            idDepartamento: ries.idDepartamento,
            idArea:area.idArea,
            label: ries.label,
            idRiesgo: ries.idRiesgo,
            idDocumento: ries.idDocumento,
            nombre: ries.label,
            tituloRiesgo: ries.tituloRiesgo,
            idBloque:ries.idBloque,
            idDepCon: ries.idDepCon,
            numero: ries.numero,
            fechaDoc: ries.fechaDoc,
            imperativas:ries.imperativas,
            recomendaciones:ries.recomendaciones
          });
        }

        riesgos=riesgos.sort(function(a, b) { 
          return a.idBloque- b.idBloque;
        })
        riesgos=this.groupBy(riesgos,"tituloRiesgo")


        //para no repetir el departamento.
        let existe = hijos.find(hijo => {
          return hijo.id === area.idArea + "-" + area.idDepartamento;
        });
        //Si no existe
        if (!existe) {
          //DATOS TABLA COLORES
          hijos.push({
            id: area.idArea + "-" + area.idDepartamento,
            icon: "small-tick",
            label: area.departamento,
            childNodes: riesgos
          });

          //DATOS ARBOL
          hijosArbol.push({
            id: area.idArea + "-" + area.idDepartamento,
            icon: "small-tick",
            label: area.departamento
          });
        }
      }

      datosArbol.push({
        id: key,
        icon: "small-tick",
        label: key,
        childNodes: hijosArbol
      });
      datosRiesgos.push({
        id: key,
        icon: "small-tick",
        label: key,
        childNodes: hijos
      });
    }

    this.setState({ arbol: datosArbol });
    this.setState({ riesgos: datosRiesgos });
  };

  irAIdentificacion = () => {
    this.props.history.push('/seguimiento')
  }

  
  render() {

    const {steps} = this.state;

    document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("seguimientocontrol.title",{ns:"page"})
    
    const desplegarArbol = hijos => {
      hijos.forEach(hijo => {
        hijo.isExpanded = true;
        if (hijo.childNodes) desplegarArbol(hijo.childNodes);
      });
      this.setState({ arbol: hijos });
    };

    const replegarArbol = hijos => {
      hijos.forEach(hijo => {
        hijo.isExpanded = false;
        if (hijo.childNodes) replegarArbol(hijo.childNodes);
      });
      this.setState({ arbol: hijos });
    };


    const handleJoyrideCallback=async(data)=>{
        

      const { action, index, status, type } = data;

      if(data.action==="close"){

        localStorage.setItem("ayuda",false)
        this.setState({pasoGuia:0})

      }else{
        if ([EVENTS.STEP_AFTER].includes(type)) {

          let paso=index+ (action === ACTIONS.PREV ? -1 : 1)
  
          //paso en el que nos encontremos.
          // eslint-disable-next-line default-case
          switch(paso){
            case 1:
                await desplegarArbol(this.state.arbol)   
            break;
          
          
          }
  
          await this.setState({ pasoGuia: paso });         
  
        }
  
        //si termina la guia.
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            localStorage.setItem("ayuda",false)
            replegarArbol(this.state.arbol)
        }
      }
    

    } 

    return (
      <>
      {this.state.cargandoCC===true?
       <Cargando/>
       :
       <>
      <div id="vista-general-cuadro-seguimiento">
        {this.state.arbol? this.state.arbol.length !== 0? (
      /* cuadro de mando si hay algo en arbol */
      <>
        <Joyride
            run={localStorage.getItem("ayuda")==="true"?true:false}
            callback={handleJoyrideCallback}
            showProgress={true}
            stepIndex={this.state.pasoGuia}
            steps={steps}
            disableScrollParentFix={true}
            continuous ={true}
            locale={{ back: 'Volver', close: 'Cerrar', last: 'Último', next: 'Siguiente', skip: 'Saltar' }}
            styles={{
                options: {
                    arrowColor: '#e3ffeb',
                    backgroundColor: '#e3ffeb',
                    primaryColor: '#E31E24',
                }
            }}
        />
        <Seguimiento editable={this.props.editable}  riesgo={this.state.idRiesgo} setRecargar={this.setRecargar} area={this.state.idArea} departamento={this.state.idDepartamento} abierto={this.state.modalIdentificacionAbierto} setModalAbierto={this.setModalAbierto} modal={true} i18next={i18next}/>
        <Card
          interactive={true}
          id="arbol-areas-cuadro-seguimiento"
          elevation={Elevation.TWO}
          align={Alignment.LEFT}
          className="pixelAlrededorGrande tablaCuarenta"
        >
          <Tooltip content={i18next.t("tree.replegar",{ns:"global"})} position={Position.TOP}>
            <Button
              icon="exclude-row"
              onClick={() => replegarArbol(this.state.arbol)}
            />
          </Tooltip>
          <Tooltip content={i18next.t("tree.desplegar",{ns:"global"})} position={Position.TOP}>
            <Button
              icon="add-column-right"
              onClick={() => desplegarArbol(this.state.arbol)}
            />
          </Tooltip>
          <Tree
            contents={this.state.arbol}
            onNodeClick={this.handleNodeClick}
            onNodeCollapse={this.handleNodeCollapse}
            onNodeExpand={this.handleNodeExpand}
            className={Classes.ELEVATION_0}
          />
        </Card>
      </>
		) :(
			/* SI NO hay nada en arbol, mandamos al usuario a crear los elementos */
          <Card interactive={true} elevation={Elevation.TWO}>
            <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">
              <p>{i18next.t("seguimientocontrol.empty",{ns:"page"})}</p>
              <Button onClick={this.irAIdentificacion}>{i18next.t("seguimientocontrol.pulse_aqui",{ns:"page"})}</Button>
            </Callout>
          </Card>
		):
		/* Cargando.... */
		<></>}
        <div className="tablaSesenta">
          {this.state.riesgos.map(area => (
            <div className="cardCMI" key={area.id}>
              {area.childNodes.map(departamento => (
                <Card
                  key={departamento.id}
                  className="pixelAlrededorGrande"
                  elevation={Elevation.TWO}
                  interactive={true}
                >
                  <p><b>{i18next.t("seguimientocontrol.area",{ns:"page"})}: </b> {area.label}</p>
                  <p><b>{i18next.t("seguimientocontrol.departamento",{ns:"page"})}: </b>{departamento.label}</p>
                  <div className="tg-wrap">
                    {
                      <table className="tablaToAncha">
                        <tbody>
                        {Object.keys(departamento.childNodes).map(depKey=>{
                            let elmDibujar=[]
                            elmDibujar[0]= (<> <b>{i18next.t("seguimientocontrol.bloque",{ns:"page"})}: </b> {depKey} </> )
                            elmDibujar[1]= (<><br/><br/></>)
                            // eslint-disable-next-line no-lone-blocks
                            {departamento.childNodes[depKey].map(elemento => (
                              elmDibujar.push(<>
                              <div className="bloque-seguimiento">
                                <tr
                                  key={elemento.nivelRiesgo}

                                >
                                  <td onClick={() => this.abrirModal(elemento)}>
                                    {elemento.label}
                                    <h3>{i18next.t("seguimientocontrol.imperativas",{ns:"page"})}</h3>
                                    <ul>
                                      
                                      {elemento.imperativas.map(el=>(<><li className={
                                        el.nivel ===1
                                          ? "celdaFondoVerde"
                                          : el.nivel < 4
                                          ? "celdaFondoAmarillo"
                                          : el.nivel ===4
                                          ? "celdaFondoNaranja"
                                          : el.nivel >4?"celdaFondoRojo"
                                          :<></>
                                      }><b> {i18next.t("seguimientocontrol.nivel",{ns:"page"})}: {el.nivel} </b> - {el.label}</li></>))}
                                    </ul>
                                    <h3>{i18next.t("seguimientocontrol.recomendaciones",{ns:"page"})}</h3>
                                    <ul>
                                      {elemento.recomendaciones.map(el=>(<><li className={
                                        el.nivel ===1
                                          ? "celdaFondoVerde"
                                          : el.nivel < 4
                                          ? "celdaFondoAmarillo"
                                          : el.nivel ===4
                                          ? "celdaFondoNaranja"
                                          : el.nivel >4?"celdaFondoRojo"
                                          :<></>
                                      }><b> {i18next.t("seguimientocontrol.nivel",{ns:"page"})}: {el.nivel} </b> - {el.label}</li></>))}
                                    </ul>
                                  </td>
                                </tr>
                                </div>
                              </>)
                            ))}
                            elmDibujar.push(<><br/><br/></>)
                            return elmDibujar
                          })}
                        </tbody>
                      </table>
                    }
                  </div>
                </Card>
              ))}
            </div>
          ))}
        </div>
        </div>
      </>
    }
    </> 
    );
  }

  handleNodeCollapse = (nodeData) => {
    nodeData.isExpanded = false;
    this.setState(this.state);
  };

  handleNodeExpand = (nodeData) => {
    nodeData.isExpanded = true;
    this.setState(this.state);
  };

  forEachNode(nodes, callback) {
    if (nodes == null) {
      return;
    }

    for (const node of nodes) {
      callback(node);
      this.forEachNode(node.childNodes, callback);
    }
  }
}

export default withRouter(withApollo(ControlCumplimiento))
