/* eslint-disable array-callback-return */
import React from 'react';
import { Spinner, AnchorButton, Navbar, Button, ButtonGroup, Alignment, Dialog, Classes, Intent, Tooltip, Card, Elevation, Callout, InputGroup, FormGroup, Radio, RadioGroup , TextArea, NumericInput } from "@blueprintjs/core"
import Select from "react-select"
import chroma from "chroma-js"
import { DateInput } from "@blueprintjs/datetime";
import moment from 'moment';
import gql from "graphql-tag";
import { withApollo } from 'react-apollo';
import ReactToPrint from "react-to-print";
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import { Cargando } from "../Pantallas/Cargando"
import { PDFViewer, Page,Text,View,Document,StyleSheet,Image, Font} from '@react-pdf/renderer';
import iconoFooter from '../../Assets/lc.png';
import ReactDOM from 'react-dom';
import i18next from "i18next";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import const_table from "./BootstrapTable";
import open_sans_bold from '../../Assets/fonts/Open_Sans/OpenSans-Bold.ttf'
import open_sans from '../../Assets/fonts/Open_Sans/OpenSans-Regular.ttf'

Font.register({ family: 'Open Sans', src: open_sans });
Font.register({ family: 'Open Sans Bold', src: open_sans_bold });

const styles = StyleSheet.create({
    dialog:{
        width: '65%'    
    },
    dialog2:{
        width: '45%'    
    },
    page: {
        paddingBottom:95,
        paddingTop:30,
        paddingLeft: 30,
        paddingRight: 20
      },
      body: {
        flex: 1,
        backgroundColor: '#FFFFFF',
        marginLeft:20,
      },
      imageHead: {
        width: '20%',
        maxHeight: 60,
        marginBottom: 20
      },
      rowHeadGral: {
        flexDirection: 'row',
        width: '100%',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#000000',
        marginTop:10
      },
      rowHead: {
        flexDirection: 'row',
        width: '100%',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#000000',
        marginTop:-1
      },
      rowHeadTwo: {
        flexDirection: 'row',
        width: '100%',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#000000',
        marginBottom: 20,
        marginTop:-1
      },
      row: {
        flexDirection: 'row',
        width: '100%',
        marginTop:3
      },
      label: {
        flexDirection: 'column',
        width: '20%',
        fontSize:12,
        fontFamily: 'Open Sans Bold',
      },
      labelHead: {
        flexDirection: 'column',
        width: '20%',
        fontSize:12,
        fontFamily: 'Open Sans Bold',
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: '#000000'
      },
      colHead: {
        flexDirection: 'row',
        width: '100%',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#000000',
        marginTop:-1
      },
      labelA: {
        flexDirection: 'row',
        width: '40%',
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: '#000000'
      },
      labelD: {
        flexDirection: 'row',
        width: '60%'
      },
      labelDataA: {
        flexDirection: 'column',
        width: '20%',
        fontSize:10,
        fontFamily: 'Open Sans Bold'
      },
      valueDataA: {
        flexDirection: 'column',
        width: '80%',
        fontSize:10,
        fontFamily: 'Open Sans'
      },
      labelDataD: {
        flexDirection: 'column',
        width: '35%',
        fontSize:10,
        fontFamily: 'Open Sans Bold'
      },
      valueDataD: {
        flexDirection: 'column',
        width: '65%',
        fontSize:10,
        fontFamily: 'Open Sans'
      },
      rowTitleOne: {
        flexDirection: 'row',
        width: '100%',
        marginBottom: 20,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#000000',
        marginTop:5
      },
      rowTitle: {
        flexDirection: 'row',
        width: '100%',
        marginTop: 20,
        marginBottom: 20,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#000000'
      },
      labelTitle: {
        flexDirection: 'column',
        fontSize:12,
        fontFamily: 'Open Sans Bold',
        textAlign: 'center',
        
      },
      text: {
        flexDirection: 'column',
        fontSize:12,
        fontFamily: 'Open Sans',
        alignItems: 'flex-start',
        justifySelf: 'flex-start',
      },
      footer: {
        position: 'absolute',
        fontSize: 8,
        bottom: 10,
        left: 20,
        right: 0,
        textAlign: 'center',
        color: 'grey',
        fontFamily: 'Open Sans',
      },
      footerPage: {
        position: 'absolute',
        fontSize: 8,
        bottom: 60,
        left: 0,
        right: 20,
        textAlign: 'right',
        color: 'black',
        fontFamily: 'Open Sans Bold',
      },
      icono: {
        position: 'absolute',
        float: 'right',
        width: 40,
        bottom: 10,
        right: 20,
      },
      labelData: {
        flexDirection: 'column',
        marginRight: 10,
        fontSize:10,
        fontFamily: 'Open Sans Bold',
      },
      textData: {
        flexDirection: 'column',
        fontSize:10,
        fontFamily: 'Open Sans',
        alignItems: 'flex-start',
        justifySelf: 'flex-start',
        flexWrap: 'wrap',
        width: 250
      }
      
     
})    



class Evaluacion extends React.Component {

    state = {
        value: '',
        modalNuevoAbierto: false,
        modalEvaluacionAbierto: false,
        evaluaciones:[],
        nuevoActivado:true,
        editarActivado:false,
        empleados: [ ],
        trabajadorSeleccionado: null,
        puestosDeTrabajo: [ ],
        codigoEmpleadoMal: null,
        riesgoOptions: [
            { value: 1, label: i18next.t("valoracionrendimiento.bajo",{ns:"page"}), color: "green" },
            { value: 2, label: i18next.t("valoracionrendimiento.medio",{ns:"page"}), color: "yellow" },
            { value: 3, label: i18next.t("valoracionrendimiento.alto",{ns:"page"}), color: "orange"},
            { value: 4, label: i18next.t("valoracionrendimiento.muyalto",{ns:"page"}), color: "red"},
        ],
        responsabilidad: 1,
        adaptabilidad: 3,
        calidadTrabajo: 3,
        capacidadTrabajo: 3,
        cumplimientoObjetivos: 3,
        cumplimientoPlazos: 3,
        compromisoEmpresa: 3,
        capacidadDecision: 3,
        capacidadTrabajoEquipo: 3,
        conocimientos: 3,
        fiabilidad: 3,
        flexibilidad: 3,
        organizacion: 3,
        iniciativa: 3,
        disponibilidadAprender: 3,
        capacidadResolucion: 3,
        puntualidad: 3,
        liderazgo: 3,
        motivacion: 3,
        radioConclusion:1,
        observacionesEvaluacion: null,
        riesgoEvaluacion: 1,
        observacionesConclusion: null,
        fechaProximaValoracion: new Date(),
        fechaValoracion: new Date(),
        evaluadorSeleccionado:null,
        evaluacionSeleccionada:null,
        modalBorrar:false,
        busquedaEval:"",
        cargando: false,
        imprimirActivado:false,
        modalImprimir:false,
        imprimirFichaActivado:false,
        modalImprimirFicha:false,
        riesgoDescr:null,
        responsabilidadDesc:null,
        conclusionDesc:null,
        areas: [],
        evaluacionesTablaGral: [],
        cargandoGrid:false
    }

    

    

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  recargarEmpleadosProps=async ()=>{
        //cargar el select de personas.
        await this.props.client
        .query({
            query: gql` 
                {empleados(FILTROS:{ID_EMPLEADO:${this.props.empleado.ID_EMPLEADO}},ORDEN:"ID_EMPLEADO,ASC"){ID_EMPLEADO,FECHA_ALTA,FECHA_BAJA,EMPLEADO{ID_PERSONA}}}
            `,
            fetchPolicy:'network-only'
        }).then(async result=>{ 

            let empleados=JSON.parse(JSON.stringify(result.data.empleados))
            for(let empleado of empleados){
                
                //esto es para el select del evaluador
                empleado.value=empleado.ID_EMPLEADO

                //OBTENCION NOMBRE DE LA PERSONA
                await this.props.client
                .query({
                    query: gql` 
                        {personasFisicas(FILTROS:{ID_PERSONA:${empleado.ID_EMPLEADO}}){NOMBRE,APELLIDO1,APELLIDO2}}
                    `,
                    fetchPolicy:'network-only'
                }).then(result=>{ 

                    for(let persona of result.data.personasFisicas){
                
                        empleado.nombre=` ${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`
                        empleado.label=empleado.nombre

                    }

                })


                //Obtencion del puesto de trabajo actual del empleado
                await this.props.client
                .query({
                    query: gql` 
                        {empleadosPuestos(FILTROS:{ID_EMPLEADO:${empleado.ID_EMPLEADO},FECHA_BAJA:null},LIMIT:1,ORDEN:"FECHA_ALTA,ID_PUESTO,DESC"){ID_EMPLEO,FECHA_ALTA,FECHA_BAJA,PUESTO_TRABAJO{ID_PUESTO,DESCRIPCION,GRADO_RESPONSABILIDAD,ID_RIESGO_OBJETIVO,ID_DEPARTAMENTO,DEPARTAMENTO{ID_AREA},DESC_CORTA,DESC_LARGA}}}
                    `,
                    fetchPolicy:'network-only'
                }).then(async result=>{ 

                    //SI TENEMOS PUESTO.
                    result.data.empleadosPuestos.forEach(async puesto=>{

                        empleado.idPuesto=puesto.PUESTO_TRABAJO.ID_PUESTO
                        empleado.idDepartamento=puesto.PUESTO_TRABAJO.ID_DEPARTAMENTO
                        empleado.idArea=puesto.PUESTO_TRABAJO.DEPARTAMENTO.ID_AREA
                        empleado.puestoActual=puesto.PUESTO_TRABAJO.DESCRIPCION
                        empleado.riesgoPuesto=puesto.PUESTO_TRABAJO.ID_RIESGO_OBJETIVO
                        empleado.fechaPuesto=puesto.FECHA_ALTA

                    })
            
                })

            }
            await this.setState({empleados:empleados})

        })

    }

  recargarEmpleados=async ()=>{

        //cargar el select de personas.
        let empleados=await this.props.client
        .query({
            query: gql` 
                {empleados(ORDEN:"ID_EMPLEADO,ASC"){ID_EMPLEADO,FECHA_ALTA,FECHA_BAJA,CODIGO_EMPLEADO,EMPLEADO{ID_PERSONA}}}
            `,
            fetchPolicy:'network-only'
        }).then(async result=>{ 
            return result.data.empleados
        })

        let personasFisicas=await this.props.client
        .query({
            query: gql` 
                {personasFisicas{ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}
            `,
            fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.personasFisicas
        })

        let puestos=await this.props.client
        .query({
            query: gql` 
                {empleadosPuestos(FILTROS:{FECHA_BAJA:null},ORDEN:"FECHA_ALTA,ID_PUESTO,DESC"){
                    ID_EMPLEO,
                    FECHA_ALTA,
                    FECHA_BAJA,
                    ID_EMPLEADO,
                    PUESTO_TRABAJO{ID_PUESTO,CODIGO_PUESTO,DESCRIPCION,GRADO_RESPONSABILIDAD,ID_RIESGO_OBJETIVO,DEPARTAMENTO{AREA{DESCRIPCION}},ID_DEPARTAMENTO,DEPARTAMENTO{ID_AREA,DESCRIPCION},DESC_CORTA,DESC_LARGA}}}
            `,
            fetchPolicy:'network-only'
        }).then(async result=>{
            return result.data.empleadosPuestos
        })
                    
        for(let empleado of empleados){
                empleado.value=empleado.ID_EMPLEADO
                empleado.codigoEmpleado= empleado.CODIGO_EMPLEADO
                
                personasFisicas.find(persona=>{
                  if (persona.ID_PERSONA===empleado.ID_EMPLEADO) {
                    empleado.nombre=` ${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`
                    empleado.label=empleado.nombre 
                  } 
                })
                puestos.find(puesto=>{
                    if (puesto.ID_EMPLEADO===empleado.ID_EMPLEADO) {
                        empleado.idPuesto=puesto.PUESTO_TRABAJO.ID_PUESTO
                        empleado.idDepartamento=puesto.PUESTO_TRABAJO.ID_DEPARTAMENTO
                        empleado.area=puesto.PUESTO_TRABAJO.DEPARTAMENTO.AREA.DESCRIPCION
                        empleado.idArea=puesto.PUESTO_TRABAJO.DEPARTAMENTO.ID_AREA
                        empleado.puestoActual=puesto.PUESTO_TRABAJO.DESCRIPCION
                        empleado.riesgoPuesto=puesto.PUESTO_TRABAJO.ID_RIESGO_OBJETIVO
                        empleado.fechaPuesto=puesto.FECHA_ALTA
                        empleado.codigoPuesto=puesto.PUESTO_TRABAJO.CODIGO_PUESTO
                        empleado.departamento=puesto.PUESTO_TRABAJO.DEPARTAMENTO.DESCRIPCION
                    }
                })

            
            }
            this.setState({empleados:empleados})
    
    }

    recargarEvaluaciones=async()=>{

        //cargar tabla evaluaciones.
        let evaluaciones=await this.props.client
        .query({
            query: gql` 
                {empleadosValoracion
                    {
                        ID_VALORACION,
                        ID_EMPLEADO,
                        ANTIGUEDAD_PUESTO,
                        ID_PUESTO,
                        GRADO_RESPONSABILIDAD,
                        ADAPTABILIDAD,
                        CALIDAD_TRABAJO,
                        CAPACIDAD_TRABAJO,
                        CUMPLIDOR_OBJETIVOS,
                        CUMPLIMIENTO_PLAZOS,
                        COMPROMISO_EMPRESA,
                        CAPACIDAD_DECISION,
                        CAPACIDAD_TRABAJO_EQUIPO,
                        CONOCIMIENTOS_PROFESIONALES,
                        FIABILIDAD,
                        FLEXIBILIDAD,
                        ORGANIZACION,
                        INICIATIVA,
                        DISPONIBILIDAD_APRENDER,
                        CAPACIDAD_RESOLUCION,
                        PUNTUALIDAD,
                        LIDERAZGO,
                        MOTIVACION,
                        OBSERVACIONES,
                        ID_PERFIL_RIESGO,
                        CONCLUSION,
                        DECISION,
                        FECHA_PROXIMA,
                        FECHA,
                        ID_RESPONSABLE,
                        ID_PUESTO_RESPONSABLE
                    }
                }
            `,
            fetchPolicy:'network-only'
        }).then(async result=>{ 
            return result.data.empleadosValoracion
        })

        for(let ev of evaluaciones){
            let trabajador=this.state.empleados.find(empleado=>{return empleado.ID_EMPLEADO===ev.ID_EMPLEADO})
            let evaluador=this.state.empleados.find(empleado=>{return empleado.ID_EMPLEADO===ev.ID_RESPONSABLE})

            ev.trabajador=trabajador
            ev.evaluador=evaluador
        }

        this.setState({evaluaciones:evaluaciones})
        console.log(this.state.evaluaciones)
        this.setState({copyEvaluaciones:evaluaciones})

    }

    cargarEmpresa= async() => {
        
         //DATOS JURIDICOS
         let datosEmpresa= await this.props.client
         .query({
             query: gql`
             {
                 personasJuridicas(FILTROS:{ID_PERSONA:1}){ID_PERSONA,ID_GRUPO_EMPRESARIAL,TIPO_CAPITAL_SOCIAL{DESCRIPCION},GRUPO_EMPRESARIAL{DESCRIPCION},TIPO_SOCIEDAD{DESCRIPCION},ID_PERSONA,RAZON_SOCIAL,NOMBRE_COMERCIAL,EMPRESA_PRINCIPAL,FECHA_FINALIZACION,INICIO_EJERCICIO,FIN_EJERCICIO,ESCISIONES,FUSIONES,FILIALES,WEB_CORPORATIVA,CONVENIOS_COLECTIVOS,TOTAL_PARTIDAS_ACTIVO,CIFRA_ANUAL_NEGOCIO,NUMERO_MEDIO_TRABAJADORES}
             }
             `,
             fetchPolicy: 'network-only'
 
         }).then(result=>{
             //si tenemos razon social.
             if(result.data.personasJuridicas.length>0){
                 return {
                     "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL,
                     "nombreComercial":result.data.personasJuridicas[0].NOMBRE_COMERCIAL,
                     "sitioWeb":result.data.personasJuridicas[0].WEB_CORPORATIVA,
                     "tipoSociedad":result.data.personasJuridicas[0].TIPO_SOCIEDAD?result.data.personasJuridicas[0].TIPO_SOCIEDAD.DESCRIPCION:"",
                     "tipoTitulos":result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL?result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL.DESCRIPCION:"",
                     "totalPartidas":result.data.personasJuridicas[0].TOTAL_PARTIDAS_ACTIVO,
                     "cifraAnual":result.data.personasJuridicas[0].CIFRA_ANUAL_NEGOCIO,
                     "numTrabajadores":result.data.personasJuridicas[0].NUMERO_MEDIO_TRABAJADORES,
                     "escisiones":result.data.personasJuridicas[0].ESCISIONES,
                     "fusiones":result.data.personasJuridicas[0].FUSIONES,
                     "filiales":result.data.personasJuridicas[0].FILIALES,
                     "inicioAct":result.data.personasJuridicas[0].INICIO_EJERCICIO,
                     "finAct":result.data.personasJuridicas[0].FIN_EJERCICIO,
                     "grupoEmpresarial":result.data.personasJuridicas[0].GRUPO_EMPRESARIAL?result.data.personasJuridicas[0].GRUPO_EMPRESARIAL.DESCRIPCION:"",
                     "conveniosColectivos":result.data.personasJuridicas[0].CONVENIOS_COLECTIVOS
                 }
 
             }
             
         })
 
        return datosEmpresa
    
    }

    componentDidMount=async()=>{

        this.setState({ cargando: true })

        if(this.props.empleado){
            await this.recargarEmpleadosProps()
        } else {
            this.cargarAreas()
            await this.recargarEmpleados()
            await this.recargarEvaluaciones()
        }        
        this.setState({cargando: false})
        
    }

    // obtenerCodigo=async(empleado)=>{

    //     if(!empleado.idPuesto)return empleado
    //     //OBTENER EL NUMERO DEL AREA.
    //     let indiceArea=await this.props.client
    //     .query({
    //         query: gql` 
    //             {departamentosAreas(CAMPOS:"ID_AREA",FILTROS:{PREDEFINIDA:false}){ID_AREA}}
    //         `,
    //         fetchPolicy:'network-only'
    //     }).then(async result=>{ 
    //         let index=0
    //         result.data.departamentosAreas.find((area)=>{
    //             index++
    //             return parseInt(area.ID_AREA)===parseInt(empleado.idArea)
    //         })
    //         return index
    //     })

    //     let indiceDepartamento=await this.props.client
    //     .query({
    //         query: gql` 
    //             {departamentos(CAMPOS:"ID_DEPARTAMENTO",FILTROS:{PREDEFINIDO:false,ID_AREA:${empleado.idArea}}){ID_DEPARTAMENTO}}
    //         `,
    //         fetchPolicy:'network-only'
    //     }).then(async result=>{ 
    //         let index=0
    //         result.data.departamentos.find(departamento=>{
    //             index++
    //             return parseInt(departamento.ID_DEPARTAMENTO)===parseInt(empleado.idDepartamento)
    //         })
    //         return index
    //     })

    //     let indicePuesto=await this.props.client
    //     .query({
    //         query: gql` 
    //             {puestosTrabajo(CAMPOS:"ID_PUESTO",FILTROS:{ID_DEPARTAMENTO:${empleado.idDepartamento}}){ID_PUESTO}}
    //         `,
    //         fetchPolicy:'network-only'
    //     }).then(async result=>{ 
    //         let index=0
    //         result.data.puestosTrabajo.find(pt=>{
    //             index++
    //             return parseInt(pt.ID_PUESTO)===parseInt(empleado.idPuesto)
    //         })
    //         return index
    //     })

    //     let indiceEmpleado=await this.props.client
    //     .query({
    //         query: gql` 
    //             {departamentosEmpleados(FILTROS:{ID_DEPARTAMENTO:${empleado.idDepartamento}}){ID_EMPLEADO}}
    //         `,
    //         fetchPolicy:'network-only'
    //     }).then(async result=>{ 
    //         let index=0
    //         result.data.departamentosEmpleados.find(ed=>{
    //             index++
    //             return parseInt(ed.ID_EMPLEADO)===parseInt(empleado.ID_EMPLEADO)
    //         })
    //         return index
    //     })

    //     empleado.codigoPuesto=indiceArea+""+indiceDepartamento+""+indicePuesto
    //     empleado.CODIGO_EMPLEADO=indiceArea+""+indiceDepartamento+""+indicePuesto+""+indiceEmpleado

    //     return empleado

    // }
    CargarDatosEmpr= async () =>{
        let datosEmpresa = await this.cargarEmpresa()
        this.setState({
            cargandoImprimir:false,
            datosEmpresa:datosEmpresa
        })
    }




    // componentDidUpdate=async(props)=>{
    //     if(this.props!==props){
    //         if(this.props.abierto){
  
    //             await this.setState({
    //                 responsabilidad: 1,
    //                 adaptabilidad: 3,
    //                 calidadTrabajo: 3,
    //                 capacidadTrabajo: 3,
    //                 cumplimientoObjetivos: 3,
    //                 cumplimientoPlazos: 3,
    //                 compromisoEmpresa: 3,
    //                 capacidadDecision: 3,
    //                 capacidadTrabajoEquipo: 3,
    //                 conocimientos: 3,
    //                 fiabilidad: 3,
    //                 flexibilidad: 3,
    //                 organizacion: 3,
    //                 iniciativa: 3,
    //                 disponibilidadAprender: 3,
    //                 capacidadResolucion: 3,
    //                 puntualidad: 3,
    //                 liderazgo: 3,
    //                 motivacion: 3,
    //                 radioConclusion:1,
    //                 observacionesEvaluacion: null,
    //                 riesgoEvaluacion: 1,
    //                 observacionesConclusion: null,
    //                 fechaProximaValoracion: new Date(),
    //                 fechaValoracion: new Date(),
    //                 evaluadorSeleccionado:null,
    //                 evaluacionSeleccionada:null,
    //                 modalEvaluacionAbierto: true
    //             })
    //         }

    //         if(this.props.empleado){

       
    //             let empleado=await this.state.empleados.find(emp=>{return emp.ID_EMPLEADO===this.props.empleado.id})
    //             if(typeof empleado==="undefined"){

    //                 await this.recargarEmpleados().then(async ()=>{
    //                     empleado=await this.state.empleados.find(emp=>{return emp.ID_EMPLEADO===this.props.empleado.id})
    //                 })

    //             }

    //             //Calculamos el tiempo del puesto de trabajo 
    //             let fechaActual = moment(this.state.fechaValoracion);
    //             let fechaAlta = moment(empleado.fechaPuesto);

    //             let meses = fechaActual.diff(fechaAlta, 'months');
    //             fechaAlta.add(meses, 'months');

    //             empleado.antiguedad=meses+" meses"   
    //             await this.setState({trabajadorSeleccionado:await this.obtenerCodigo(empleado)})

    //         }
       
    //     }
        
    // }
    conclusionDesc=(data) =>{
        let conclusion='';
        switch (data) {
            case 1:
                conclusion="1. Rendimiento muy insuficiente. Formación ineficaz. Sin cohesión ni compromiso con la empresa."            
                break;
            case 2:
                conclusion="2. Rendimiento insuficiente. Puede mejorar. Valorable formación y/o cambio de puesto de trabajo."                
                break;
            case 3:
                conclusion="3. Rendimiento óptimo. Mantener motivación. Valorar promoción y formación."             
                break;
             case 4:
                 conclusion="4. Rendimiento de referencia. Comprometido con la cultura empresarial."        
                break;    
            default:
                break;
        }
        this.setState({conclusionDesc:conclusion})

    }
    gradoResp= (data) =>{
        let responsabilidad=''
        switch (data) {
            case 1:
                responsabilidad="1. Areas de escasa iniciativa, sin precisar conocimientos específicos."
                break;
            case 2:
                responsabilidad="2. Tareas auxiliares con alguna iniciativa, bajo estrecha supervisión." 
                break;
            case 3:
              responsabilidad="3. Tareas que precisan iniciativa y conocimientos técnicos específicos." 
            break;
            case 4:
                responsabilidad="4. Tareas ejecutivas, debiendo tomar decisiones sobre los métodos a emplear."
            break;
            case 5:
                responsabilidad="5. Tareas de jefatura de otros trabajadores, con responsabilidad en el departamento."
            break;
            case 6:
                responsabilidad="6. Tareas de planeamiento y supervisión de varios departamentos." 
            break;
            case 7:
                responsabilidad="7. Tareas de alta especialización con titulación superior y experiencia, asesorando a la dirección."
            break;
            case 8:
                responsabilidad= "8. Interpreta y aplica políticas de empresa en varios deparamentos, reportando a la alta dirección."
            break;         
        
            default:
                break;
        }

        this.setState({responsabilidadDesc: responsabilidad})
        console.log(this.state.responsabilidadDesc)

    }

    riesgoDescr= (data) =>{
        let riesgoDesc=[]
        if(this.state.trabajadorSeleccionado.riesgoPuesto){
        let color=''
        let value=''
        switch (data) {
            case 1:
                color= "green"
                value= i18next.t("valoracionrendimiento.bajo",{ns:"page"})                
                break;
            case 2:
                color= "yellow"
                value= i18next.t("valoracionrendimiento.medio",{ns:"page"})                
                break;
            case 3:
                color= "orange"
                value= i18next.t("valoracionrendimiento.alto",{ns:"page"})                
                break;
             case 4:
                 color="red"
                 value=i18next.t("valoracionrendimiento.muyalto",{ns:"page"})          
                break;    
            default:
                break;
        }
        riesgoDesc.push({"color":color, "value":value});
    }
        this.setState({riesgoDescr:riesgoDesc})
        console.log(this.state.riesgoDescr)
        

    }
    ImprimirFicha= () => (
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                {this.riesgoDescr(this.state.trabajadorSeleccionado.riesgoPuesto)}
                {this.gradoResp(this.state.responsabilidad)}

                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("valoracionrendimiento.title",{ns:"page"})}</Text>
                </View>
                
                    <View style={styles.rowTitleOne}>
                         <Text style={styles.labelTitle}>{i18next.t("valoracionrendimiento.titledatos",{ns:"page"}).toUpperCase()}</Text>                            
                    </View> 

                           <View style={styles.row}>
                                <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.fullname",{ns:"page"}).toUpperCase()}:</Text>
                                <Text style={styles.textData}>{this.state.trabajadorSeleccionado?this.state.trabajadorSeleccionado.nombre:''}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.codigo_empleado",{ns:"page"}).toUpperCase()}:</Text>
                                <Text style={styles.textData}>{this.state.trabajadorSeleccionado?this.state.trabajadorSeleccionado.codigoEmpleado:''}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.area",{ns:"page"}).toUpperCase()}:</Text>
                                <Text style={styles.textData}>{this.state.trabajadorSeleccionado?this.state.trabajadorSeleccionado.area:''}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.departamento",{ns:"page"}).toUpperCase()}:</Text>
                                <Text style={styles.textData}>{this.state.trabajadorSeleccionado?this.state.trabajadorSeleccionado.departamento:''}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.puesto",{ns:"page"}).toUpperCase()}:</Text>
                                <Text style={styles.textData}>{this.state.trabajadorSeleccionado?this.state.trabajadorSeleccionado.puestoActual:''}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.antiguedad",{ns:"page"}).toUpperCase()}:</Text>
                                <Text style={styles.textData}>{this.state.trabajadorSeleccionado?this.state.trabajadorSeleccionado.antiguedad:''}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.codigo_puesto",{ns:"page"}).toUpperCase()}:</Text>
                                <Text style={styles.textData}>{this.state.trabajadorSeleccionado?this.state.trabajadorSeleccionado.codigoPuesto:''}</Text>
                            </View>
                            {this.state.riesgoDescr.map((riesgo, index) => {
                                let color= riesgo.color;
                                let value= riesgo.value
                                console.log(value)
                                return(
                                    <View style={styles.row}>              
                                        <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.riesgo",{ns:"page"}).toUpperCase()}:</Text>
                                        <Text style={{backgroundColor:`${color}`, paddingHorizontal: 8, flexDirection:'column', fontSize:10, fontFamily: 'Open Sans', width: 70, border:'1 solid #00000'}}>{value}</Text>
                                </View>
                                 )
                            })}
                           
                            
                    <View style={styles.rowTitle}>
                         <Text style={styles.labelTitle}>{i18next.t("valoracionrendimiento.titlegrado",{ns:"page"}).toUpperCase()}</Text>                            
                    </View> 
                    
                    <View style={styles.row}>              
                        <Text style={{textAlign:'center',flexDirection: 'row',fontSize:10,fontFamily: 'Open Sans', width:'100%', paddingHorizontal: 12}}>{this.state.responsabilidad?this.state.responsabilidadDesc:''}</Text>
                    </View>
                    
                    <View style={styles.rowTitle}>
                         <Text style={styles.labelTitle}>{i18next.t("valoracionrendimiento.titleevaluacion",{ns:"page"}).toUpperCase()}</Text>                            
                    </View>
                    
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.adaptabilidad",{ns:"page"}).toUpperCase()}:</Text>
                        <Text style={styles.textData}>{this.state.adaptabilidad}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.calidad_trabajo",{ns:"page"}).toUpperCase()}:</Text>
                        <Text style={styles.textData}>{this.state.calidadTrabajo}</Text>
                    </View>
                    <View style={styles.row}>             
                        <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.capacidad_trabajo",{ns:"page"}).toUpperCase()}:</Text>
                        <Text style={styles.textData}>{this.state.capacidadTrabajo}</Text>
                    </View>        
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.cumplimiento_objetivo",{ns:"page"}).toUpperCase()}:</Text>
                        <Text style={styles.textData}>{this.state.cumplimientoObjetivos}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.cumplimiento_plazo",{ns:"page"}).toUpperCase()}:</Text>
                        <Text style={styles.textData}>{this.state.cumplimientoPlazos}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.compromiso_empresa",{ns:"page"}).toUpperCase()}:</Text>
                        <Text style={styles.textData}>{this.state.compromisoEmpresa}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.capacidad_decision",{ns:"page"}).toUpperCase()}:</Text>
                        <Text style={styles.textData}>{this.state.capacidadDecision}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.capacidad_equipo",{ns:"page"}).toUpperCase()}:</Text>
                        <Text style={styles.textData}>{this.state.capacidadTrabajoEquipo}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.conocimientos_profesionales",{ns:"page"}).toUpperCase()}:</Text>
                        <Text style={styles.textData}>{this.state.conocimientos}</Text>
                    </View>   
                  
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.fiabilidad",{ns:"page"}).toUpperCase()}:</Text>
                        <Text style={styles.textData}>{this.state.fiabilidad}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.flexibilidad",{ns:"page"}).toUpperCase()}:</Text>
                        <Text style={styles.textData}>{this.state.flexibilidad}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.organizacion",{ns:"page"}).toUpperCase()}:</Text>
                        <Text style={styles.textData}>{this.state.organizacion}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.iniciativa",{ns:"page"}).toUpperCase()}:</Text>
                        <Text style={styles.textData}>{this.state.iniciativa}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.disponibilidad_aprender",{ns:"page"}).toUpperCase()}:</Text>
                        <Text style={styles.textData}>{this.state.disponibilidadAprender}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.capacidad_resolucion",{ns:"page"}).toUpperCase()}:</Text>
                        <Text style={styles.textData}>{this.state.capacidadResolucion}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.puntualidad",{ns:"page"}).toUpperCase()}:</Text>
                        <Text style={styles.textData}>{this.state.puntualidad}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.liderazgo",{ns:"page"}).toUpperCase()}:</Text>
                        <Text style={styles.textData}>{this.state.liderazgo}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.motivacion",{ns:"page"}).toUpperCase()}:</Text>
                        <Text style={styles.textData}>{this.state.motivacion}</Text>
                    </View>         
                   
                    
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.observaciones",{ns:"page"}).toUpperCase()}:</Text>
                        <Text style={styles.textData}>{this.state.observacionesEvaluacion?this.state.observacionesEvaluacion:''}</Text>
                    </View>
                    {this.riesgoDescr(this.state.riesgoEvaluacion)}{this.state.riesgoEvaluacion?this.state.riesgoDescr.map((riesgo) => {
                                let color= riesgo.color;
                                let value= riesgo.value
                            return(
                                <View style={styles.row}>              
                                <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.perfil",{ns:"page"}).toUpperCase()}:</Text>
                                <Text style={{backgroundColor:`${color}`, paddingHorizontal: 8, flexDirection:'column', fontSize:10, fontFamily: 'Open Sans', width: 70, border:'1 solid #00000'}}>{value}</Text>
                            </View>
                            )
                    }):<></>
                }
                    
                    <View style={styles.rowTitle}>
                         <Text style={styles.labelTitle}>{i18next.t("valoracionrendimiento.conclusion",{ns:"page"}).toUpperCase()}</Text>                            
                    </View>
                    {this.conclusionDesc(this.state.radioConclusion)}
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.conclusion_evaluacion",{ns:"page"})}:</Text>
                        <Text style={styles.textData}>{this.state.radioConclusion?this.state.conclusionDesc:''}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.decision_propuesta",{ns:"page"}).toUpperCase()}:</Text>
                        <Text style={styles.textData}>{this.state.observacionesConclusion?this.state.observacionesConclusion:''}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.fecha_propuesta",{ns:"page"}).toUpperCase()}:</Text>
                        <Text style={styles.textData}>{moment(this.state.fechaProximaValoracion).format("DD/MM/YYYY")}</Text>
                    </View>
                    <View style={styles.rowTitle}>
                         <Text style={styles.labelTitle}>{i18next.t("valoracionrendimiento.evaluador",{ns:"page"}).toUpperCase()}</Text>                            
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.evaluador",{ns:"page"}).toUpperCase()}:</Text>
                        <Text style={styles.textData}>{this.state.evaluadorSeleccionado?this.state.evaluadorSeleccionado.nombre:''}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.codigo_empleado",{ns:"page"}).toUpperCase()}:</Text>
                        <Text style={styles.textData}>{this.state.evaluadorSeleccionado?this.state.evaluadorSeleccionado.codigoEmpleado:''}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.puesto",{ns:"page"}).toUpperCase()}:</Text>
                        <Text style={styles.textData}>{this.state.evaluadorSeleccionado?this.state.evaluadorSeleccionado.puestoActual:''}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.codigo_puesto",{ns:"page"}).toUpperCase()}:</Text>
                        <Text style={styles.textData}>{this.state.evaluadorSeleccionado?this.state.evaluadorSeleccionado.codigoPuesto:''}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>{i18next.t("valoracionrendimiento.fecha_evaluacion",{ns:"page"}).toUpperCase()}:</Text>
                        <Text style={styles.textData}>{moment(this.state.fechaValoracion).format("DD/MM/YYYY")}</Text>
                    </View>
                                        
            </View>
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
                />
            <Image
                        style={styles.icono}
                        src={iconoFooter}
                        fixed
                        />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    generarFicha = async () => {
        this.setState({
            modalImprimirFicha:true,
            cargandoImprimir:true,
            cargandoGrid:true
        }, async () => {
            if(this.state.datosEmpresa==null){
                await this.CargarDatosEmpr()
                ReactDOM.render(this.ImprimirFicha(), document.getElementById('generarFicha-pdf'))
            }else{
                this.setState({
                    cargandoImprimir:false,
                }, () =>{
                    ReactDOM.render(this.ImprimirFicha(), document.getElementById('generarFicha-pdf'))
                })
            }
        }) 
    }


     ImprimirTabla= () => (
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("registrosretributivos.recursoshumanos",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("valoracionrendimiento.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                {this.state.evaluacionesTablaGral?this.state.evaluacionesTablaGral.map((evaluacionAD, indexG) => {
                    
                    return(
                        
                        <View key={indexG}>
                                <View style={styles.rowHeadGral}>
                                    <View style={styles.labelA}>
                                        <Text style={styles.labelDataA}>{i18next.t("valoracionrendimiento.area",{ns:"page"}).toUpperCase()}:</Text>
                                        <Text style={styles.valueDataA}>{evaluacionAD.area}</Text> 
                                    </View>
                                    <View style={styles.labelD}>
                                        <Text style={styles.labelDataD}>{i18next.t("valoracionrendimiento.departamento",{ns:"page"}).toUpperCase()}:</Text>
                                        <Text style={styles.valueDataD}>{evaluacionAD.dpto}</Text> 
                                    </View>
                                </View>
                            {evaluacionAD.evaluaciones.map((evaluacion, index)=>{
                            return (
                            <View key={index}>       
                            {index===0 && (
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'5%'}}>Nº</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'25%'}}>{i18next.t("valoracionrendimiento.trabajador",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("valoracionrendimiento.codigo_puesto",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("valoracionrendimiento.fecha_evaluacion",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'25%'}}>{i18next.t("valoracionrendimiento.proxima",{ns:"page"})}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("valoracionrendimiento.conclusion",{ns:"page"}).toUpperCase()}</Text>
                                </View>                               
                            )}
                           
                            <View style={styles.colHead}>
                                  <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'5%'}}>{index+1}</Text>
                                  <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'25%'}}>{evaluacion.trabajador.nombre?evaluacion.trabajador.nombre:''}</Text>
                                  <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{evaluacion.trabajador.codigoPuesto!==null?evaluacion.trabajador.codigoPuesto:''}</Text>                                  
                                  <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{evaluacion.FECHA!==null?moment(evaluacion.FECHA).format("DD-MM-YYYY "):''}</Text>                               
                                  <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'25%'}}>{this.state.fechaProximaValoracion!==null?moment(this.state.fechaProximaValoracion).format("DD-MM-YYYY "):''}</Text>
                                  <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{evaluacion.CONCLUSION?evaluacion.CONCLUSION:''}</Text>
                            </View> 
                                           
                            </View>
                        )
                        })}
                        </View>
                        
                    )
                }):<></>}
                
                        
            </View>
            <Text
            style={styles.footerPage}
            render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
            fixed
            />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    cargarAreas=async()=>{

        let areas=[]
        
        // obtencion de las areas
        await this.props.client.query({
            query:gql`{departamentosAreas(FILTROS:{PREDEFINIDA:false}){ID_AREA,DESCRIPCION,PREDEFINIDA}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{

           
            result.data.departamentosAreas.forEach(async(area)=>{
                areas.push({id:area.ID_AREA,value:area.ID_AREA,label:area.DESCRIPCION,tipo:"area"})
                
            })  
            this.setState({
                areas:areas,
            })
             
        })
     
        areas.forEach(async (area,index)=>{

            await this.props.client.query({
                query:gql`{departamentos(FILTROS:{ID_AREA:${area.id}}){ID_DEPARTAMENTO,DESCRIPCION}}`
                ,
                fetchPolicy:'network-only'
            }).then(async(result)=>{
    
                let deps=[]
                result.data.departamentos.forEach(async(dep)=>{
                    deps.push({id:dep.ID_DEPARTAMENTO,label:dep.DESCRIPCION,tipo:"departamento",padre:area.id})
                })  
                areas[index].childNodes=deps
               
            })

        })
      
    }

    cargarEvaluacionGral= async(area,dpto,idDep) =>{
        
        let evaluacionesGral = this.state.evaluacionesTablaGral
        let existe=false
        let evaluacionFind=[]
        this.state.evaluaciones.find(evaluacion=>{
            if (evaluacion.trabajador.idDepartamento===idDep) {
                existe=true
                evaluacionFind.push(evaluacion)
            }
        })
        if (existe) {
            evaluacionesGral.push({area:area,dpto:dpto,evaluaciones:evaluacionFind})
        }
        this.setState({evaluacionesTablaGral : evaluacionesGral })
      }

    cargarEvaluacion = async () => {
        if (this.state.areas!==null) {
            this.state.areas.map((area, index)=>{
                area.childNodes.map(async (child, indexC)=>{
                    await this.cargarEvaluacionGral(area.label,child.label,child.id)
                })
            })
        }
    }  

    generarTablaImprimir = async () => {
        this.setState({
            modalImprimir:true,
            cargandoImprimir:true,
            evaluacionesTablaGral:[],
            cargandoGrid:true
        }, async() => {
            await this.cargarEvaluacion();
            console.log(this.state.evaluacionesTablaGral)
            if(this.state.datosEmpresa==null){
                await this.CargarDatosEmpr()
                ReactDOM.render(this.ImprimirTabla(), document.getElementById ('generarTabla-pdf'))
            }else{
                this.setState({
                    cargandoImprimir:false,
                }, () =>{
                    ReactDOM.render(this.ImprimirTabla(), document.getElementById('generarTabla-pdf'))
                })
            }
        }) 
    }

  


   
    

    render() {

        document.title = i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("valoracionrendimiento.title",{ns:"page"})

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };

       
        const abrirModalNuevo = async() => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            this.setState({
                nuevoActivado:true,
                editarActivado:false,
                borrarActivado:false,
                modalNuevoAbierto: true,
                imprimirActivado:false,
                trabajadorSeleccionado:null,
                evaluacionSeleccionada:null,
                cargandoGrid:true
            })
       }
       const cerrarModalImprimir= () =>{
           this.setState({
               modalImprimir:false,
               cargandoGrid:false
           })
       } 
       const cerrarModalImprimirFicha= () =>{
        this.setState({
            modalImprimirFicha:false
        })
    } 

        const abrirModalEditar =async () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.evaluacionSeleccionada){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            let evaluacion=this.state.evaluacionSeleccionada

            //Calculamos el tiempo del puesto de trabajo 
            let fechaActual = moment(this.state.fechaValoracion);
            let fechaAlta = moment(evaluacion.trabajador.fechaPuesto);

            let meses = fechaActual.diff(fechaAlta, 'months');
            fechaAlta.add(meses, 'months');

            evaluacion.trabajador.antiguedad=meses+" meses"


            await this.setState({
                nuevoActivado:false,
                editarActivado:true,
                borrarActivado:false,
                responsabilidad: this.state.evaluacionSeleccionada.GRADO_RESPONSABILIDAD,
                adaptabilidad: this.state.evaluacionSeleccionada.ADAPTABILIDAD,
                calidadTrabajo: this.state.evaluacionSeleccionada.CALIDAD_TRABAJO,
                capacidadTrabajo: this.state.evaluacionSeleccionada.CAPACIDAD_TRABAJO,
                cumplimientoObjetivos: this.state.evaluacionSeleccionada.CUMPLIDOR_OBJETIVOS,
                cumplimientoPlazos:this.state.evaluacionSeleccionada.CUMPLIMIENTO_PLAZOS,
                compromisoEmpresa:this.state.evaluacionSeleccionada.COMPROMISO_EMPRESA,
                capacidadDecision: this.state.evaluacionSeleccionada.CAPACIDAD_DECISION,
                capacidadTrabajoEquipo: this.state.evaluacionSeleccionada.CAPACIDAD_TRABAJO_EQUIPO,
                conocimientos: this.state.evaluacionSeleccionada.CONOCIMIENTOS_PROFESIONALES,
                fiabilidad: this.state.evaluacionSeleccionada.FIABILIDAD,
                flexibilidad: this.state.evaluacionSeleccionada.FLEXIBILIDAD,
                organizacion: this.state.evaluacionSeleccionada.ORGANIZACION,
                iniciativa: this.state.evaluacionSeleccionada.INICIATIVA,
                disponibilidadAprender: this.state.evaluacionSeleccionada.DISPONIBILIDAD_APRENDER,
                capacidadResolucion: this.state.evaluacionSeleccionada.CAPACIDAD_RESOLUCION,
                puntualidad: this.state.evaluacionSeleccionada.PUNTUALIDAD,
                liderazgo: this.state.evaluacionSeleccionada.LIDERAZGO,
                motivacion: this.state.evaluacionSeleccionada.MOTIVACION,
                radioConclusion:this.state.evaluacionSeleccionada.CONCLUSION,
                observacionesEvaluacion: this.state.evaluacionSeleccionada.OBSERVACIONES,
                riesgoEvaluacion: this.state.evaluacionSeleccionada.ID_PERFIL_RIESGO,
                observacionesConclusion:this.state.evaluacionSeleccionada.DECISION,
                fechaProximaValoracion: new Date(this.state.evaluacionSeleccionada.FECHA_PROXIMA),
                fechaValoracion: new Date(this.state.evaluacionSeleccionada.FECHA),
                trabajadorSeleccionado:this.state.evaluacionSeleccionada.trabajador,
                evaluadorSeleccionado:this.state.evaluacionSeleccionada.evaluador,
                modalEvaluacionAbierto:true,
                cargandoGrid:true
            })

            // await this.setState({trabajadorSeleccionado:await this.obtenerCodigo(this.state.trabajadorSeleccionado)})
            // await this.setState({evaluadorSeleccionado:await this.obtenerCodigo(this.state.evaluadorSeleccionado)})

        }

        const abrirModalBorrar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.evaluacionSeleccionada){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            this.setState({
                nuevoActivado:false,
                editarActivado:false,
                borrarActivado:true,
                modalBorrar:true,
                cargandoGrid:true
            })
        }

        const cerrarModal = () => {
            this.setState({
                modalNuevoAbierto: false,
                modalEditarAbierto: false,
                modalBorrar: false,
                evaluacionSeleccionada:null,
                trabajadorSeleccionado:null,
                cargandoGrid:false
            })
        }

        const cerrarModal2 = () => {
            this.setState({
                modalEvaluacionAbierto: false,
                evaluacionSeleccionada: null,
                trabajadorSeleccionado:null,
                cargandoGrid:false
            })
            if(this.props.modal){
                this.props.setModalEvaluacion(false)
            }
        }

        const escogeFecha= (nuevaFecha) => {
            this.setState({ fechaProximaValoracion: nuevaFecha })
            console.log(this.state.fechaProximaValoracion)
        }

        const escogeFechaActual= (nuevaFecha) => {
            this.setState({ fechaValoracion: nuevaFecha })
            
        }

        const abrirDialogTrabajador = async(value) => {

            //Calculamos el tiempo del puesto de trabajo 
            let fechaActual = moment(this.state.fechaValoracion);
            let fechaAlta = moment(this.state.trabajadorSeleccionado.fechaPuesto);

            let meses = fechaActual.diff(fechaAlta, 'months');
            fechaAlta.add(meses, 'months');

            let trabajador=this.state.trabajadorSeleccionado
            trabajador.antiguedad=meses+" meses"           

            // this.setState({trabajadorSeleccionado:await this.obtenerCodigo(this.state.trabajadorSeleccionado)})


            //reiniciamos los campos y abrimos el modal
            this.setState({
                responsabilidad: 1,
                adaptabilidad: 3,
                calidadTrabajo: 3,
                capacidadTrabajo: 3,
                cumplimientoObjetivos: 3,
                cumplimientoPlazos: 3,
                compromisoEmpresa: 3,
                capacidadDecision: 3,
                capacidadTrabajoEquipo: 3,
                conocimientos: 3,
                fiabilidad: 3,
                flexibilidad: 3,
                organizacion: 3,
                iniciativa: 3,
                disponibilidadAprender: 3,
                capacidadResolucion: 3,
                puntualidad: 3,
                liderazgo: 3,
                motivacion: 3,
                radioConclusion:1,
                observacionesEvaluacion: null,
                riesgoEvaluacion: 1,
                observacionesConclusion: null,
                fechaProximaValoracion: new Date(),
                fechaValoracion: new Date(),
                evaluadorSeleccionado:null,
                evaluacionSeleccionada:null,
                modalEvaluacionAbierto: true,
                modalNuevoAbierto:false
            })
               

        }

        function dateFormatter(cell, row) {
        return (
        moment(cell).format("DD/MM/YYYY")
        );
        }

        const columnsTrab = [
        {
            dataField: 'nombre',
            text: i18next.t("valoracionrendimiento.nametrabajador",{ns:"page"})
        },{
            dataField: 'FECHA_ALTA',
            text: i18next.t("valoracionrendimiento.fecha_alta",{ns:"page"}),
            formatter: dateFormatter
        },{
            dataField: 'puestoActual',
            text: i18next.t("valoracionrendimiento.puesto",{ns:"page"})
        }
        ];
        
        function areaFormatter(cell, row) {
        return (
            cell.area
        );
        }

        function dptoFormatter(cell, row) {
            return (
                cell.departamento
            );
        }

        function nameFormatter(cell, row) {
            return (
                cell.nombre
            );
        }

        function codPuestoFormatter(cell, row) {
            return (
                cell.codigoPuesto
            );
        }

        const columns = [
        {
        dataField: 'trabajador',
        text: i18next.t("valoracionrendimiento.area",{ns:"page"}),
        formatter: areaFormatter
        },{
            dataField: 'trabajador',
            text: i18next.t("valoracionrendimiento.departamento",{ns:"page"}),
            formatter: dptoFormatter
            },{
                dataField: 'trabajador',
                text: i18next.t("valoracionrendimiento.nametrabajador",{ns:"page"}),
                formatter: nameFormatter
                },{
                    dataField: 'trabajador',
                    text: i18next.t("valoracionrendimiento.codigo_puesto",{ns:"page"}),
                    formatter: codPuestoFormatter
                    },{
                        dataField: 'FECHA',
                        text: i18next.t("valoracionrendimiento.fecha_evaluacion",{ns:"page"}),
                        formatter: dateFormatter
                    },{
                        dataField: 'FECHA_PROXIMA',
                        text: i18next.t("valoracionrendimiento.fecha_prox_evaluacion",{ns:"page"}),
                        formatter: dateFormatter
                    },{
                        dataField: 'CONCLUSION',
                        text: i18next.t("valoracionrendimiento.conclusion",{ns:"page"})
                    }
        ];

        const handleOnSelectTrab=(row, isSelect) => {
        if (isSelect) {
        this.setState({
            trabajadorSeleccionado: row
        });
        }
        return true;
        }

        const handleOnSelect=(row, isSelect) => {
        if (isSelect) {
        this.setState({
            evaluacionSeleccionada: row
        });
        }
        return true;
        }

        const añadirNuevo =async () => {

            //validaciones
        

            if(!this.state.trabajadorSeleccionado.puestoActual){
                alert(i18next.t("valoracionrendimiento.valid1",{ns:"page"}))
                return
            }
            if(!this.state.observacionesEvaluacion)return
            if(!this.state.observacionesConclusion)return
            if(!this.state.evaluadorSeleccionado)return
   

            let puestoEmp=await this.props.client
            .query({
                query: gql` 
                    {empleadosPuestos(CAMPOS:"ID_EMPLEO",FILTROS:{ID_EMPLEADO:${this.state.evaluadorSeleccionado.value},FECHA_BAJA:null}){ID_EMPLEO}}
                `,
                fetchPolicy:'network-only'
            }).then(result=>{ 
                return result.data.empleadosPuestos
            })

            if(puestoEmp.length===0){
                alert(i18next.t("valoracionrendimiento.valid2",{ns:"page"}))
                return
            }

            //creacion de la evaluacion
            this.props.client.mutate({
                mutation: gql`
                mutation{crearEmpleadosValoracion(VALORES:{
                    ID_EMPLEADO:${this.state.trabajadorSeleccionado.ID_EMPLEADO},
                    ANTIGUEDAD_PUESTO:"${this.state.trabajadorSeleccionado.antiguedad}",
                    ID_PUESTO:${this.state.trabajadorSeleccionado.idPuesto},
                    GRADO_RESPONSABILIDAD:${this.state.responsabilidad},
                    ADAPTABILIDAD:${this.state.adaptabilidad},
                    CALIDAD_TRABAJO:${this.state.calidadTrabajo},
                    CAPACIDAD_TRABAJO:${this.state.capacidadTrabajo},
                    CUMPLIDOR_OBJETIVOS:${this.state.cumplimientoObjetivos},
                    CUMPLIMIENTO_PLAZOS:${this.state.cumplimientoPlazos},
                    COMPROMISO_EMPRESA:${this.state.compromisoEmpresa},
                    CAPACIDAD_DECISION:${this.state.capacidadDecision},
                    CAPACIDAD_TRABAJO_EQUIPO:${this.state.capacidadTrabajoEquipo},
                    CONOCIMIENTOS_PROFESIONALES:${this.state.conocimientos},
                    FIABILIDAD:${this.state.fiabilidad},
                    FLEXIBILIDAD:${this.state.flexibilidad},
                    ORGANIZACION:${this.state.organizacion},
                    INICIATIVA:${this.state.iniciativa},
                    DISPONIBILIDAD_APRENDER:${this.state.disponibilidadAprender},
                    CAPACIDAD_RESOLUCION:${this.state.capacidadResolucion},
                    PUNTUALIDAD:${this.state.puntualidad},
                    LIDERAZGO:${this.state.liderazgo},
                    MOTIVACION:${this.state.motivacion},
                    OBSERVACIONES:"""${this.state.observacionesEvaluacion}"""
                    ID_PERFIL_RIESGO:${this.state.riesgoEvaluacion},
                    CONCLUSION:${this.state.radioConclusion},
                    DECISION:"""${this.state.observacionesConclusion}"""
                    FECHA_PROXIMA:"${moment(this.state.fechaProximaValoracion).format("YYYY-MM-DD")}"
                    FECHA:"${moment(this.state.fechaValoracion).format("YYYY-MM-DD")}"
                    ID_RESPONSABLE:${this.state.evaluadorSeleccionado.ID_EMPLEADO},
                    ID_PUESTO_RESPONSABLE:${this.state.evaluadorSeleccionado.idPuesto}
                }){ID_VALORACION}}`

            }).then(async result=>{
                
                alert(i18next.t("insertcorrecto",{ns:"global"}))

                this.setState({
                    modalEvaluacionAbierto:false,
                    modalNuevoAbierto:false,
                    evaluacionSeleccionada:null,
                    cargandoGrid:false
                })

                await this.recargarEvaluaciones()

                

            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })

            if(this.props.modal){
                this.props.setModalEvaluacion(false)
            }

        }

        const actualizarEvaluacion=()=>{

             //validaciones

             if(!this.state.trabajadorSeleccionado.puestoActual){
                alert(i18next.t("valoracionrendimiento.valid1",{ns:"page"}))
                return
            }
            if(!this.state.observacionesEvaluacion)return
            if(!this.state.observacionesConclusion)return
            if(!this.state.evaluadorSeleccionado)return
            if(!this.state.evaluadorSeleccionado.puestoActual){
                alert(i18next.t("valoracionrendimiento.valid2",{ns:"page"}))
                return
            }

            //creacion de la evaluacion
            this.props.client.mutate({
                mutation: gql`
                mutation{actualizarEmpleadoValoracion(
                ID_VALORACION:${this.state.evaluacionSeleccionada.ID_VALORACION}
                VALORES:{
                    ID_EMPLEADO:${this.state.trabajadorSeleccionado.ID_EMPLEADO},
                    ANTIGUEDAD_PUESTO:"${this.state.trabajadorSeleccionado.antiguedad}",
                    ID_PUESTO:${this.state.trabajadorSeleccionado.idPuesto},
                    GRADO_RESPONSABILIDAD:${this.state.responsabilidad},
                    ADAPTABILIDAD:${this.state.adaptabilidad},
                    CALIDAD_TRABAJO:${this.state.calidadTrabajo},
                    CAPACIDAD_TRABAJO:${this.state.capacidadTrabajo},
                    CUMPLIDOR_OBJETIVOS:${this.state.cumplimientoObjetivos},
                    CUMPLIMIENTO_PLAZOS:${this.state.cumplimientoPlazos},
                    COMPROMISO_EMPRESA:${this.state.compromisoEmpresa},
                    CAPACIDAD_DECISION:${this.state.capacidadDecision},
                    CAPACIDAD_TRABAJO_EQUIPO:${this.state.capacidadTrabajoEquipo},
                    CONOCIMIENTOS_PROFESIONALES:${this.state.conocimientos},
                    FIABILIDAD:${this.state.fiabilidad},
                    FLEXIBILIDAD:${this.state.flexibilidad},
                    ORGANIZACION:${this.state.organizacion},
                    INICIATIVA:${this.state.iniciativa},
                    DISPONIBILIDAD_APRENDER:${this.state.disponibilidadAprender},
                    CAPACIDAD_RESOLUCION:${this.state.capacidadResolucion},
                    PUNTUALIDAD:${this.state.puntualidad},
                    LIDERAZGO:${this.state.liderazgo},
                    MOTIVACION:${this.state.motivacion},
                    OBSERVACIONES:"""${this.state.observacionesEvaluacion}"""
                    ID_PERFIL_RIESGO:${this.state.riesgoEvaluacion},
                    CONCLUSION:${this.state.radioConclusion},
                    DECISION:"""${this.state.observacionesConclusion}"""
                    FECHA_PROXIMA:"${moment(this.state.fechaProximaValoracion).format("YYYY-MM-DD")}"
                    FECHA:"${moment(this.state.fechaValoracion).format("YYYY-MM-DD")}"
                    ID_RESPONSABLE:${this.state.evaluadorSeleccionado.ID_EMPLEADO},
                    ID_PUESTO_RESPONSABLE:${this.state.evaluadorSeleccionado.idPuesto}
                }){ID_VALORACION}}`

            }).then(async result=>{
                
                alert(i18next.t("updatecorrecto",{ns:"global"}))

                this.setState({
                    modalEvaluacionAbierto:false,
                    modalNuevoAbierto:false,
                    evaluacionSeleccionada:null,
                    cargandoGrid:false
                })

                await this.recargarEvaluaciones()

                
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })

        }

        const eliminarEvaluacion=()=>{

           //creacion de la evaluacion
           this.props.client.mutate({
               mutation: gql`
               mutation{eliminarEmpleadoValoracion(
                 ID_VALORACION:${this.state.evaluacionSeleccionada.ID_VALORACION}
              ){ID_VALORACION}}`

           }).then(async result=>{
               
               alert(i18next.t("deletecorrecto",{ns:"global"}))

               this.recargarEvaluaciones()

               cerrarModal()

               this.setState({
                   evaluacionSeleccionada:null,
                   cargandoGrid:false
               })

           }).catch(err=>{
               alert(i18next.t("error",{ns:"global"}))
           })

       }
      //  CONSULTAR SI PONER CAMBIO ANTIGUEDAD  
        const cambiarAntiguedad = (e) => {

            let trabajadoresACambiar = this.state.trabajadorSeleccionado.antiguedad

            let valor = e.currentTarget.value

            console.log(trabajadoresACambiar);

            this.setState({
                trabajadoresACambiar : valor
            })

            //alert("Andres, no me sale lo de cambiar el valor de la array, lo dejo aqui para seguir con el UI")
        }

        const seleccionRiesgos  = (value) => { 
            this.setState({riesgoEvaluacion: value.value})
        }

        const dot = (color = '#ccc') => ({
            alignItems: 'center',
            display: 'flex',
        
            ':before': {
            backgroundColor: color,
            borderRadius: 10,
            content: '" "',
            display: 'block',
            marginRight: 8,
            height: 10,
            width: 10,
            },
        });

        const estilosColorSelect = {
            control: styles => ({ ...styles, backgroundColor: 'white' }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = chroma(data.color);
            return {
                ...styles,
                backgroundColor: isDisabled
                ? '#ccc'
                : isSelected
                    ? chroma.contrast(color, 'white') > 2 ? 'gray' : 'gray'
                    : data.color,
                cursor: isDisabled ? 'not-allowed' : 'default',
            };
            },
            input: styles => ({ ...styles, ...dot() }),
            placeholder: styles => ({ ...styles, ...dot() }),
            singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
        }

        const handleResponsabilidad = (event) => {
            this.setState({responsabilidad: parseInt(event.currentTarget.value)})
        }

        const handleRadioConclusion = (event) => {
            this.setState({radioConclusion: parseInt(event.currentTarget.value)})
        }

        const handleAdaptabilidad = (e) => {
            this.setState({ adaptabilidad: e })
        }

        const handleCalidadTrabajo = (e) => {
            this.setState({ calidadTrabajo: e})
        }

        const handleCapacidadTrabajo = (e) => {
            this.setState({ capacidadTrabajo: e})
        }

        const handleCumplimientoObjetivos = (e) => {
            this.setState({ cumplimientoObjetivos: e})
        }

        const handleCumplimientoPlazos = (e) => {
            this.setState({ cumplimientoPlazos: e})
        }

        const handleCompromisoEmpresa = (e) => {
            this.setState({ compromisoEmpresa: e})
        }

        const handleCapacidadDecision = (e) => {
            this.setState({ capacidadDecision: e })
        }

        const handleCapacidadTrabajoEquipo = (e) => {
            this.setState({ capacidadTrabajoEquipo: e })
        }

        const handleConocimientosProfesionales = (e) => {
            this.setState({ conocimientos: e })
        }

        const handleFiabilidad = (e) => {
            this.setState({ fiabilidad: e })
        }

        const handleFlexibilidad = (e) => {
            this.setState({ flexibilidad: e})
        }

        const handleOrganizacion = (e) => {
            this.setState({ organizacion: e})
        }

        const handleIniciativa = (e) => {
            this.setState({ iniciativa: e })
        }

        const handleDisponibilidadAprender = (e) => {
            this.setState({ disponibilidadAprender: e})
        }

        const handleCapacidadResolucion = (e) => {
            this.setState({ capacidadResolucion: e })
        }

        const handlePuntualidad = (e) => {
            this.setState({ puntualidad: e })
        }

        const handleLiderazgo = (e) => {
            this.setState({ liderazgo: e})
        }

        const handleMotivacion = (e) => {
            this.setState({ motivacion: e})
        }

        const handleObservacionesEvaluacion = (e) => {
            this.setState({ observacionesEvaluacion: e.currentTarget.value })
        }

        const handleObservacionesConclusion = (e) => {
            this.setState({ observacionesConclusion: e.currentTarget.value })
        }

        const seleccionarEvaluador=async(val)=>{
            this.setState({evaluadorSeleccionado:val})
            // this.setState({evaluadorSeleccionado:await this.obtenerCodigo(val)})
            console.log(this.state.evaluadorSeleccionado)

        }

        // Finally, render it!
        return (
            <>
            {this.state.cargando === true?
                <Cargando />
                :
                
            <>
                {this.props.modal?
               
                    <Dialog
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModal2}
                    title={i18next.t("valoracionrendimiento.title",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalEvaluacionAbierto}
                    className="dialog-ancho"
                    >
                    <div className={Classes.DIALOG_BODY} ref={(input) => { this.Ficha= input; }}>
                        <>
                            <Card interactive={true} elevation={Elevation.TWO} >
                                <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("valoracionrendimiento.titledatos",{ns:"page"})}</Callout>
                                <FormGroup
                                    label={i18next.t("valoracionrendimiento.fullname",{ns:"page"})+":"}
                                    fill={true}
                                >
                                    <InputGroup readOnly={true} value={this.state.trabajadorSeleccionado?this.state.trabajadorSeleccionado.nombre:null} fill={true} />
                                </FormGroup>
                                <FormGroup
                                    label={i18next.t("valoracionrendimiento.codigo_empleado",{ns:"page"})+":"}
                                    fill={true}
                                >
                                    <InputGroup readOnly={true} value={this.state.trabajadorSeleccionado && this.state.trabajadorSeleccionado.CODIGO_EMPLEADO?this.state.trabajadorSeleccionado.CODIGO_EMPLEADO:null} fill={true} />
                                </FormGroup>
                                <FormGroup
                                    label={i18next.t("valoracionrendimiento.antiguedad",{ns:"page"})+":"}
                                    fill={true}
                                >
                                    <InputGroup readOnly={true} value={this.state.trabajadorSeleccionado?this.state.trabajadorSeleccionado.antiguedad:null} onChange={cambiarAntiguedad} fill={true} />
                                </FormGroup>
                                <FormGroup
                                    label={i18next.t("valoracionrendimiento.puesto",{ns:"page"})+":"}
                                    fill={true}
                                >
                                    <InputGroup readOnly={true} value={this.state.trabajadorSeleccionado?this.state.trabajadorSeleccionado.puestoActual:null} onChange={cambiarAntiguedad} fill={true} />
                                </FormGroup>
                                <FormGroup
                                    label={i18next.t("valoracionrendimiento.codigo_puesto",{ns:"page"})+":"}
                                    fill={true}
                                >
                                    <InputGroup readOnly={true} value={this.state.trabajadorSeleccionado && this.state.trabajadorSeleccionado.codigoPuesto?this.state.trabajadorSeleccionado.codigoPuesto:null} fill={true} />
                                </FormGroup>
                                <FormGroup
                                    label={i18next.t("valoracionrendimiento.riesgo",{ns:"page"})+":"}
                                    fill={true}
                                >
                                    <Select
                                        options={this.state.riesgoOptions}
                                        readOnly={true}
                                        className="pixelAlrededor"
                                        onChange={seleccionRiesgos}
                                        backspaceRemovesValue={true}
                                        isClearable={true}
                                        styles={estilosColorSelect}
                                        value={this.state.riesgoOptions.filter(riesgo => riesgo.value === (this.state.trabajadorSeleccionado?this.state.trabajadorSeleccionado.riesgoPuesto:1))}
                                    />
                                </FormGroup>
                                <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("valoracionrendimiento.titlegrado",{ns:"page"})}</Callout>
                                <RadioGroup
                                    onChange={handleResponsabilidad}
                                    selectedValue={this.state.responsabilidad}
                                    large={true}
                                >
                                    <Radio label={i18next.t("valoracionrendimiento.grado1",{ns:"page"})} value={1} />
                                    <Radio label={i18next.t("valoracionrendimiento.grado2",{ns:"page"})} value={2} />
                                    <Radio label={i18next.t("valoracionrendimiento.grado3",{ns:"page"})} value={3} />
                                    <Radio label={i18next.t("valoracionrendimiento.grado4",{ns:"page"})} value={4} />
                                    <Radio label={i18next.t("valoracionrendimiento.grado5",{ns:"page"})} value={5} />
                                    <Radio label={i18next.t("valoracionrendimiento.grado6",{ns:"page"})} value={6} />
                                    <Radio label={i18next.t("valoracionrendimiento.grado7",{ns:"page"})} value={7} />
                                    <Radio label={i18next.t("valoracionrendimiento.grado8",{ns:"page"})} value={8} />
                                </RadioGroup>
                                </Card>
                                <Card interactive={true} elevation={Elevation.TWO}>
                                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("valoracionrendimiento.titleevaluacion",{ns:"page"})}</Callout>
                                    <div className="pixelAlrededor">
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.adaptabilidad",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.adaptabilidad} onValueChange={handleAdaptabilidad} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.calidad_trabajo",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.calidadTrabajo} onValueChange={handleCalidadTrabajo} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.capacidad_trabajo",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.capacidadTrabajo} onValueChange={handleCapacidadTrabajo} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.cumpliento_objetivo",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.cumplimientoObjetivos} onValueChange={handleCumplimientoObjetivos} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.cumplimiento_plazo",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.cumplimientoPlazos} onValueChange={handleCumplimientoPlazos} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.compromiso_empresa",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.compromisoEmpresa} onValueChange={handleCompromisoEmpresa} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.capacidad_decision",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.capacidadDecision} onValueChange={handleCapacidadDecision} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.capacidad_equipo",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.capacidadTrabajoEquipo} onValueChange={handleCapacidadTrabajoEquipo} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.conocimientos_profesionales",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.conocimientos} onValueChange={handleConocimientosProfesionales} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.fiabilidad",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.fiabilidad} onValueChange={handleFiabilidad} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.flexibilidad",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.flexibilidad} onValueChange={handleFlexibilidad} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.organizacion",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.organizacion} onValueChange={handleOrganizacion} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.iniciativa",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.iniciativa} onValueChange={handleIniciativa} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.disponibilidad_aprender",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.disponibilidadAprender} onValueChange={handleDisponibilidadAprender} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.capacidad_resolucion",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.capacidadResolucion} onValueChange={handleCapacidadResolucion} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.puntualidad",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.puntualidad} onValueChange={handlePuntualidad} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.liderazgo",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.liderazgo} onValueChange={handleLiderazgo} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.motivacion",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.motivacion} onValueChange={handleMotivacion} />
                                        </FormGroup>
                                    </div>
                                    <FormGroup label={i18next.t("valoracionrendimiento.observaciones",{ns:"page"})} fill={true} className="pixelAlrededorGrande">
                                        <div style={!this.state.observacionesEvaluacion?{border:"1px solid red"}:{}}>
                                            <TextArea fill={true} value={this.state.observacionesEvaluacion} onChange={handleObservacionesEvaluacion} />
                                        </div>
                                    </FormGroup>
                                    <FormGroup
                                        label={i18next.t("valoracionrendimiento.perfil",{ns:"page"})}
                                        fill={true}
                                        className="pixelAlrededorGrande"
                                    >
                                        <Select
                                            options={this.state.riesgoOptions}
                                            className="pixelAlrededor"
                                            onChange={seleccionRiesgos}
                                            backspaceRemovesValue={true}
                                            isClearable={true}
                                            styles={estilosColorSelect}
                                            value={this.state.riesgoOptions.filter(riesgo => riesgo.value === (this.state.riesgoEvaluacion))}
                                        />
                                    </FormGroup>
                                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("valoracionrendimiento.conclusion",{ns:"page"})}</Callout>
                                    <RadioGroup
                                        onChange={handleRadioConclusion}
                                        selectedValue={this.state.radioConclusion}
                                        large={true}
                                    >
                                        <Radio label={i18next.t("valoracionrendimiento.conclusion1",{ns:"page"})} value={1} />
                                        <Radio label={i18next.t("valoracionrendimiento.conclusion2",{ns:"page"})} value={2} />
                                        <Radio label={i18next.t("valoracionrendimiento.conclusion3",{ns:"page"})} value={3} />
                                        <Radio label={i18next.t("valoracionrendimiento.conclusion4",{ns:"page"})} value={4} />
                                    </RadioGroup>
                                    <FormGroup label={i18next.t("valoracionrendimiento.decision",{ns:"page"})} fill={true} className="pixelAlrededorGrande">
                                        <div style={!this.state.observacionesConclusion?{border:"1px solid red"}:{}}>
                                            <TextArea fill={true} value={this.state.observacionesConclusion} onChange={handleObservacionesConclusion} />
                                        </div>
                                    </FormGroup>
                                    <FormGroup
                                        label={i18next.t("valoracionrendimiento.fecha_propuesta",{ns:"page"})}
                                        inline={true}
                                    >
                                        <DateInput minDate={new Date(1900, 1 ,1)}
                                            {...jsDateFormatter} 
                                            onChange={escogeFecha}
                                            value={this.state.fechaProximaValoracion}
                                        />
                                    </FormGroup>
                                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("valoracionrendimiento.evaluador",{ns:"page"})}</Callout>
                                    <FormGroup
                                        label={i18next.t("valoracionrendimiento.evaluador",{ns:"page"})+":"}
                                        fill={true}
                                    >
                                        <div style={!this.state.evaluadorSeleccionado?{border:"1px solid red"}:{}}>
                                            <Select
                                                options={this.state.trabajadorSeleccionado?this.state.empleados.filter(emp=>{return emp.ID_EMPLEADO!==this.state.trabajadorSeleccionado.ID_EMPLEADO && !emp.FECHA_BAJA}):[]}
                                                className="pixelAlrededor"
                                                onChange={seleccionarEvaluador}
                                                value={this.state.evaluadorSeleccionado}
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup
                                        label={i18next.t("valoracionrendimiento.codigo_empleado",{ns:"page"})+":"}
                                        fill={true}
                                    >
                                        <InputGroup readOnly={true} value={this.state.evaluadorSeleccionado && this.state.evaluadorSeleccionado.CODIGO_EMPLEADO?this.state.evaluadorSeleccionado.CODIGO_EMPLEADO:null} fill={true} />
                                    </FormGroup>
                                    <FormGroup
                                        label={i18next.t("valoracionrendimiento.puesto",{ns:"page"})+":"}
                                        fill={true}
                                    >
                                        <InputGroup readOnly={true} value={this.state.evaluadorSeleccionado && this.state.evaluadorSeleccionado.puestoActual?this.state.evaluadorSeleccionado.puestoActual:""} onChange={cambiarAntiguedad} fill={true} />
        
                                    </FormGroup>
                                    <FormGroup
                                        label={i18next.t("valoracionrendimiento.codigo_puesto",{ns:"page"})+":"}
                                        fill={true}
                                    >
                                        <InputGroup readOnly={true} value={this.state.evaluadorSeleccionado && this.state.evaluadorSeleccionado.codigoPuesto?this.state.evaluadorSeleccionado.codigoPuesto:""} fill={true} />
                                    </FormGroup>
                                    <FormGroup
                                        label={i18next.t("valoracionrendimiento.fecha_avaluacion",{ns:"page"})}
                                        inline={true}
                                    >
                                        <DateInput minDate={new Date(1900, 1 ,1)}
                                            {...jsDateFormatter} 
                                            onChange={escogeFechaActual}
                                            value={this.state.fechaValoracion}
                                        />
                                    </FormGroup>
                            </Card>
                        </>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button intent={Intent.DANGER} className="bp3-minimal" icon="floppy-disk" onClick={this.state.nuevoActivado?añadirNuevo:actualizarEvaluacion}>
                                {i18next.t("guardar",{ns:"global"})}
                            </Button>
                            <ReactToPrint
                                trigger={() => <Button intent={Intent.DANGER} className="bp3-minimal" icon="print">{i18next.t("valoracionrendimiento.generar_documento",{ns:"page"})}</Button>}
                                content={() => this.Ficha}
                            />  
                        </div>
                    </div>
                    </Dialog>
            
                :
                <></> }
                    <Dialog
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title={i18next.t("valoracionrendimiento.titleselect",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalNuevoAbierto}
                    style={styles.dialog2}
                    >
                    <div className={Classes.DIALOG_BODY}>
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <BootstrapTable
                        keyField='ID_EMPLEADO'
                        data={ this.state.empleados }
                        columns={ columnsTrab }
                        selectRow={ const_table.selectRow(handleOnSelectTrab) }
                        pagination={ paginationFactory(const_table.options) }
                        headerClasses="header-class"
                        rowClasses="row-class"
                        hover
                        condensed
                        noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                        />
                        </Card>
                    </div>
                        <br />
                        <br />
                        <br />
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                <Button onClick={cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                            </Tooltip>
                            <Tooltip content={i18next.t("valoracionrendimiento.escoger",{ns:"page"})} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={this.state.trabajadorSeleccionado !== null? abrirDialogTrabajador : () => alert(i18next.t("noseleccionado",{ns:"global"}))}
                                >
                                    {i18next.t("valoracionrendimiento.escoger",{ns:"page"})}
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>
                    </Dialog>
        
                    <Dialog
                   
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal2}
                    title={i18next.t("valoracionrendimiento.title",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalEvaluacionAbierto}
                    className="dialog-ancho"
                    >
                    <div className={Classes.DIALOG_BODY} ref={(input) => { this.Ficha= input; }}>
                        <>
                            <Card interactive={true} elevation={Elevation.TWO} >
                                <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("valoracionrendimiento.titledatos",{ns:"page"})}</Callout>
                                <FormGroup
                                    label={i18next.t("valoracionrendimiento.fullname",{ns:"page"})+":"}
                                    fill={true}
                                >
                                    <InputGroup readOnly={true} value={this.state.trabajadorSeleccionado?this.state.trabajadorSeleccionado.nombre:null} fill={true} />
                                </FormGroup>
                                <FormGroup
                                    label={i18next.t("valoracionrendimiento.codigo_empleado",{ns:"page"})+":"}
                                    fill={true}
                                >
                                    <InputGroup readOnly={true} value={this.state.trabajadorSeleccionado && this.state.trabajadorSeleccionado.codigoEmpleado?this.state.trabajadorSeleccionado.codigoEmpleado:null} fill={true} />
                                </FormGroup>
                                <FormGroup
                                    label={i18next.t("valoracionrendimiento.antiguedad",{ns:"page"})+":"}
                                    fill={true}
                                >
                                    <InputGroup readOnly={true} value={this.state.trabajadorSeleccionado?this.state.trabajadorSeleccionado.antiguedad:null} onChange={cambiarAntiguedad} fill={true} />
                                </FormGroup>
                                <FormGroup
                                    label={i18next.t("valoracionrendimiento.puesto",{ns:"page"})+":"}
                                    fill={true}
                                >
                                    <InputGroup readOnly={true} value={this.state.trabajadorSeleccionado?this.state.trabajadorSeleccionado.puestoActual:null} onChange={cambiarAntiguedad} fill={true} />
                                </FormGroup>
                                <FormGroup
                                    label={i18next.t("valoracionrendimiento.codigo_puesto",{ns:"page"})+":"}
                                    fill={true}
                                >
                                    <InputGroup readOnly={true} value={this.state.trabajadorSeleccionado && this.state.trabajadorSeleccionado.codigoPuesto?this.state.trabajadorSeleccionado.codigoPuesto:null} fill={true} />
                                </FormGroup>
                                <FormGroup
                                    label={i18next.t("valoracionrendimiento.riesgo",{ns:"page"})+":"}
                                    fill={true}
                                >
                                    <Select
                                        options={this.state.riesgoOptions}
                                        readOnly={true}
                                        className="pixelAlrededor"
                                        onChange={seleccionRiesgos}
                                        backspaceRemovesValue={true}
                                        isClearable={true}
                                        styles={estilosColorSelect}
                                        value={this.state.riesgoOptions.filter(riesgo => riesgo.value === (this.state.trabajadorSeleccionado?this.state.trabajadorSeleccionado.riesgoPuesto:1))}
                                    />
                                </FormGroup>
                                <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("valoracionrendimiento.titlegrado",{ns:"page"})}</Callout>
                                <RadioGroup
                                    onChange={handleResponsabilidad}
                                    selectedValue={this.state.responsabilidad}
                                    large={true}
                                >
                                    <Radio label={i18next.t("valoracionrendimiento.grado1",{ns:"page"})} value={1} />
                                    <Radio label={i18next.t("valoracionrendimiento.grado2",{ns:"page"})} value={2} />
                                    <Radio label={i18next.t("valoracionrendimiento.grado3",{ns:"page"})} value={3} />
                                    <Radio label={i18next.t("valoracionrendimiento.grado4",{ns:"page"})} value={4} />
                                    <Radio label={i18next.t("valoracionrendimiento.grado5",{ns:"page"})} value={5} />
                                    <Radio label={i18next.t("valoracionrendimiento.grado6",{ns:"page"})} value={6} />
                                    <Radio label={i18next.t("valoracionrendimiento.grado7",{ns:"page"})} value={7} />
                                    <Radio label={i18next.t("valoracionrendimiento.grado8",{ns:"page"})} value={8} />
                                </RadioGroup>
                                </Card>
                                <Card interactive={true} elevation={Elevation.TWO}>
                                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("valoracionrendimiento.titleevaluacion",{ns:"page"})}</Callout>
                                    <div className="pixelAlrededor">
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.adaptabilidad",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.adaptabilidad} onValueChange={handleAdaptabilidad} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.calidad_trabajo",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.calidadTrabajo} onValueChange={handleCalidadTrabajo} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.capacidad_trabajo",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.capacidadTrabajo} onValueChange={handleCapacidadTrabajo} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.cumplimiento_objetivo",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.cumplimientoObjetivos} onValueChange={handleCumplimientoObjetivos} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.cumplimiento_plazo",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.cumplimientoPlazos} onValueChange={handleCumplimientoPlazos} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.compromiso_empresa",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.compromisoEmpresa} onValueChange={handleCompromisoEmpresa} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.capacidad_decision",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.capacidadDecision} onValueChange={handleCapacidadDecision} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.capacidad_equipo",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.capacidadTrabajoEquipo} onValueChange={handleCapacidadTrabajoEquipo} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.conocimientos_profesionales",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.conocimientos} onValueChange={handleConocimientosProfesionales} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.fiabilidad",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.fiabilidad} onValueChange={handleFiabilidad} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.flexibilidad",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.flexibilidad} onValueChange={handleFlexibilidad} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.organizacion",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.organizacion} onValueChange={handleOrganizacion} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.iniciativa",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.iniciativa} onValueChange={handleIniciativa} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.disponibilidad_aprender",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.disponibilidadAprender} onValueChange={handleDisponibilidadAprender} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.capacidad_resolucion",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.capacidadResolucion} onValueChange={handleCapacidadResolucion} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.puntualidad",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.puntualidad} onValueChange={handlePuntualidad} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.liderazgo",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.liderazgo} onValueChange={handleLiderazgo} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label={i18next.t("valoracionrendimiento.motivacion",{ns:"page"})}>
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.motivacion} onValueChange={handleMotivacion} />
                                        </FormGroup>
                                    </div>
                                    <FormGroup label={i18next.t("valoracionrendimiento.observaciones",{ns:"page"})} fill={true} className="pixelAlrededorGrande">
                                        <div style={!this.state.observacionesEvaluacion?{border:"1px solid red"}:{}}>
                                            <TextArea fill={true} value={this.state.observacionesEvaluacion} onChange={handleObservacionesEvaluacion} />
                                        </div>
                                    </FormGroup>
                                    <FormGroup
                                        label={i18next.t("valoracionrendimiento.perfil",{ns:"page"})}
                                        fill={true}
                                        className="pixelAlrededorGrande"
                                    >
                                        <Select
                                            options={this.state.riesgoOptions}
                                            className="pixelAlrededor"
                                            onChange={seleccionRiesgos}
                                            backspaceRemovesValue={true}
                                            isClearable={true}
                                            styles={estilosColorSelect}
                                            value={this.state.riesgoOptions.filter(riesgo => riesgo.value === (this.state.riesgoEvaluacion))}
                                        />
                                    </FormGroup>
                                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("valoracionrendimiento.conclusion",{ns:"page"})}</Callout>
                                    <RadioGroup
                                        onChange={handleRadioConclusion}
                                        selectedValue={this.state.radioConclusion}
                                        large={true}
                                    >
                                        <Radio label={i18next.t("valoracionrendimiento.conclusion1",{ns:"page"})} value={1} />
                                        <Radio label={i18next.t("valoracionrendimiento.conclusion2",{ns:"page"})} value={2} />
                                        <Radio label={i18next.t("valoracionrendimiento.conclusion3",{ns:"page"})} value={3} />
                                        <Radio label={i18next.t("valoracionrendimiento.conclusion4",{ns:"page"})} value={4} />
                                    </RadioGroup>
                                    <FormGroup label={i18next.t("valoracionrendimiento.decision_propuesta",{ns:"page"})} fill={true} className="pixelAlrededorGrande">
                                        <div style={!this.state.observacionesConclusion?{border:"1px solid red"}:{}}>
                                            <TextArea fill={true} value={this.state.observacionesConclusion} onChange={handleObservacionesConclusion} />
                                        </div>
                                    </FormGroup>
                                    <FormGroup
                                        label={i18next.t("valoracionrendimiento.fecha_propuesta",{ns:"page"})}
                                        inline={true}
                                    >
                                        <DateInput minDate={new Date(1900, 1 ,1)}
                                            {...jsDateFormatter} 
                                            onChange={escogeFecha}
                                            value={this.state.fechaProximaValoracion}
                                        />
                                    </FormGroup>
                                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("valoracionrendimiento.evaluador",{ns:"page"})}</Callout>
                                    <FormGroup
                                        label={i18next.t("valoracionrendimiento.evaluador",{ns:"page"})+":"}
                                        fill={true}
                                    >
                                        <div style={!this.state.evaluadorSeleccionado?{border:"1px solid red"}:{}}>
                                            <Select
                                                options={this.state.trabajadorSeleccionado?this.state.empleados.filter(emp=>{return emp.ID_EMPLEADO!==this.state.trabajadorSeleccionado.ID_EMPLEADO}):[]}
                                                className="pixelAlrededor"
                                                onChange={seleccionarEvaluador}
                                                value={this.state.evaluadorSeleccionado}
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup
                                        label={i18next.t("valoracionrendimiento.codigo_empleado",{ns:"page"})+":"}
                                        fill={true}
                                    >
                                        <InputGroup readOnly={true} value={this.state.evaluadorSeleccionado && this.state.evaluadorSeleccionado.CODIGO_EMPLEADO?this.state.evaluadorSeleccionado.CODIGO_EMPLEADO:null} fill={true} />
                                    </FormGroup>
                                    <FormGroup
                                        label={i18next.t("valoracionrendimiento.puesto",{ns:"page"})+":"}
                                        fill={true}
                                    >
                                        <InputGroup readOnly={true} value={this.state.evaluadorSeleccionado && this.state.evaluadorSeleccionado.puestoActual?this.state.evaluadorSeleccionado.puestoActual:""} onChange={cambiarAntiguedad} fill={true} />
        
                                    </FormGroup>
                                    <FormGroup
                                        label={i18next.t("valoracionrendimiento.codigo_puesto",{ns:"page"})+":"}
                                        fill={true}
                                    >
                                        <InputGroup readOnly={true} value={this.state.evaluadorSeleccionado && this.state.evaluadorSeleccionado.codigoPuesto?this.state.evaluadorSeleccionado.codigoPuesto:""} fill={true} />
                                    </FormGroup>
                                    <FormGroup
                                        label={i18next.t("valoracionrendimiento.fecha_evaluacion",{ns:"page"})+":"}
                                        inline={true}
                                    >
                                        <DateInput minDate={new Date(1900, 1 ,1)}
                                            {...jsDateFormatter} 
                                            onChange={escogeFechaActual}
                                            value={this.state.fechaValoracion}
                                        />
                                    </FormGroup>
                            </Card>
                        </>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button intent={Intent.DANGER} className="bp3-minimal" icon="floppy-disk" onClick={this.state.nuevoActivado?añadirNuevo:actualizarEvaluacion}>
                                {i18next.t("guardar",{ns:"global"})}
                            </Button>
                            <Button intent={Intent.DANGER} className="bp3-minimal" icon="print"  active={this.state.imprimirActivadoFicha} onClick={() => this.generarFicha()}>{i18next.t("valoracionrendimiento.generar_documento",{ns:"page"})}</Button>

                            {/* <ReactToPrint
                                trigger={() => <Button intent={Intent.DANGER} className="bp3-minimal" icon="print">Generar Documento</Button>}
                                content={() => this.Ficha}
                            />   */}
                        </div>
                    </div>
                    </Dialog>
        
                    <Dialog transitionDuration={400}
                            autoFocus={true}
                            enforceFocus={true}
                            icon="warning-sign"
                            onClose={cerrarModal}
                            title={i18next.t("delete",{ns:"global"})}
                            canEscapeKeyClose={true}
                            canOutsideClickClose={true}
                            isCloseButtonShown={true}
                            usePortal={true}
                            isOpen={this.state.modalBorrar}
                        >
                        <div className={Classes.DIALOG_BODY}>
                                <h2>{i18next.t("valoracionrendimiento.questiondel",{ns:"page"})}</h2>
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Tooltip content={i18next.t("delete",{ns:"global"})} position="top">
                                    <AnchorButton
                                        intent={Intent.PRIMARY}
                                        onClick={eliminarEvaluacion}
                                    >
                                        {i18next.t("yes",{ns:"global"})}
                                    </AnchorButton>
                                </Tooltip>
                                <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                    <Button onClick={cerrarModal}>{i18next.t("no",{ns:"global"})}</Button>
                                </Tooltip>
                            </div>
                        </div>
                    </Dialog>

                    <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={i18next.t("print",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimir}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id='generarTabla-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>

                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimirFicha}
                    title={i18next.t("print",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirFicha}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id="generarFicha-pdf">
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>

                {this.props.empleado? <></>:
                    <div className="pixelArriba">
                        <Card interactive={true} elevation={Elevation.TWO}>
                            {this.state.cargandoGrid?
                                <Cargando/>
                            :
                            <>
                            <Navbar>
                                <Navbar.Group>
                                    <ButtonGroup align={Alignment.LEFT}>
                                        <Button className="bp3-minimal" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                        <Button className="bp3-minimal" icon="edit" text={i18next.t("edit",{ns:"global"})} active={this.state.editarActivado} onClick={abrirModalEditar}/>
                                        <Button className="bp3-minimal" icon="trash" text={i18next.t("delete",{ns:"global"})} active={this.state.borrarActivado} onClick={abrirModalBorrar} />
                                        <Button className="bp3-minimal" id="imprimir" icon="print" text={i18next.t("print",{ns:"global"})} active={this.state.imprimirActivado} onClick={() => this.generarTablaImprimir()} />
                                    </ButtonGroup>
                                    
                                </Navbar.Group>
                            </Navbar>
                            <BootstrapTable
                            keyField='ID_VALORACION'
                            data={ this.state.evaluaciones }
                            columns={ columns }
                            selectRow={ const_table.selectRow(handleOnSelect) }
                            pagination={ paginationFactory(const_table.options) }
                            headerClasses="header-class"
                            rowClasses="row-class"
                            hover
                            condensed
                            noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                            />
                            </>
                            }
                        </Card>
                    </div>
                    }                
                    </>
                }
{/*             
                </>
            } */}
            </>
        );
    }
}

export default withApollo(Evaluacion)